import {all, takeLatest, put, call, select} from "redux-saga/effects";
import Service from "../../_api/api-service";
import testbedAreasActionTypes , {ITestbedAreasAction} from "./testbed-areas.types";
import {testbedsError, testbedAreasFetchFinish} from "./testbed-areas.actions";
import {ITestbed, ITestbedArea, ITestbedAreaProjection, ITestbedCreateRequest} from "../../_api/openAPI";
import {AxiosResponse} from "axios";
import {selectTestbedsList} from "../testbeds/testbed.selectors";


function* fetchTestbedAreas() {
  try {
    const response:AxiosResponse<ITestbedAreaProjection[]> = yield Service.testbedAreaControllerApi.allTestbedAreasByManager("test")
    yield put<ITestbedAreasAction>(testbedAreasFetchFinish(response.data));
  }
  catch (e) {
    yield put(testbedsError())
  }
}

function* createUpdateTestbedArea({payload}: ITestbedAreasAction) {
  try {
    const data:ITestbedArea = payload;
    if(data.testbedAreaId) {
      yield Service.testbedAreaControllerApi.updateTestbedArea(data.testbedAreaId,  data as ITestbedCreateRequest)
    } else {
      yield Service.testbedAreaControllerApi.newTestbedArea(data as ITestbedCreateRequest)
    }
    yield fetchTestbedAreas();
  } catch (e) {
    yield put(testbedsError())
  }
}

function* deleteTestBedArea({payload}: ITestbedAreasAction) {
  if(payload) {
    yield Service.testbedAreaControllerApi.deleteTestbedArea(payload as number);
    yield fetchTestbedAreas();
  }
}

function* onTestbedsAreasFetchStart() {
  yield takeLatest(testbedAreasActionTypes.FETCH_TESTBED_AREAS_START, fetchTestbedAreas)
}


function* onTestbedAreaCreateStart() {
  yield takeLatest(testbedAreasActionTypes.CREATE_OR_UPDATE_TESTBED_AREA_START, createUpdateTestbedArea)
}

function* onTestbedAreaDeleteStart() {
  yield takeLatest(testbedAreasActionTypes.DELETE_TESTBED_AREA_START, deleteTestBedArea)
}

export function* testbedAreasSagas() {
  yield all([
    call(onTestbedsAreasFetchStart),
    call(onTestbedAreaCreateStart),
    call(onTestbedAreaDeleteStart)
  ])
}

