import testbedsActionTypes, {ITestbedsAction} from "./testbeds.types";
import {ITestbed, ITestbedProjection} from "../../_api/openAPI";

export const testbedsFetchStart = ():ITestbedsAction => ({
  type: testbedsActionTypes.FETCH_TESTBEDS_START
})

export const testbedsFetchFinish = (testbeds: ITestbedProjection[]):ITestbedsAction => ({
  type: testbedsActionTypes.FETCH_TESTBEDS_FINISH,
  payload: testbeds
})

// Not sure if these are needed
export const testbedsCreateStart = (testbed: ITestbedProjection):ITestbedsAction => ({
  type: testbedsActionTypes.CREATE_OR_UPDATE_TESTBED_START,
   payload: testbed
})

export const testbedsDeleteStart = (id:number):ITestbedsAction => ({
  type: testbedsActionTypes.DELETE_TESTBED_START,
  payload: id
})

export const testbedsError = ():ITestbedsAction => ({
  type: testbedsActionTypes.TESTBED_ERROR
})
