import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import { Theme } from '_theme'
import Logo from '_common/Logo/Logo'
import SidebarNav from './SidebarNav'
import logo from '../../../_common/Logo/logo_including.png'
import List from "@material-ui/core/List";


interface SidebarProps {
  onToggleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  isDesktop: boolean
  isMobile: boolean
  isSidebarCollapsedDesktop: boolean
  isSidebarOpenMobile: boolean
}

const Sidebar = (props: SidebarProps) => {
  const { isDesktop, isSidebarCollapsedDesktop } = props

  const classes = useStyles(props)

  return (
    <aside className={classes.sidebar}>
      <div className={classes.sidebarHeader}>
        <br/><br/><br/><br/><br/>
        {isSidebarCollapsedDesktop && (
          <Link to="/" className={classes.sidebarTitleLink}>
            <img src={logo} height={20} width={50}/>
          </Link>
        )}
        {!isSidebarCollapsedDesktop && (
          <Link to="/" className={classes.sidebarTitleLink}>
            <img src={logo} height={70} width={140}/>
          </Link>
        )}

        {/*<Link to="/" className={classes.sidebarTitleLink}>*/}
        {/*<img src={logo} height={70} width={140}/>*/}
        {/*  /!*<Logo size={30} className={classes.logo} />*!/*/}
        {/*  /!*<Typography*!/*/}
        {/*  /!*  component="h2"*!/*/}
        {/*  /!*  variant="h5"*!/*/}
        {/*  /!*  color="inherit"*!/*/}
        {/*  /!*  noWrap*!/*/}
        {/*  /!*  className={classes.title}*!/*/}
        {/*  /!*>*!/*/}
        {/*  /!*  <span className={classes.name}>Testbed Manager</span>*!/*/}
        {/*  /!*  <span className={classes.tagline}>Admin Env</span>*!/*/}
        {/*  /!*</Typography>*!/*/}
        {/*</Link>*/}
      </div>
      <SidebarNav isCollapsed={isDesktop && isSidebarCollapsedDesktop} />
    </aside>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  sidebar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    color: theme.sidebar.color,
    background: theme.sidebar.background,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  sidebarHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  sidebarTitleLink: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    // '&:hover': {
    //   '& $logo': {
    //     color: '#fff',
    //   },
    // },
  },
  logo: {
    color: theme.palette.primary.main,
  },
  title: (props: SidebarProps) => ({
    // fontSize: '20px',
    // fontWeight: 400,
    position: 'relative',
    overflow: 'visible',
    marginLeft: '5px',
    display: props.isDesktop && props.isSidebarCollapsedDesktop ? 'none' : 'block',
  }),
  name: {},
  tagline: {
    fontSize: 8,
    fontWeight: 'bold',
    position: 'absolute',
    top: '100%',
    marginTop: -5,
    background: theme.palette.primary.main,
    color: '#fff',
    borderRadius: 2,
    padding: '1px 3px',
    right: 0,
  },
}))

export default Sidebar
