import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import {PersistConfig} from "redux-persist/es/types";
import {DefaultRootState} from "react-redux";
import storage from 'redux-persist/lib/storage'

import reservationsReducer from "./reservations/reservations.reducer";
import {ITestbedsState} from "./testbeds/testbeds.types";
import testbedsReducer from "./testbeds/testbeds.reducer";
import { IReservationsState } from "./reservations/reservations.types";
import {IResourcesState} from "./resources/resources.types";
import resourcesReducer from "./resources/resources.reducer";
import {ITestbedAreasState} from "./testbed-areas/testbed-areas.types";
import testbedAreasReducer from "./testbed-areas/testbed-areas.reducer";

const persistConfig: PersistConfig<IRootState> = {
  key: 'root',
  storage
}

export interface IRootState extends DefaultRootState {
  //users: IUsersState,
  testbeds: ITestbedsState,
  testbedAreas: ITestbedAreasState,
  reservations: IReservationsState,
  resources: IResourcesState
}


export const rootReducer = combineReducers<IRootState>({
  testbeds: testbedsReducer,
  resources: resourcesReducer,
  reservations: reservationsReducer,
  testbedAreas: testbedAreasReducer
})

export default persistReducer<IRootState>(persistConfig, rootReducer);
