import React, {useState} from "react";
import PageContainer from "../_common/PageContainer";
import {Checkbox, TextField} from "@material-ui/core";
import {useAppDispatch} from '../_redux/hooks'
import Button from "@material-ui/core/Button";
import {useSelector} from "react-redux";
import {selectTestbedsList,
  selectTestbedsLoading
} from "../_redux/testbeds/testbed.selectors";
import DataTable from "react-data-table-component";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {useFormik} from "formik";
import Box from '@mui/material/Box'
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {testbedsCreateStart, testbedsDeleteStart} from "../_redux/testbeds/testbeds.actions";
import {ITestbedProjection} from "../_api/openAPI";
import {Col, Form, Row} from "react-bootstrap";

const columns = [
  {
    name: 'Testbed Id',
    selector: (row: any) => row.testbedId,
    sortable: true
  },
  {
    name: 'Name',
    selector: (row: any) => row.name,
    sortable: true

  },
  {
    name: 'Area',
    selector: (row: any) => row.area,
    sortable: true
  },
];

const INITIAL_VALUES: ITestbedProjection = {
  area: "",
  location: "",
  name: "",
  description: '',
  operationEndTime: '',
  operationStartTime: '',
  simulator: true,
  accuracy: 0,
  testbedStatusMessage: '',
  uavSupport:false,
  auvSupport:false
}


const TestbedView: React.FC<{}> = () => {
  const [toggleForm, setToggleForm] = useState(false);
  const testbedList = useSelector(selectTestbedsList);
  const loading = useSelector(selectTestbedsLoading);
  const dispatch = useAppDispatch();

  const form = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (values, formikHelpers) =>{
      dispatch(testbedsCreateStart(values));
      setToggleForm(false);
    }
  })

  const createRow = () => {
    setToggleForm(true);
    form.setValues(INITIAL_VALUES);
    form.setSubmitting(false);
  };

  const deleteRow = (id: number) => {
    dispatch(testbedsDeleteStart(id));
    setToggleForm(false);

  };

  const cancelAll = () => {
    setToggleForm(false);
  };

  const handleOnRowClicked = (row: ITestbedProjection) => {
    setToggleForm(true);
    form.setValues(row, true);
    form.setSubmitting(false);
  };

  return (
    (loading) ? <div>Loading....</div> :
      <PageContainer>
        <section>
          <Row>
            <Col sm={12}>
              <DataTable
                title="Testbeds"
                columns={columns}
                data={testbedList}
                dense
                pagination
                sortIcon={<ArrowDownward/>}
                highlightOnHover={true}
                onRowClicked={handleOnRowClicked}/>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  <Col className="d-flex flex-row align-items-end justify-content-end">*/}
          {/*    <Button*/}
          {/*      color={"primary"}*/}
          {/*      startIcon={<AddCircleIcon/>}*/}
          {/*      onClick={createRow}*/}
          {/*      size="large"*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </section>
        {
          toggleForm &&
          <section className="my-4">
            <Row className="mb-3">
              <Col className="d-flex flex-row justify-content-between align-items-center">
                <Box>
                  {form.values.testbedId ? `Testbed ID: ${form.values.testbedId}` : `Create new Testbed`}
                </Box>
                <Box className="d-flex flex-row">
                  {/*{*/}
                  {/*  form.values.testbedId &&*/}
                  {/*  <Button style={{color: "red"}} className="me-2" variant={"outlined"} startIcon={<DeleteIcon/>}*/}
                  {/*          onClick={()=>deleteRow(form.values.testbedId as number)}>DELETE</Button>*/}
                  {/*}*/}
                  <Button color={"secondary"} variant={"outlined"} startIcon={<CancelIcon/>}
                          onClick={cancelAll}>CANCEL</Button>
                </Box>
              </Col>
            </Row>
            <Row>
              <Form onSubmit={form.submitForm}>
                <Box>
                  <Row>
                    <Col sm={4}>
                      <TextField
                        label="Name"
                        id="name"
                        name="name"
                        placeholder="Name"
                        error={Boolean(form.errors.name)}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.name}
                        helperText={form.errors.name}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        label="Accuracy"
                        id="accuracy"
                        name="accuracy"
                        placeholder="Accuracy"
                        error={Boolean(form.errors.accuracy)}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"number"}
                        value={form.values.accuracy}
                        helperText={form.errors.accuracy}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        label="Area"
                        id="area"
                        name="area"
                        placeholder="Area"
                        error={Boolean(form.errors.area)}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.area}
                        helperText={form.errors.area}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        label="Description"
                        id="description"
                        name="description"
                        placeholder="Description"
                        error={Boolean(form.errors.description)}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.description}
                        helperText={form.errors.description}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        label="Location"
                        id="location"
                        name="location"
                        placeholder="Location"
                        error={Boolean(form.errors.location)}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.location}
                        helperText={form.errors.location}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        label="Start Time"
                        id="operationStartTime"
                        name="operationStartTime"
                        placeholder="Operation Start Time"
                        error={Boolean(form.errors.operationStartTime)}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.operationStartTime}
                        helperText={form.errors.operationStartTime}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        label="End Time"
                        id="operationEndTime"
                        name="operationEndTime"
                        placeholder="Operation End Time"
                        error={Boolean(form.errors.operationEndTime)}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.operationEndTime}
                        helperText={form.errors.operationEndTime}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        label="Status Message"
                        id="testbedStatusMessage"
                        name="testbedStatusMessage"
                        placeholder="Status Message"
                        error={Boolean(form.errors.testbedStatusMessage)}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.testbedStatusMessage}
                        helperText={form.errors.testbedStatusMessage}
                      />
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col sm={4}>
                      <Checkbox id="simulatorEnabled" name="simulator" placeholder="Simulator" checked={form.values.simulator} onChange={form.handleChange}/>
                      <label htmlFor="simulatorEnabled">Simulator</label>
                    </Col>
                    <Col sm={4}>
                      <Checkbox id="vrEnabled" name="vrEnabled" placeholder="VR Enabled" checked={form.values.vrEnabled} onChange={form.handleChange}/>
                      <label htmlFor="vrEnabled">VR Enabled</label>
                    </Col>
                    <Col sm={4}>
                      <Checkbox id="usvSupport" name="usvSupport" placeholder="USV Support" checked={form.values.usvSupport} onChange={form.handleChange}/>
                      <label htmlFor="usvSupport">USV Support</label>
                    </Col>
                    <Col sm={4}>
                      <Checkbox id="uavSupport" name="uavSupport" placeholder="UAV Support" checked={form.values.uavSupport} onChange={form.handleChange}/>
                      <label htmlFor="uavSupport">UAV Support</label>
                    </Col>
                    <Col sm={4}>
                      <Checkbox id="auvSupport" name="auvSupport" placeholder="AUV Support" checked={form.values.auvSupport} onChange={form.handleChange}/>
                      <label htmlFor="ausvSupport">AUV Support</label>
                    </Col>
                    <Col sm={4}>
                      <Checkbox id="ugvSupport" name="ugvSupport" placeholder="UGV Support" checked={form.values.ugvSupport} onChange={form.handleChange}/>
                      <label htmlFor="ugvSupport">UGV Support</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-row justify-content-end align-items-center">
                      <Button color={"primary"} type="button" variant={"outlined"} disabled={!form.isValid || form.isSubmitting} startIcon={<SaveIcon/>} onClick={form.submitForm} >SUBMIT</Button>
                    </Col>
                  </Row>
                </Box>
              </Form>
            </Row>
          </section>
        }
      </PageContainer>
  )
}





export default TestbedView;
