import testbedsActionTypes, {ITestbedsAction, ITestbedsState} from "./testbeds.types";

const INITIAL_STATE: ITestbedsState = {
  testbedList: [],
  loading: false
}

const testbedsReducer = (state: ITestbedsState = INITIAL_STATE, action: ITestbedsAction) => {
  switch (action.type) {
    case testbedsActionTypes.FETCH_TESTBEDS_START :
      return {
        ...state,
        loading: true
      }
    case testbedsActionTypes.FETCH_TESTBEDS_FINISH:
      return {
        ...state,
        testbedList: action.payload,
        selectedTestbed: undefined,
        loading: false
      }
    case testbedsActionTypes.TESTBED_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return {
        ...state
      }
  }
}

export default testbedsReducer;
