import {Action} from "redux";
import {IReservation, IReservationProjection} from "../../_api/openAPI";

export enum ReservationStatus{
  	CANCELLED = 1,
  	CONFLICT,
  	OK,
  	PENDING,
  	REJECTED
}

export interface IReservationsAction extends Action<reservationsActionTypes> {
    payload?: any
}

export interface IReservationsState {
    reservationList: IReservationProjection[]
    loading: boolean
}

enum reservationsActionTypes {
    FETCH_RESERVATIONS_START = "FETCH_RESERVATIONS_START",
    FETCH_RESERVATIONS_FINISH = "FETCH_RESERVATIONS_FINISH",
    RESERVATION_ERROR = "RESERVATION_ERROR",
    UPDATE_RESERVATION_STATUS_START = "UPDATE_RESERVATION_STATUS_START",
    UPDATE_RESERVATION_STATUS_FINISH = "UPDATE_RESERVATION_STATUS_FINISH"
}

 export default reservationsActionTypes
