interface Config {
  navigationType: 'hash' | 'history'
  useSampleData?: boolean
  api: {
    baseUrl: string
    timeout: number
  }
}

const config: Config = {
  navigationType: 'hash',
  useSampleData: true,
  api: {
    baseUrl: 'http://outlander4.di.uoa.gr:8181',
    timeout: 5000,
  },
}

export default config
