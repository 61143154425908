import {all, takeLatest, put, call} from "redux-saga/effects";
import Service from "../../_api/api-service";
import testbedsActionTypes, {ITestbedsAction} from "./testbeds.types";
import {testbedsError, testbedsFetchFinish} from "./testbeds.actions";
import {ITestbed, ITestbedCreateRequest, ITestbedProjection} from "../../_api/openAPI";
import {AxiosResponse} from "axios";
import _ from "lodash";

function* fetchTestbeds() {
  try {
    const response:AxiosResponse<ITestbedProjection[]> = yield Service.testbedControllerApi.allTestbedsByManager("test");
    yield put<ITestbedsAction>(testbedsFetchFinish(response.data));
  } catch (e) {
    yield put(testbedsError())
  }
}

function* createUpdateTestbeds({type, payload}: ITestbedsAction) {
  try {
    const data:ITestbedProjection = payload;
    if(data.testbedId) {
      yield Service.testbedControllerApi.replaceTestbed(data.testbedId,  data as ITestbedCreateRequest)
    } else {
      yield Service.testbedControllerApi.newTestbed(data as ITestbedCreateRequest)
    }
    yield fetchTestbeds();
  } catch (e) {
    yield put(testbedsError())
  }
}

function* deleteTestBed({payload}: ITestbedsAction) {
  if(payload) {
    yield Service.testbedControllerApi.deleteTestbed(payload as number);
    yield fetchTestbeds();
  }
}

function* onTestbedsFetchStart() {
  yield takeLatest(testbedsActionTypes.FETCH_TESTBEDS_START, fetchTestbeds)
}


function* onTestbedCreateStart() {
  yield takeLatest(testbedsActionTypes.CREATE_OR_UPDATE_TESTBED_START, createUpdateTestbeds)
}

function* onTestbedDeleteStart() {
  yield takeLatest(testbedsActionTypes.DELETE_TESTBED_START, deleteTestBed)
}

export function* testbedsSagas() {
  yield all([
    call(onTestbedsFetchStart),
    call(onTestbedCreateStart),
    call(onTestbedDeleteStart)
  ])
}

