import {Action} from "redux";
import {ITestbedProjection} from "../../_api/openAPI";

export interface ITestbedsAction extends Action<testbedsActionTypes> {
  payload?: any
}

export interface ITestbedsState {
  testbedList: ITestbedProjection[]
  loading: boolean
}

enum testbedsActionTypes {
  FETCH_TESTBEDS_START = "FETCH_TESTBEDS_START",
  FETCH_TESTBEDS_FINISH = "FETCH_TESTBEDS_FINISH",
  CREATE_OR_UPDATE_TESTBED_START = "CREATE_OR_UPDATE_TESTBED_START",
  CREATE_OR_UPDATE_TESTBED_FINISH = "CREATE_OR_UPDATE_TESTBED_FINISH",
  DELETE_TESTBED_START = "DELETE_TESTBED_START",
  DELETE_TESTBED_FINISH = "DELETE_TESTBED_FINISH",
  TESTBED_ERROR = "TESTBED_ERROR"

}

export default testbedsActionTypes
