import testbedAreasActionTypes , {ITestbedAreasState, ITestbedAreasAction} from "./testbed-areas.types";

const INITIAL_STATE: ITestbedAreasState = {
  testbedAreasList: [],
  loading: false
}

const testbedAreasReducer = (state: ITestbedAreasState = INITIAL_STATE, action: ITestbedAreasAction) => {
  switch (action.type) {
    case testbedAreasActionTypes.FETCH_TESTBED_AREAS_START :
      return {
        ...state,
        loading: true
      }
    case testbedAreasActionTypes.FETCH_TESTBED_AREAS_FINISH:
      return {
        ...state,
        testbedAreasList: action.payload,
        loading: false
      }
    case testbedAreasActionTypes.TESTBED_AREA_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return {
        ...state
      }
  }
}

export default testbedAreasReducer;
