import {createSelector} from "reselect";
import {IResourcesState} from "./resources.types";
import {IRootState} from "../root-reducer";
import _ from "lodash";


const selectResources = (state:IRootState) => state.resources;


export const selectResourcesList = createSelector(
  [selectResources],
  (resources:IResourcesState)=> _.orderBy(resources.resourceList, i=>i.resourceId)
);

export const selectSensorsList = createSelector(
  [selectResourcesList],
  (resources)=> {
    return _.orderBy(_.filter(resources, i=>i.resourceType === 'Sensor'), i => i.resourceId)
  }
)

export const selectUxVsList = createSelector(
  [selectResourcesList],
  (resources)=> {
    return _.orderBy(_.filter(resources, i=>i.resourceType === 'UxV'), i => i.resourceId)
  }
)

export const selectOperatorList = createSelector(
  [selectResourcesList],
  (resources)=> {
    return _.orderBy(_.filter(resources, i=>i.resourceType === 'Operator'), i => i.resourceId)
  }
)

export const selectEquipmentsList = createSelector(
  [selectResourcesList],
  (resources)=> {
    return _.orderBy(_.filter(resources, i=>i.resourceType === 'Equipment'), i => i.resourceId)
  }
)

export const selectResourcesLoading = createSelector(
  [selectResources],
  (resources:IResourcesState)=>resources.loading
);

