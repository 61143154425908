/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface IApprovalLut
 */
export interface IApprovalLut {
    /**
     * 
     * @type {number}
     * @memberof IApprovalLut
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IApprovalLut
     */
    'name'?: string;
    /**
     * 
     * @type {Array<IEthicsCheckInfo>}
     * @memberof IApprovalLut
     */
    'ethicsCheckInfos1'?: Array<IEthicsCheckInfo>;
    /**
     * 
     * @type {Array<IEthicsCheckInfo>}
     * @memberof IApprovalLut
     */
    'ethicsCheckInfos2'?: Array<IEthicsCheckInfo>;
}
/**
 * 
 * @export
 * @interface IApprovalLutResponse
 */
export interface IApprovalLutResponse {
    /**
     * 
     * @type {number}
     * @memberof IApprovalLutResponse
     */
    'approvalLutId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IApprovalLutResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface IBoockedTestBed
 */
export interface IBoockedTestBed {
    /**
     * 
     * @type {string}
     * @memberof IBoockedTestBed
     */
    'testBed'?: string;
    /**
     * 
     * @type {string}
     * @memberof IBoockedTestBed
     */
    'type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBoockedTestBed
     */
    'location'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBoockedTestBed
     */
    'dateTime'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ICustomBookedResourcesResponse
 */
export interface ICustomBookedResourcesResponse {
    /**
     * 
     * @type {string}
     * @memberof ICustomBookedResourcesResponse
     */
    'Image'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomBookedResourcesResponse
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomBookedResourcesResponse
     */
    'Type'?: string;
    /**
     * 
     * @type {object}
     * @memberof ICustomBookedResourcesResponse
     */
    'Status'?: object;
    /**
     * 
     * @type {object}
     * @memberof ICustomBookedResourcesResponse
     */
    'Selected'?: object;
    /**
     * 
     * @type {number}
     * @memberof ICustomBookedResourcesResponse
     */
    'MinSpeed'?: number;
    /**
     * 
     * @type {number}
     * @memberof ICustomBookedResourcesResponse
     */
    'MaxSpeed'?: number;
    /**
     * 
     * @type {number}
     * @memberof ICustomBookedResourcesResponse
     */
    'Speed'?: number;
    /**
     * 
     * @type {number}
     * @memberof ICustomBookedResourcesResponse
     */
    'MinAltitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ICustomBookedResourcesResponse
     */
    'MaxAltitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ICustomBookedResourcesResponse
     */
    'Altitude'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICustomBookedResourcesResponse
     */
    'availableSensors'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ICustomBookedResourcesResponse
     */
    'availableAlgorithms'?: object;
    /**
     * 
     * @type {object}
     * @memberof ICustomBookedResourcesResponse
     */
    'availableCommunications'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICustomBookedResourcesResponse
     */
    'definedSensorsList'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ICustomBookedResourcesResponse
     */
    'definedAlgorithmsList'?: object;
    /**
     * 
     * @type {object}
     * @memberof ICustomBookedResourcesResponse
     */
    'definedCommunicationsList'?: object;
    /**
     * 
     * @type {object}
     * @memberof ICustomBookedResourcesResponse
     */
    'definedEventsList'?: object;
    /**
     * 
     * @type {Array<IDefinedWayPoint>}
     * @memberof ICustomBookedResourcesResponse
     */
    'definedWayPoints'?: Array<IDefinedWayPoint>;
    /**
     * 
     * @type {object}
     * @memberof ICustomBookedResourcesResponse
     */
    'WPs'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomBookedResourcesResponse
     */
    'borrowed'?: boolean;
    /**
     * 
     * @type {Array<IDefinedWayPoint>}
     * @memberof ICustomBookedResourcesResponse
     */
    'location'?: Array<IDefinedWayPoint>;
}
/**
 * 
 * @export
 * @interface ICustomBookedTestbedResponse
 */
export interface ICustomBookedTestbedResponse {
    /**
     * 
     * @type {string}
     * @memberof ICustomBookedTestbedResponse
     */
    'User'?: string;
    /**
     * 
     * @type {Array<IBoockedTestBed>}
     * @memberof ICustomBookedTestbedResponse
     */
    'boockedTestBeds'?: Array<IBoockedTestBed>;
}
/**
 * 
 * @export
 * @interface ICustomTestbedAreasResponse
 */
export interface ICustomTestbedAreasResponse {
    /**
     * 
     * @type {string}
     * @memberof ICustomTestbedAreasResponse
     */
    'TestBedArea'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICustomTestbedAreasResponse
     */
    'Polygon'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICustomTestbedAreasResponse
     */
    'Obstacles'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IDateTime
 */
export interface IDateTime {
    /**
     * 
     * @type {string}
     * @memberof IDateTime
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof IDateTime
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof IDateTime
     */
    'endTime'?: string;
}
/**
 * 
 * @export
 * @interface IDdlScript
 */
export interface IDdlScript {
    /**
     * 
     * @type {number}
     * @memberof IDdlScript
     */
    'scriptId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IDdlScript
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof IDdlScript
     */
    'genScriptContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof IDdlScript
     */
    'generatedfilename'?: string;
    /**
     * 
     * @type {string}
     * @memberof IDdlScript
     */
    'realScriptContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof IDdlScript
     */
    'scriptContent'?: string;
}
/**
 * 
 * @export
 * @interface IDefinedWayPoint
 */
export interface IDefinedWayPoint {
    /**
     * 
     * @type {number}
     * @memberof IDefinedWayPoint
     */
    'altitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof IDefinedWayPoint
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof IDefinedWayPoint
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof IDefinedWayPoint
     */
    'speed'?: number;
    /**
     * 
     * @type {number}
     * @memberof IDefinedWayPoint
     */
    'timestep'?: number;
    /**
     * 
     * @type {number}
     * @memberof IDefinedWayPoint
     */
    'wayPoint'?: number;
}
/**
 * 
 * @export
 * @interface IEquipmentCreateRequest
 */
export interface IEquipmentCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IEquipmentCreateRequest
     */
    'resourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IEquipmentCreateRequest
     */
    'resourceDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof IEquipmentCreateRequest
     */
    'resourceLocation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IEquipmentCreateRequest
     */
    'transferable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IEquipmentCreateRequest
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof IEquipmentCreateRequest
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IEquipmentCreateRequest
     */
    'mobile'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IEquipmentCreateRequest
     */
    'equipmentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof IEquipmentCreateRequest
     */
    'equipmentType'?: string;
}
/**
 * 
 * @export
 * @interface IEquipmentProperties
 */
export interface IEquipmentProperties {
    /**
     * 
     * @type {number}
     * @memberof IEquipmentProperties
     */
    'equipmentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof IEquipmentProperties
     */
    'equipmentType'?: string;
}
/**
 * 
 * @export
 * @interface IEquipmentPropertiesEquipment
 */
export interface IEquipmentPropertiesEquipment {
    /**
     * 
     * @type {number}
     * @memberof IEquipmentPropertiesEquipment
     */
    'equipmentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof IEquipmentPropertiesEquipment
     */
    'equipmentType'?: string;
}
/**
 * 
 * @export
 * @interface IEthicsCheckInfo
 */
export interface IEthicsCheckInfo {
    /**
     * 
     * @type {IEthicsCheckInfoPK}
     * @memberof IEthicsCheckInfo
     */
    'id'?: IEthicsCheckInfoPK;
    /**
     * 
     * @type {boolean}
     * @memberof IEthicsCheckInfo
     */
    'emailCheck'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IEthicsCheckInfo
     */
    'ethicApprovalTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof IEthicsCheckInfo
     */
    'ethicComment'?: string;
    /**
     * 
     * @type {string}
     * @memberof IEthicsCheckInfo
     */
    'testbedApprovalTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof IEthicsCheckInfo
     */
    'testbedComment'?: string;
    /**
     * 
     * @type {IApprovalLut}
     * @memberof IEthicsCheckInfo
     */
    'approvalLut1'?: IApprovalLut;
    /**
     * 
     * @type {IApprovalLut}
     * @memberof IEthicsCheckInfo
     */
    'approvalLut2'?: IApprovalLut;
    /**
     * 
     * @type {IUser}
     * @memberof IEthicsCheckInfo
     */
    'testbedOper'?: IUser;
    /**
     * 
     * @type {IUser}
     * @memberof IEthicsCheckInfo
     */
    'ethicUser'?: IUser;
}
/**
 * 
 * @export
 * @interface IEthicsCheckInfoPK
 */
export interface IEthicsCheckInfoPK {
    /**
     * 
     * @type {number}
     * @memberof IEthicsCheckInfoPK
     */
    'scriptId'?: number;
    /**
     * 
     * @type {number}
     * @memberof IEthicsCheckInfoPK
     */
    'testbedId'?: number;
}
/**
 * 
 * @export
 * @interface IExperiment
 */
export interface IExperiment {
    /**
     * 
     * @type {number}
     * @memberof IExperiment
     */
    'experimentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IExperiment
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperiment
     */
    'endedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperiment
     */
    'experimentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperiment
     */
    'experimentVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperiment
     */
    'launchedAt'?: string;
    /**
     * 
     * @type {IDdlScript}
     * @memberof IExperiment
     */
    'ddlScript'?: IDdlScript;
    /**
     * 
     * @type {IExperimentStatusLut}
     * @memberof IExperiment
     */
    'experimentStatusLut'?: IExperimentStatusLut;
    /**
     * 
     * @type {IUser}
     * @memberof IExperiment
     */
    'user'?: IUser;
    /**
     * 
     * @type {Array<IReservationItem>}
     * @memberof IExperiment
     */
    'reservationItems'?: Array<IReservationItem>;
    /**
     * 
     * @type {Array<IExperimentExecution>}
     * @memberof IExperiment
     */
    'experimentExecutions'?: Array<IExperimentExecution>;
}
/**
 * 
 * @export
 * @interface IExperimentDDLScriptFromExperimentProjection
 */
export interface IExperimentDDLScriptFromExperimentProjection {
    /**
     * 
     * @type {number}
     * @memberof IExperimentDDLScriptFromExperimentProjection
     */
    'scriptId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IExperimentDDLScriptFromExperimentProjection
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentDDLScriptFromExperimentProjection
     */
    'genScriptContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentDDLScriptFromExperimentProjection
     */
    'realScriptContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentDDLScriptFromExperimentProjection
     */
    'scriptContent'?: string;
}
/**
 * 
 * @export
 * @interface IExperimentDDLScriptProjection
 */
export interface IExperimentDDLScriptProjection {
    /**
     * 
     * @type {number}
     * @memberof IExperimentDDLScriptProjection
     */
    'scriptId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IExperimentDDLScriptProjection
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentDDLScriptProjection
     */
    'genScriptContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentDDLScriptProjection
     */
    'generatedfilename'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentDDLScriptProjection
     */
    'realScriptContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentDDLScriptProjection
     */
    'scriptContent'?: string;
}
/**
 * 
 * @export
 * @interface IExperimentExecution
 */
export interface IExperimentExecution {
    /**
     * 
     * @type {number}
     * @memberof IExperimentExecution
     */
    'experimentExecutionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IExperimentExecution
     */
    'endExecution'?: string;
    /**
     * 
     * @type {number}
     * @memberof IExperimentExecution
     */
    'experimentStatus'?: number;
    /**
     * 
     * @type {string}
     * @memberof IExperimentExecution
     */
    'startExecution'?: string;
    /**
     * 
     * @type {Array<IExperimentLog>}
     * @memberof IExperimentExecution
     */
    'experimentLogs'?: Array<IExperimentLog>;
    /**
     * 
     * @type {IExperiment}
     * @memberof IExperimentExecution
     */
    'experiment'?: IExperiment;
}
/**
 * 
 * @export
 * @interface IExperimentExecutionProjection
 */
export interface IExperimentExecutionProjection {
    /**
     * 
     * @type {number}
     * @memberof IExperimentExecutionProjection
     */
    'experimentExecutionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IExperimentExecutionProjection
     */
    'startExecution'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentExecutionProjection
     */
    'endExecution'?: string;
    /**
     * 
     * @type {number}
     * @memberof IExperimentExecutionProjection
     */
    'experimentStatus'?: number;
    /**
     * 
     * @type {number}
     * @memberof IExperimentExecutionProjection
     */
    'experiment'?: number;
}
/**
 * 
 * @export
 * @interface IExperimentExecutionRequest
 */
export interface IExperimentExecutionRequest {
    /**
     * 
     * @type {number}
     * @memberof IExperimentExecutionRequest
     */
    'experimentExecutionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IExperimentExecutionRequest
     */
    'startExecution'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentExecutionRequest
     */
    'endExecution'?: string;
    /**
     * 
     * @type {number}
     * @memberof IExperimentExecutionRequest
     */
    'experimentStatus'?: number;
    /**
     * 
     * @type {number}
     * @memberof IExperimentExecutionRequest
     */
    'experimentId'?: number;
}
/**
 * 
 * @export
 * @interface IExperimentLog
 */
export interface IExperimentLog {
    /**
     * 
     * @type {number}
     * @memberof IExperimentLog
     */
    'experimentLogId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IExperimentLog
     */
    'experimentStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentLog
     */
    'experimentStatusMessage'?: string;
    /**
     * 
     * @type {IExperimentExecution}
     * @memberof IExperimentLog
     */
    'experimentExecution'?: IExperimentExecution;
    /**
     * 
     * @type {ITestbed}
     * @memberof IExperimentLog
     */
    'testbed'?: ITestbed;
}
/**
 * 
 * @export
 * @interface IExperimentProjection
 */
export interface IExperimentProjection {
    /**
     * 
     * @type {number}
     * @memberof IExperimentProjection
     */
    'experimentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IExperimentProjection
     */
    'experimentVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentProjection
     */
    'experimentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentProjection
     */
    'launchedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentProjection
     */
    'endedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentProjection
     */
    'experimentStatusLut'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentProjection
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExperimentProjection
     */
    'user'?: string;
    /**
     * 
     * @type {IScript}
     * @memberof IExperimentProjection
     */
    'ddlScript'?: IScript;
}
/**
 * 
 * @export
 * @interface IExperimentScript
 */
export interface IExperimentScript {
    /**
     * 
     * @type {IDdlScript}
     * @memberof IExperimentScript
     */
    'ddlScript'?: IDdlScript;
}
/**
 * 
 * @export
 * @interface IExperimentStatusLut
 */
export interface IExperimentStatusLut {
    /**
     * 
     * @type {number}
     * @memberof IExperimentStatusLut
     */
    'experimentStatusId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IExperimentStatusLut
     */
    'experimentStatusDescr'?: string;
    /**
     * 
     * @type {Array<IExperiment>}
     * @memberof IExperimentStatusLut
     */
    'experiments'?: Array<IExperiment>;
}
/**
 * 
 * @export
 * @interface IExperimentStatusProjection
 */
export interface IExperimentStatusProjection {
    /**
     * 
     * @type {number}
     * @memberof IExperimentStatusProjection
     */
    'experimentStatusId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IExperimentStatusProjection
     */
    'experimentStatusDescr'?: string;
}
/**
 * 
 * @export
 * @interface IExperimentStatusResponse
 */
export interface IExperimentStatusResponse {
    /**
     * 
     * @type {number}
     * @memberof IExperimentStatusResponse
     */
    'experimentStatusId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IExperimentStatusResponse
     */
    'experimentStatusDescr'?: string;
}
/**
 * 
 * @export
 * @interface IExperimentVersions
 */
export interface IExperimentVersions {
    /**
     * 
     * @type {string}
     * @memberof IExperimentVersions
     */
    'experimentVersion'?: string;
}
/**
 * 
 * @export
 * @interface IHealthStatus
 */
export interface IHealthStatus {
    /**
     * 
     * @type {number}
     * @memberof IHealthStatus
     */
    'healthStatusId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IHealthStatus
     */
    'healthStatusDescr'?: string;
}
/**
 * 
 * @export
 * @interface IHealthStatusResponse
 */
export interface IHealthStatusResponse {
    /**
     * 
     * @type {number}
     * @memberof IHealthStatusResponse
     */
    'healthStatusId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IHealthStatusResponse
     */
    'healthStatusDescr'?: string;
}
/**
 * 
 * @export
 * @interface IItem
 */
export interface IItem {
    /**
     * 
     * @type {boolean}
     * @memberof IItem
     */
    'mobile'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IItem
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IItem
     */
    'resourceType'?: string;
    /**
     * 
     * @type {number}
     * @memberof IItem
     */
    'resourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IItem
     */
    'resourceName'?: string;
    /**
     * 
     * @type {number}
     * @memberof IItem
     */
    'testbedId'?: number;
}
/**
 * 
 * @export
 * @interface IItemReservedResources
 */
export interface IItemReservedResources {
    /**
     * 
     * @type {boolean}
     * @memberof IItemReservedResources
     */
    'mobile'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IItemReservedResources
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IItemReservedResources
     */
    'resourceType'?: string;
    /**
     * 
     * @type {number}
     * @memberof IItemReservedResources
     */
    'resourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IItemReservedResources
     */
    'resourceName'?: string;
    /**
     * 
     * @type {number}
     * @memberof IItemReservedResources
     */
    'testbedId'?: number;
}
/**
 * 
 * @export
 * @interface ILocalTime
 */
export interface ILocalTime {
    /**
     * 
     * @type {number}
     * @memberof ILocalTime
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof ILocalTime
     */
    'minute'?: number;
    /**
     * 
     * @type {number}
     * @memberof ILocalTime
     */
    'second'?: number;
    /**
     * 
     * @type {number}
     * @memberof ILocalTime
     */
    'nano'?: number;
}
/**
 * 
 * @export
 * @interface ILocalTimeTestbedLocation
 */
export interface ILocalTimeTestbedLocation {
    /**
     * 
     * @type {number}
     * @memberof ILocalTimeTestbedLocation
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof ILocalTimeTestbedLocation
     */
    'minute'?: number;
    /**
     * 
     * @type {number}
     * @memberof ILocalTimeTestbedLocation
     */
    'second'?: number;
    /**
     * 
     * @type {number}
     * @memberof ILocalTimeTestbedLocation
     */
    'nano'?: number;
}
/**
 * 
 * @export
 * @interface IObstacle
 */
export interface IObstacle {
    /**
     * 
     * @type {number}
     * @memberof IObstacle
     */
    'obstacleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IObstacle
     */
    'obstacle': string;
    /**
     * 
     * @type {ITestbedArea}
     * @memberof IObstacle
     */
    'testbedArea'?: ITestbedArea;
}
/**
 * 
 * @export
 * @interface IObstacleProjection
 */
export interface IObstacleProjection {
    /**
     * 
     * @type {string}
     * @memberof IObstacleProjection
     */
    'obstacle'?: string;
}
/**
 * 
 * @export
 * @interface IObstacleProjectionTestbedAreaObstacles
 */
export interface IObstacleProjectionTestbedAreaObstacles {
    /**
     * 
     * @type {string}
     * @memberof IObstacleProjectionTestbedAreaObstacles
     */
    'obstacle'?: string;
}
/**
 * 
 * @export
 * @interface IOperatorCategoryResponse
 */
export interface IOperatorCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof IOperatorCategoryResponse
     */
    'operatorCategoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IOperatorCategoryResponse
     */
    'operatorCategory'?: string;
}
/**
 * 
 * @export
 * @interface IOperatorCreateRequest
 */
export interface IOperatorCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IOperatorCreateRequest
     */
    'resourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IOperatorCreateRequest
     */
    'resourceDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof IOperatorCreateRequest
     */
    'resourceLocation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IOperatorCreateRequest
     */
    'transferable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IOperatorCreateRequest
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof IOperatorCreateRequest
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IOperatorCreateRequest
     */
    'operatorName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IOperatorCreateRequest
     */
    'operatorCategory'?: string;
}
/**
 * 
 * @export
 * @interface IOperatorProperties
 */
export interface IOperatorProperties {
    /**
     * 
     * @type {string}
     * @memberof IOperatorProperties
     */
    'operatorCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof IOperatorProperties
     */
    'operatorName'?: string;
}
/**
 * 
 * @export
 * @interface IOperatorPropertiesOperator
 */
export interface IOperatorPropertiesOperator {
    /**
     * 
     * @type {string}
     * @memberof IOperatorPropertiesOperator
     */
    'operatorCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof IOperatorPropertiesOperator
     */
    'operatorName'?: string;
}
/**
 * 
 * @export
 * @interface IReservation
 */
export interface IReservation {
    /**
     * 
     * @type {number}
     * @memberof IReservation
     */
    'reservationId'?: number;
    /**
     * 
     * @type {IReservationStatus}
     * @memberof IReservation
     */
    'reservationStatus': IReservationStatus;
    /**
     * 
     * @type {string}
     * @memberof IReservation
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof IReservation
     */
    'validUntil': string;
    /**
     * 
     * @type {ITestbedArea}
     * @memberof IReservation
     */
    'testbedArea': ITestbedArea;
    /**
     * 
     * @type {IUser}
     * @memberof IReservation
     */
    'user': IUser;
    /**
     * 
     * @type {Array<IReservationItem>}
     * @memberof IReservation
     */
    'reservationItems': Array<IReservationItem>;
}
/**
 * 
 * @export
 * @interface IReservationItem
 */
export interface IReservationItem {
    /**
     * 
     * @type {number}
     * @memberof IReservationItem
     */
    'reservationItemId'?: number;
    /**
     * 
     * @type {IExperiment}
     * @memberof IReservationItem
     */
    'experiment'?: IExperiment;
    /**
     * 
     * @type {IReservation}
     * @memberof IReservationItem
     */
    'reservation'?: IReservation;
    /**
     * 
     * @type {IResource}
     * @memberof IReservationItem
     */
    'resource'?: IResource;
    /**
     * 
     * @type {boolean}
     * @memberof IReservationItem
     */
    'permitted'?: boolean;
}
/**
 * 
 * @export
 * @interface IReservationItemPermissionRequest
 */
export interface IReservationItemPermissionRequest {
    /**
     * 
     * @type {number}
     * @memberof IReservationItemPermissionRequest
     */
    'reservationItemId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IReservationItemPermissionRequest
     */
    'permission'?: boolean;
}
/**
 * 
 * @export
 * @interface IReservationItemProjection
 */
export interface IReservationItemProjection {
    /**
     * 
     * @type {IResourceProjection}
     * @memberof IReservationItemProjection
     */
    'resource'?: IResourceProjection;
}
/**
 * 
 * @export
 * @interface IReservationItemRequest
 */
export interface IReservationItemRequest {
    /**
     * 
     * @type {number}
     * @memberof IReservationItemRequest
     */
    'resourceId'?: number;
}
/**
 * 
 * @export
 * @interface IReservationItemStatus
 */
export interface IReservationItemStatus {
    /**
     * 
     * @type {number}
     * @memberof IReservationItemStatus
     */
    'reservationItemStatusId'?: number;
    /**
     * 
     * @type {IUser}
     * @memberof IReservationItemStatus
     */
    'user': IUser;
    /**
     * 
     * @type {boolean}
     * @memberof IReservationItemStatus
     */
    'permitted': boolean;
}
/**
 * 
 * @export
 * @interface IReservationItemStatusProjection
 */
export interface IReservationItemStatusProjection {
    /**
     * 
     * @type {number}
     * @memberof IReservationItemStatusProjection
     */
    'reservationItemId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IReservationItemStatusProjection
     */
    'destinationTestbedAreaName'?: string;
    /**
     * 
     * @type {number}
     * @memberof IReservationItemStatusProjection
     */
    'destinationTestbedAreaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IReservationItemStatusProjection
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationItemStatusProjection
     */
    'validUntil'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IReservationItemStatusProjection
     */
    'mobile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IReservationItemStatusProjection
     */
    'permitted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IReservationItemStatusProjection
     */
    'resourceType'?: string;
    /**
     * 
     * @type {number}
     * @memberof IReservationItemStatusProjection
     */
    'resourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IReservationItemStatusProjection
     */
    'resourceName'?: string;
}
/**
 * 
 * @export
 * @interface IReservationProjection
 */
export interface IReservationProjection {
    /**
     * 
     * @type {number}
     * @memberof IReservationProjection
     */
    'reservationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IReservationProjection
     */
    'reservationStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationProjection
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationProjection
     */
    'validUntil'?: string;
    /**
     * 
     * @type {number}
     * @memberof IReservationProjection
     */
    'user'?: number;
    /**
     * 
     * @type {number}
     * @memberof IReservationProjection
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {Array<IItem>}
     * @memberof IReservationProjection
     */
    'reservationItems'?: Array<IItem>;
}
/**
 * 
 * @export
 * @interface IReservationProjectionCustom
 */
export interface IReservationProjectionCustom {
    /**
     * 
     * @type {string}
     * @memberof IReservationProjectionCustom
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationProjectionCustom
     */
    'validUntil'?: string;
    /**
     * 
     * @type {Array<IObstacleProjection>}
     * @memberof IReservationProjectionCustom
     */
    'obstacles'?: Array<IObstacleProjection>;
    /**
     * 
     * @type {boolean}
     * @memberof IReservationProjectionCustom
     */
    'testbedAreaIndoor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IReservationProjectionCustom
     */
    'testbedAreaLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationProjectionCustom
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationProjectionCustom
     */
    'testbedAreaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationProjectionCustom
     */
    'testbedAreaPolygon'?: string;
    /**
     * 
     * @type {Array<IReservationItemProjection>}
     * @memberof IReservationProjectionCustom
     */
    'getreservationItems'?: Array<IReservationItemProjection>;
}
/**
 * 
 * @export
 * @interface IReservationProjectionReservedResources
 */
export interface IReservationProjectionReservedResources {
    /**
     * 
     * @type {number}
     * @memberof IReservationProjectionReservedResources
     */
    'reservationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IReservationProjectionReservedResources
     */
    'reservationStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationProjectionReservedResources
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationProjectionReservedResources
     */
    'validUntil'?: string;
    /**
     * 
     * @type {number}
     * @memberof IReservationProjectionReservedResources
     */
    'user'?: number;
    /**
     * 
     * @type {number}
     * @memberof IReservationProjectionReservedResources
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {Array<IItemReservedResources>}
     * @memberof IReservationProjectionReservedResources
     */
    'reservationItems'?: Array<IItemReservedResources>;
}
/**
 * 
 * @export
 * @interface IReservationRequest
 */
export interface IReservationRequest {
    /**
     * 
     * @type {number}
     * @memberof IReservationRequest
     */
    'reservationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IReservationRequest
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationRequest
     */
    'validUntil'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationRequest
     */
    'username'?: string;
    /**
     * 
     * @type {number}
     * @memberof IReservationRequest
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {Array<IReservationItemRequest>}
     * @memberof IReservationRequest
     */
    'reservationItems'?: Array<IReservationItemRequest>;
}
/**
 * 
 * @export
 * @interface IReservationStatus
 */
export interface IReservationStatus {
    /**
     * 
     * @type {number}
     * @memberof IReservationStatus
     */
    'reservationStatusId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IReservationStatus
     */
    'reservationStatus': string;
    /**
     * 
     * @type {Array<IReservation>}
     * @memberof IReservationStatus
     */
    'reservations'?: Array<IReservation>;
}
/**
 * 
 * @export
 * @interface IReservationStatusProjection
 */
export interface IReservationStatusProjection {
    /**
     * 
     * @type {string}
     * @memberof IReservationStatusProjection
     */
    'reservationStatus'?: string;
    /**
     * 
     * @type {number}
     * @memberof IReservationStatusProjection
     */
    'reservationStatusId'?: number;
}
/**
 * 
 * @export
 * @interface IReservationTimestamps
 */
export interface IReservationTimestamps {
    /**
     * 
     * @type {number}
     * @memberof IReservationTimestamps
     */
    'reservationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IReservationTimestamps
     */
    'validFromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationTimestamps
     */
    'validFromTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationTimestamps
     */
    'validUntilDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservationTimestamps
     */
    'validUntilTime'?: string;
}
/**
 * 
 * @export
 * @interface IResource
 */
export interface IResource {
    /**
     * 
     * @type {number}
     * @memberof IResource
     */
    'resourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IResource
     */
    'resourceName': string;
    /**
     * 
     * @type {string}
     * @memberof IResource
     */
    'resourceDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResource
     */
    'resourceLocation'?: string;
    /**
     * 
     * @type {number}
     * @memberof IResource
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof IResource
     */
    'preferedSrid'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IResource
     */
    'transferable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IResource
     */
    'mobile': boolean;
    /**
     * 
     * @type {Array<IReservationItem>}
     * @memberof IResource
     */
    'reservationItems'?: Array<IReservationItem>;
    /**
     * 
     * @type {IHealthStatus}
     * @memberof IResource
     */
    'healthStatus'?: IHealthStatus;
    /**
     * 
     * @type {IResourceStatus}
     * @memberof IResource
     */
    'resourceStatus'?: IResourceStatus;
    /**
     * 
     * @type {IResourceType}
     * @memberof IResource
     */
    'resourceType': IResourceType;
    /**
     * 
     * @type {ITestbedArea}
     * @memberof IResource
     */
    'testbedArea': ITestbedArea;
}
/**
 * 
 * @export
 * @interface IResourceDTO
 */
export interface IResourceDTO {
    /**
     * 
     * @type {number}
     * @memberof IResourceDTO
     */
    'resourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IResourceDTO
     */
    'resourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceDTO
     */
    'resourceDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceDTO
     */
    'resourceLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceDTO
     */
    'resourceStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceDTO
     */
    'healthStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceDTO
     */
    'resourceType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IResourceDTO
     */
    'transferable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IResourceDTO
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof IResourceDTO
     */
    'testbedId'?: number;
    /**
     * 
     * @type {number}
     * @memberof IResourceDTO
     */
    'preferedSrid'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IResourceDTO
     */
    'mobile'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IResourceDTO
     */
    'testbedAreaId'?: number;
}
/**
 * 
 * @export
 * @interface IResourceDTOList
 */
export interface IResourceDTOList {
    /**
     * 
     * @type {IResourceDTO}
     * @memberof IResourceDTOList
     */
    'resource'?: IResourceDTO;
}
/**
 * 
 * @export
 * @interface IResourceProjection
 */
export interface IResourceProjection {
    /**
     * 
     * @type {number}
     * @memberof IResourceProjection
     */
    'resourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjection
     */
    'resourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjection
     */
    'resourceDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjection
     */
    'resourceLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjection
     */
    'resourceStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjection
     */
    'healthStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjection
     */
    'resourceType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IResourceProjection
     */
    'transferable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IResourceProjection
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof IResourceProjection
     */
    'testbedId'?: number;
    /**
     * 
     * @type {number}
     * @memberof IResourceProjection
     */
    'preferedSrid'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IResourceProjection
     */
    'mobile'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IResourceProjection
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjection
     */
    'testbedName'?: string;
    /**
     * 
     * @type {ISensorProperties}
     * @memberof IResourceProjection
     */
    'sensor'?: ISensorProperties;
    /**
     * 
     * @type {IOperatorProperties}
     * @memberof IResourceProjection
     */
    'operator'?: IOperatorProperties;
    /**
     * 
     * @type {IUxVProperties}
     * @memberof IResourceProjection
     */
    'uxV'?: IUxVProperties;
    /**
     * 
     * @type {IEquipmentProperties}
     * @memberof IResourceProjection
     */
    'equipment'?: IEquipmentProperties;
}
/**
 * 
 * @export
 * @interface IResourceProjectionEquipment
 */
export interface IResourceProjectionEquipment {
    /**
     * 
     * @type {number}
     * @memberof IResourceProjectionEquipment
     */
    'resourceId'?: number;
    /**
     * 
     * @type {IEquipmentPropertiesEquipment}
     * @memberof IResourceProjectionEquipment
     */
    'equipment'?: IEquipmentPropertiesEquipment;
}
/**
 * 
 * @export
 * @interface IResourceProjectionOperator
 */
export interface IResourceProjectionOperator {
    /**
     * 
     * @type {number}
     * @memberof IResourceProjectionOperator
     */
    'resourceId'?: number;
    /**
     * 
     * @type {IOperatorPropertiesOperator}
     * @memberof IResourceProjectionOperator
     */
    'operator'?: IOperatorPropertiesOperator;
}
/**
 * 
 * @export
 * @interface IResourceProjectionResource
 */
export interface IResourceProjectionResource {
    /**
     * 
     * @type {number}
     * @memberof IResourceProjectionResource
     */
    'resourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjectionResource
     */
    'resourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjectionResource
     */
    'resourceDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjectionResource
     */
    'resourceLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjectionResource
     */
    'resourceStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjectionResource
     */
    'healthStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof IResourceProjectionResource
     */
    'resourceType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IResourceProjectionResource
     */
    'transferable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IResourceProjectionResource
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof IResourceProjectionResource
     */
    'testbedId'?: number;
    /**
     * 
     * @type {number}
     * @memberof IResourceProjectionResource
     */
    'preferedSrid'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IResourceProjectionResource
     */
    'mobile'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IResourceProjectionResource
     */
    'testbedAreaId'?: number;
}
/**
 * 
 * @export
 * @interface IResourceProjectionResourceId
 */
export interface IResourceProjectionResourceId {
    /**
     * 
     * @type {number}
     * @memberof IResourceProjectionResourceId
     */
    'resourceId'?: number;
}
/**
 * 
 * @export
 * @interface IResourceProjectionSensor
 */
export interface IResourceProjectionSensor {
    /**
     * 
     * @type {number}
     * @memberof IResourceProjectionSensor
     */
    'resourceId'?: number;
    /**
     * 
     * @type {ISensorPropertiesSensor}
     * @memberof IResourceProjectionSensor
     */
    'sensor'?: ISensorPropertiesSensor;
}
/**
 * 
 * @export
 * @interface IResourceProjectionUxV
 */
export interface IResourceProjectionUxV {
    /**
     * 
     * @type {number}
     * @memberof IResourceProjectionUxV
     */
    'resourceId'?: number;
    /**
     * 
     * @type {IUxVPropertiesUxV}
     * @memberof IResourceProjectionUxV
     */
    'uxV'?: IUxVPropertiesUxV;
}
/**
 * 
 * @export
 * @interface IResourceStatus
 */
export interface IResourceStatus {
    /**
     * 
     * @type {number}
     * @memberof IResourceStatus
     */
    'resourceStatusId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IResourceStatus
     */
    'resourceStatusDescr'?: string;
    /**
     * 
     * @type {Array<IResource>}
     * @memberof IResourceStatus
     */
    'resources'?: Array<IResource>;
}
/**
 * 
 * @export
 * @interface IResourceType
 */
export interface IResourceType {
    /**
     * 
     * @type {number}
     * @memberof IResourceType
     */
    'resourceTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IResourceType
     */
    'resourceType'?: string;
    /**
     * 
     * @type {Array<IResource>}
     * @memberof IResourceType
     */
    'resources'?: Array<IResource>;
}
/**
 * 
 * @export
 * @interface IResourceTypeResponse
 */
export interface IResourceTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof IResourceTypeResponse
     */
    'resourceTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IResourceTypeResponse
     */
    'resourceType'?: string;
}
/**
 * 
 * @export
 * @interface IScript
 */
export interface IScript {
    /**
     * 
     * @type {string}
     * @memberof IScript
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof IScript
     */
    'realScriptContent'?: string;
    /**
     * 
     * @type {number}
     * @memberof IScript
     */
    'scriptId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IScript
     */
    'genScriptContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof IScript
     */
    'scriptContent'?: string;
}
/**
 * 
 * @export
 * @interface ISensorCreateRequest
 */
export interface ISensorCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ISensorCreateRequest
     */
    'resourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISensorCreateRequest
     */
    'resourceDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISensorCreateRequest
     */
    'resourceLocation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ISensorCreateRequest
     */
    'transferable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ISensorCreateRequest
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof ISensorCreateRequest
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ISensorCreateRequest
     */
    'mobile'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ISensorCreateRequest
     */
    'sensorName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISensorCreateRequest
     */
    'canonicalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISensorCreateRequest
     */
    'unit'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISensorCreateRequest
     */
    'sensorType'?: string;
}
/**
 * 
 * @export
 * @interface ISensorProperties
 */
export interface ISensorProperties {
    /**
     * 
     * @type {string}
     * @memberof ISensorProperties
     */
    'canonicalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISensorProperties
     */
    'sensorType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISensorProperties
     */
    'sensorName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISensorProperties
     */
    'unit'?: string;
}
/**
 * 
 * @export
 * @interface ISensorPropertiesSensor
 */
export interface ISensorPropertiesSensor {
    /**
     * 
     * @type {string}
     * @memberof ISensorPropertiesSensor
     */
    'canonicalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISensorPropertiesSensor
     */
    'sensorType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISensorPropertiesSensor
     */
    'sensorName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISensorPropertiesSensor
     */
    'unit'?: string;
}
/**
 * 
 * @export
 * @interface ISensorTypeResponse
 */
export interface ISensorTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof ISensorTypeResponse
     */
    'uxvTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ISensorTypeResponse
     */
    'uxvType'?: string;
}
/**
 * 
 * @export
 * @interface ITestbed
 */
export interface ITestbed {
    /**
     * 
     * @type {number}
     * @memberof ITestbed
     */
    'testbedId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITestbed
     */
    'accuracy'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITestbed
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof ITestbed
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof ITestbed
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ITestbed
     */
    'description'?: string;
    /**
     * 
     * @type {ILocalTime}
     * @memberof ITestbed
     */
    'operationEndTime': ILocalTime;
    /**
     * 
     * @type {ILocalTime}
     * @memberof ITestbed
     */
    'operationStartTime': ILocalTime;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbed
     */
    'simulator': boolean;
    /**
     * 
     * @type {string}
     * @memberof ITestbed
     */
    'testbedStatusMessage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbed
     */
    'auvSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbed
     */
    'uavSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbed
     */
    'ugvSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbed
     */
    'usvSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbed
     */
    'vrEnabled'?: boolean;
    /**
     * 
     * @type {Array<IExperimentLog>}
     * @memberof ITestbed
     */
    'experimentLogs'?: Array<IExperimentLog>;
    /**
     * 
     * @type {Array<ITestbedArea>}
     * @memberof ITestbed
     */
    'testbedAreas'?: Array<ITestbedArea>;
    /**
     * 
     * @type {Array<IUserRoleAssoc>}
     * @memberof ITestbed
     */
    'userRoleAssocs'?: Array<IUserRoleAssoc>;
    /**
     * 
     * @type {IHealthStatus}
     * @memberof ITestbed
     */
    'healthStatus'?: IHealthStatus;
}
/**
 * 
 * @export
 * @interface ITestbedArea
 */
export interface ITestbedArea {
    /**
     * 
     * @type {number}
     * @memberof ITestbedArea
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITestbedArea
     */
    'name': string;
    /**
     * 
     * @type {ITestbed}
     * @memberof ITestbedArea
     */
    'testbed': ITestbed;
    /**
     * 
     * @type {string}
     * @memberof ITestbedArea
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedArea
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedArea
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedArea
     */
    'areaValidation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedArea
     */
    'indoor': boolean;
    /**
     * 
     * @type {string}
     * @memberof ITestbedArea
     */
    'mapImageName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedArea
     */
    'maxAltitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITestbedArea
     */
    'srid'?: number;
    /**
     * 
     * @type {Array<IReservation>}
     * @memberof ITestbedArea
     */
    'reservations'?: Array<IReservation>;
    /**
     * 
     * @type {Array<IResource>}
     * @memberof ITestbedArea
     */
    'resources'?: Array<IResource>;
    /**
     * 
     * @type {Array<IObstacle>}
     * @memberof ITestbedArea
     */
    'obstacles'?: Array<IObstacle>;
}
/**
 * 
 * @export
 * @interface ITestbedAreaCreateRequest
 */
export interface ITestbedAreaCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaCreateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaCreateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaCreateRequest
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaCreateRequest
     */
    'areaValidation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedAreaCreateRequest
     */
    'indoor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaCreateRequest
     */
    'mapImageName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaCreateRequest
     */
    'maxAltitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaCreateRequest
     */
    'srid'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaCreateRequest
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaCreateRequest
     */
    'testbedId'?: number;
}
/**
 * 
 * @export
 * @interface ITestbedAreaProjection
 */
export interface ITestbedAreaProjection {
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjection
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjection
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjection
     */
    'testbed'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjection
     */
    'testbedId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjection
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjection
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjection
     */
    'areaValidation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedAreaProjection
     */
    'indoor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjection
     */
    'mapImageName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjection
     */
    'maxAltitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjection
     */
    'srid'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjection
     */
    'obstacles'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjection
     */
    'location'?: string;
}
/**
 * 
 * @export
 * @interface ITestbedAreaProjectionOptions
 */
export interface ITestbedAreaProjectionOptions {
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptions
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptions
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptions
     */
    'testbed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptions
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptions
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptions
     */
    'areaValidation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedAreaProjectionOptions
     */
    'indoor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptions
     */
    'mapImageName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptions
     */
    'maxAltitude'?: number;
    /**
     * 
     * @type {Array<IObstacleProjection>}
     * @memberof ITestbedAreaProjectionOptions
     */
    'obstacles'?: Array<IObstacleProjection>;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptions
     */
    'srid'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptions
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptions
     */
    'testbedId'?: number;
}
/**
 * 
 * @export
 * @interface ITestbedAreaProjectionOptionsTestbedAreaIsIndoor
 */
export interface ITestbedAreaProjectionOptionsTestbedAreaIsIndoor {
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaIsIndoor
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaIsIndoor
     */
    'testbed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaIsIndoor
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaIsIndoor
     */
    'areaValidation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaIsIndoor
     */
    'indoor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaIsIndoor
     */
    'mapImageName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaIsIndoor
     */
    'maxAltitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaIsIndoor
     */
    'srid'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaIsIndoor
     */
    'testbedId'?: number;
}
/**
 * 
 * @export
 * @interface ITestbedAreaProjectionOptionsTestbedAreaLocation
 */
export interface ITestbedAreaProjectionOptionsTestbedAreaLocation {
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaLocation
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaLocation
     */
    'testbed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaLocation
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaLocation
     */
    'areaValidation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaLocation
     */
    'mapImageName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaLocation
     */
    'maxAltitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaLocation
     */
    'srid'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaLocation
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaLocation
     */
    'testbedId'?: number;
}
/**
 * 
 * @export
 * @interface ITestbedAreaProjectionOptionsTestbedAreaObstacles
 */
export interface ITestbedAreaProjectionOptionsTestbedAreaObstacles {
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaObstacles
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaObstacles
     */
    'testbed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaObstacles
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaObstacles
     */
    'areaValidation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaObstacles
     */
    'mapImageName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaObstacles
     */
    'maxAltitude'?: number;
    /**
     * 
     * @type {Array<IObstacleProjectionTestbedAreaObstacles>}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaObstacles
     */
    'obstacles'?: Array<IObstacleProjectionTestbedAreaObstacles>;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaObstacles
     */
    'srid'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaObstacles
     */
    'testbedId'?: number;
}
/**
 * 
 * @export
 * @interface ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId
 */
export interface ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId {
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId
     */
    'testbed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId
     */
    'areaValidation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId
     */
    'mapImageName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId
     */
    'maxAltitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId
     */
    'srid'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId
     */
    'testbedId'?: number;
}
/**
 * 
 * @export
 * @interface ITestbedCreateRequest
 */
export interface ITestbedCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ITestbedCreateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedCreateRequest
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedCreateRequest
     */
    'area'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedCreateRequest
     */
    'accuracy'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITestbedCreateRequest
     */
    'operationEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedCreateRequest
     */
    'operationStartTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedCreateRequest
     */
    'simulator'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ITestbedCreateRequest
     */
    'testbedStatusMessage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedCreateRequest
     */
    'auvSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedCreateRequest
     */
    'uavSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedCreateRequest
     */
    'ugvSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedCreateRequest
     */
    'usvSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedCreateRequest
     */
    'vrEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ITestbedCreateRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ITestbedDTO
 */
export interface ITestbedDTO {
    /**
     * 
     * @type {string}
     * @memberof ITestbedDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedDTO
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedDTO
     */
    'description'?: string;
    /**
     * 
     * @type {ITimeDTO}
     * @memberof ITestbedDTO
     */
    'operationStartTime'?: ITimeDTO;
    /**
     * 
     * @type {string}
     * @memberof ITestbedDTO
     */
    'testbedStatusMessage'?: string;
    /**
     * 
     * @type {ITimeDTO}
     * @memberof ITestbedDTO
     */
    'operationEndTime'?: ITimeDTO;
    /**
     * 
     * @type {string}
     * @memberof ITestbedDTO
     */
    'area'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedDTO
     */
    'testbedId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedDTO
     */
    'simulator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedDTO
     */
    'uavSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedDTO
     */
    'usvSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedDTO
     */
    'vrEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedDTO
     */
    'ugvSupport'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ITestbedDTO
     */
    'accuracy'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedDTO
     */
    'auvSupport'?: boolean;
}
/**
 * 
 * @export
 * @interface ITestbedProjection
 */
export interface ITestbedProjection {
    /**
     * 
     * @type {string}
     * @memberof ITestbedProjection
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedProjection
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedProjection
     */
    'description'?: string;
    /**
     * 
     * @type {ILocalTime}
     * @memberof ITestbedProjection
     */
    'operationStartTime'?: ILocalTime;
    /**
     * 
     * @type {string}
     * @memberof ITestbedProjection
     */
    'testbedStatusMessage'?: string;
    /**
     * 
     * @type {ILocalTime}
     * @memberof ITestbedProjection
     */
    'operationEndTime'?: ILocalTime;
    /**
     * 
     * @type {string}
     * @memberof ITestbedProjection
     */
    'area'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedProjection
     */
    'testbedId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedProjection
     */
    'simulator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedProjection
     */
    'uavSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedProjection
     */
    'usvSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedProjection
     */
    'vrEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedProjection
     */
    'ugvSupport'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ITestbedProjection
     */
    'accuracy'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedProjection
     */
    'auvSupport'?: boolean;
}
/**
 * 
 * @export
 * @interface ITestbedProjectionTestbedLocation
 */
export interface ITestbedProjectionTestbedLocation {
    /**
     * 
     * @type {string}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'description'?: string;
    /**
     * 
     * @type {ILocalTimeTestbedLocation}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'operationStartTime'?: ILocalTimeTestbedLocation;
    /**
     * 
     * @type {string}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'testbedStatusMessage'?: string;
    /**
     * 
     * @type {ILocalTimeTestbedLocation}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'operationEndTime'?: ILocalTimeTestbedLocation;
    /**
     * 
     * @type {string}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'area'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'testbedId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'simulator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'uavSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'usvSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'vrEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'ugvSupport'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'accuracy'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ITestbedProjectionTestbedLocation
     */
    'auvSupport'?: boolean;
}
/**
 * 
 * @export
 * @interface ITimeDTO
 */
export interface ITimeDTO {
    /**
     * 
     * @type {number}
     * @memberof ITimeDTO
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITimeDTO
     */
    'minute'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITimeDTO
     */
    'second'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITimeDTO
     */
    'nano'?: number;
}
/**
 * 
 * @export
 * @interface IUXVTypeResponse
 */
export interface IUXVTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof IUXVTypeResponse
     */
    'uxvTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IUXVTypeResponse
     */
    'uxvType'?: string;
}
/**
 * 
 * @export
 * @interface IUser
 */
export interface IUser {
    /**
     * 
     * @type {number}
     * @memberof IUser
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    'lastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    'username'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IUser
     */
    'isSuperuser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    'lastLogin'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    'dn'?: string;
    /**
     * 
     * @type {Array<IExperiment>}
     * @memberof IUser
     */
    'experiments'?: Array<IExperiment>;
    /**
     * 
     * @type {Array<IReservation>}
     * @memberof IUser
     */
    'reservations'?: Array<IReservation>;
    /**
     * 
     * @type {Array<IReservationItemStatus>}
     * @memberof IUser
     */
    'reservationItemStatus'?: Array<IReservationItemStatus>;
    /**
     * 
     * @type {Array<IUserRoleAssoc>}
     * @memberof IUser
     */
    'userRoleAssocs'?: Array<IUserRoleAssoc>;
    /**
     * 
     * @type {Array<IEthicsCheckInfo>}
     * @memberof IUser
     */
    'ethicsCheckInfos1'?: Array<IEthicsCheckInfo>;
    /**
     * 
     * @type {Array<IEthicsCheckInfo>}
     * @memberof IUser
     */
    'ethicsCheckInfos2'?: Array<IEthicsCheckInfo>;
    /**
     * 
     * @type {Array<IVRTraining>}
     * @memberof IUser
     */
    'vrtrainings'?: Array<IVRTraining>;
}
/**
 * 
 * @export
 * @interface IUserAssociationView
 */
export interface IUserAssociationView {
    /**
     * 
     * @type {string}
     * @memberof IUserAssociationView
     */
    'role'?: string;
    /**
     * 
     * @type {number}
     * @memberof IUserAssociationView
     */
    'testbedId'?: number;
}
/**
 * 
 * @export
 * @interface IUserProjection
 */
export interface IUserProjection {
    /**
     * 
     * @type {string}
     * @memberof IUserProjection
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUserProjection
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof IUserProjection
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IUserProjection
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUserProjection
     */
    'lastname'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IUserProjection
     */
    'isSuperuser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IUserProjection
     */
    'lastLogin'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUserProjection
     */
    'dn'?: string;
}
/**
 * 
 * @export
 * @interface IUserProjectionUsername
 */
export interface IUserProjectionUsername {
    /**
     * 
     * @type {string}
     * @memberof IUserProjectionUsername
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUserProjectionUsername
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof IUserProjectionUsername
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IUserProjectionUsername
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUserProjectionUsername
     */
    'lastname'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IUserProjectionUsername
     */
    'isSuperuser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IUserProjectionUsername
     */
    'lastLogin'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUserProjectionUsername
     */
    'dn'?: string;
}
/**
 * 
 * @export
 * @interface IUserRole
 */
export interface IUserRole {
    /**
     * 
     * @type {number}
     * @memberof IUserRole
     */
    'roleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IUserRole
     */
    'description'?: string;
    /**
     * 
     * @type {Array<IUserRoleAssoc>}
     * @memberof IUserRole
     */
    'userRoleAssocs'?: Array<IUserRoleAssoc>;
}
/**
 * 
 * @export
 * @interface IUserRoleAssoc
 */
export interface IUserRoleAssoc {
    /**
     * 
     * @type {number}
     * @memberof IUserRoleAssoc
     */
    'userAssocId'?: number;
    /**
     * 
     * @type {ITestbed}
     * @memberof IUserRoleAssoc
     */
    'testbed'?: ITestbed;
    /**
     * 
     * @type {IUser}
     * @memberof IUserRoleAssoc
     */
    'user'?: IUser;
    /**
     * 
     * @type {IUserRole}
     * @memberof IUserRoleAssoc
     */
    'userRole'?: IUserRole;
}
/**
 * 
 * @export
 * @interface IUserRoleResponse
 */
export interface IUserRoleResponse {
    /**
     * 
     * @type {number}
     * @memberof IUserRoleResponse
     */
    'roleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IUserRoleResponse
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface IUxVCreateRequest
 */
export interface IUxVCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IUxVCreateRequest
     */
    'resourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUxVCreateRequest
     */
    'resourceDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUxVCreateRequest
     */
    'resourceLocation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IUxVCreateRequest
     */
    'transferable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IUxVCreateRequest
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof IUxVCreateRequest
     */
    'testbedAreaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof IUxVCreateRequest
     */
    'minSpeed'?: number;
    /**
     * 
     * @type {number}
     * @memberof IUxVCreateRequest
     */
    'maxSpeed'?: number;
    /**
     * 
     * @type {number}
     * @memberof IUxVCreateRequest
     */
    'minAltitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof IUxVCreateRequest
     */
    'maxAltitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof IUxVCreateRequest
     */
    'UxVType'?: string;
}
/**
 * 
 * @export
 * @interface IUxVProperties
 */
export interface IUxVProperties {
    /**
     * 
     * @type {string}
     * @memberof IUxVProperties
     */
    'uxvType'?: string;
}
/**
 * 
 * @export
 * @interface IUxVPropertiesUxV
 */
export interface IUxVPropertiesUxV {
    /**
     * 
     * @type {string}
     * @memberof IUxVPropertiesUxV
     */
    'uxvType'?: string;
}
/**
 * 
 * @export
 * @interface IUxVSensorDTO
 */
export interface IUxVSensorDTO {
    /**
     * 
     * @type {number}
     * @memberof IUxVSensorDTO
     */
    'sensorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IUxVSensorDTO
     */
    'canonicalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUxVSensorDTO
     */
    'sensorName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUxVSensorDTO
     */
    'unit'?: string;
}
/**
 * 
 * @export
 * @interface IUxVSensorDTOList
 */
export interface IUxVSensorDTOList {
    /**
     * 
     * @type {Array<IUxVSensorDTO>}
     * @memberof IUxVSensorDTOList
     */
    'uxvSensors'?: Array<IUxVSensorDTO>;
}
/**
 * 
 * @export
 * @interface IVREnvironment
 */
export interface IVREnvironment {
    /**
     * 
     * @type {number}
     * @memberof IVREnvironment
     */
    'vrenvironmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IVREnvironment
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof IVREnvironment
     */
    'sceneId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IVREnvironment
     */
    'previewImagem'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IVREnvironment
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IVREnvironment
     */
    'lastModificationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IVREnvironment
     */
    'customPropList'?: string;
    /**
     * 
     * @type {string}
     * @memberof IVREnvironment
     */
    'extraData'?: string;
    /**
     * 
     * @type {string}
     * @memberof IVREnvironment
     */
    'kpis'?: string;
    /**
     * 
     * @type {Array<IVRTraining>}
     * @memberof IVREnvironment
     */
    'vrtrainings'?: Array<IVRTraining>;
}
/**
 * 
 * @export
 * @interface IVRTraining
 */
export interface IVRTraining {
    /**
     * 
     * @type {number}
     * @memberof IVRTraining
     */
    'id'?: number;
    /**
     * 
     * @type {IUser}
     * @memberof IVRTraining
     */
    'user'?: IUser;
    /**
     * 
     * @type {IVREnvironment}
     * @memberof IVRTraining
     */
    'vrenvironment'?: IVREnvironment;
    /**
     * 
     * @type {Array<IVRTrainingSession>}
     * @memberof IVRTraining
     */
    'vrTrainingSessions'?: Array<IVRTrainingSession>;
}
/**
 * 
 * @export
 * @interface IVRTrainingSession
 */
export interface IVRTrainingSession {
    /**
     * 
     * @type {number}
     * @memberof IVRTrainingSession
     */
    'id'?: number;
    /**
     * 
     * @type {IVRTraining}
     * @memberof IVRTrainingSession
     */
    'vrTraining'?: IVRTraining;
    /**
     * 
     * @type {string}
     * @memberof IVRTrainingSession
     */
    'trainingResults'?: string;
    /**
     * 
     * @type {string}
     * @memberof IVRTrainingSession
     */
    'trainingReplay'?: string;
}

/**
 * DdlScriptControllerApi - axios parameter creator
 * @export
 */
export const DdlScriptControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        all1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ddlScripts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDdlScript: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDdlScript', 'id', id)
            const localVarPath = `/ddlScripts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IDdlScript} iDdlScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newDdlScript: async (iDdlScript: IDdlScript, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iDdlScript' is not null or undefined
            assertParamExists('newDdlScript', 'iDdlScript', iDdlScript)
            const localVarPath = `/ddlScripts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iDdlScript, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        one2: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('one2', 'id', id)
            const localVarPath = `/ddlScripts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DdlScriptControllerApi - functional programming interface
 * @export
 */
export const DdlScriptControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DdlScriptControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async all1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IDdlScript>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.all1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDdlScript(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDdlScript(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IDdlScript} iDdlScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newDdlScript(iDdlScript: IDdlScript, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDdlScript>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newDdlScript(iDdlScript, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async one2(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDdlScript>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.one2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DdlScriptControllerApi - factory interface
 * @export
 */
export const DdlScriptControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DdlScriptControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        all1(options?: any): AxiosPromise<Array<IDdlScript>> {
            return localVarFp.all1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDdlScript(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDdlScript(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IDdlScript} iDdlScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newDdlScript(iDdlScript: IDdlScript, options?: any): AxiosPromise<IDdlScript> {
            return localVarFp.newDdlScript(iDdlScript, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        one2(id: number, options?: any): AxiosPromise<IDdlScript> {
            return localVarFp.one2(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DdlScriptControllerApi - object-oriented interface
 * @export
 * @class DdlScriptControllerApi
 * @extends {BaseAPI}
 */
export class DdlScriptControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DdlScriptControllerApi
     */
    public all1(options?: AxiosRequestConfig) {
        return DdlScriptControllerApiFp(this.configuration).all1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DdlScriptControllerApi
     */
    public deleteDdlScript(id: number, options?: AxiosRequestConfig) {
        return DdlScriptControllerApiFp(this.configuration).deleteDdlScript(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IDdlScript} iDdlScript 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DdlScriptControllerApi
     */
    public newDdlScript(iDdlScript: IDdlScript, options?: AxiosRequestConfig) {
        return DdlScriptControllerApiFp(this.configuration).newDdlScript(iDdlScript, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DdlScriptControllerApi
     */
    public one2(id: number, options?: AxiosRequestConfig) {
        return DdlScriptControllerApiFp(this.configuration).one2(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExperimentControllerApi - axios parameter creator
 * @export
 */
export const ExperimentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        all: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/experiments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allByUserId: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('allByUserId', 'userId', userId)
            const localVarPath = `/experimentsByUserId/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allByUsername: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('allByUsername', 'username', username)
            const localVarPath = `/experimentsByUsername/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allExperimentStatuses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/experimentStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allVersionsByUserId: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('allVersionsByUserId', 'userId', userId)
            const localVarPath = `/experimentVersionsByUserId/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExperiment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteExperiment', 'id', id)
            const localVarPath = `/experiments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} executionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDdlScriptByExecutionId: async (executionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'executionId' is not null or undefined
            assertParamExists('getDdlScriptByExecutionId', 'executionId', executionId)
            const localVarPath = `/ddlScriptByExecutionId/{executionId}`
                .replace(`{${"executionId"}}`, encodeURIComponent(String(executionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDdlScriptByExperimentId: async (experimentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('getDdlScriptByExperimentId', 'experimentId', experimentId)
            const localVarPath = `/ddlScriptByExperimentId/{experimentId}`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} experimentExecutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentByExecutionId: async (experimentExecutionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experimentExecutionId' is not null or undefined
            assertParamExists('getExperimentByExecutionId', 'experimentExecutionId', experimentExecutionId)
            const localVarPath = `/experimentByExecutionId/{experimentExecutionId}`
                .replace(`{${"experimentExecutionId"}}`, encodeURIComponent(String(experimentExecutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentById: async (experimentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('getExperimentById', 'experimentId', experimentId)
            const localVarPath = `/experiment/{experimentId}`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentExecutionByExperimentId: async (experimentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('getExperimentExecutionByExperimentId', 'experimentId', experimentId)
            const localVarPath = `/executionsByExperimentId/{experimentId}`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentExecutionById: async (experimentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('getExperimentExecutionById', 'experimentId', experimentId)
            const localVarPath = `/executionById/{experimentId}`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} statusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentExecutionByStatusId: async (statusId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'statusId' is not null or undefined
            assertParamExists('getExperimentExecutionByStatusId', 'statusId', statusId)
            const localVarPath = `/executionsByStatusId/{statusId}`
                .replace(`{${"statusId"}}`, encodeURIComponent(String(statusId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentStatusById: async (status: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('getExperimentStatusById', 'status', status)
            const localVarPath = `/experimentStatusByName/{status}`
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} statusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentStatusById1: async (statusId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'statusId' is not null or undefined
            assertParamExists('getExperimentStatusById1', 'statusId', statusId)
            const localVarPath = `/experimentStatusById/{statusId}`
                .replace(`{${"statusId"}}`, encodeURIComponent(String(statusId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} experimentVersion 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScriptContentByVersionAndUserName: async (experimentVersion: string, userName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experimentVersion' is not null or undefined
            assertParamExists('getScriptContentByVersionAndUserName', 'experimentVersion', experimentVersion)
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('getScriptContentByVersionAndUserName', 'userName', userName)
            const localVarPath = `/getScriptByVersionAndUserName/{experimentVersion}/{userName}`
                .replace(`{${"experimentVersion"}}`, encodeURIComponent(String(experimentVersion)))
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionsByUsername: async (userName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('getVersionsByUsername', 'userName', userName)
            const localVarPath = `/experimentVersionByUserName/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} experimentVersion 
         * @param {number} userId 
         * @param {IDdlScript} iDdlScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newDdlRealScript: async (experimentVersion: string, userId: number, iDdlScript: IDdlScript, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experimentVersion' is not null or undefined
            assertParamExists('newDdlRealScript', 'experimentVersion', experimentVersion)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('newDdlRealScript', 'userId', userId)
            // verify required parameter 'iDdlScript' is not null or undefined
            assertParamExists('newDdlRealScript', 'iDdlScript', iDdlScript)
            const localVarPath = `/saveScriptByVersionAndUserId/{experimentVersion}/{userId}`
                .replace(`{${"experimentVersion"}}`, encodeURIComponent(String(experimentVersion)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iDdlScript, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} experimentVersion 
         * @param {string} userName 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newDdlScriptContent: async (experimentVersion: string, userName: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experimentVersion' is not null or undefined
            assertParamExists('newDdlScriptContent', 'experimentVersion', experimentVersion)
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('newDdlScriptContent', 'userName', userName)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('newDdlScriptContent', 'body', body)
            const localVarPath = `/saveScriptContentByVersionAndUserName/{experimentVersion}/{userName}`
                .replace(`{${"experimentVersion"}}`, encodeURIComponent(String(experimentVersion)))
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IExperiment} iExperiment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newExperiment: async (iExperiment: IExperiment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iExperiment' is not null or undefined
            assertParamExists('newExperiment', 'iExperiment', iExperiment)
            const localVarPath = `/experiments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iExperiment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IExperimentExecutionRequest} iExperimentExecutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newExperimentExecution: async (iExperimentExecutionRequest: IExperimentExecutionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iExperimentExecutionRequest' is not null or undefined
            assertParamExists('newExperimentExecution', 'iExperimentExecutionRequest', iExperimentExecutionRequest)
            const localVarPath = `/saveExperimentExecution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iExperimentExecutionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IExperiment} iExperiment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceExperiment: async (id: number, iExperiment: IExperiment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceExperiment', 'id', id)
            // verify required parameter 'iExperiment' is not null or undefined
            assertParamExists('replaceExperiment', 'iExperiment', iExperiment)
            const localVarPath = `/experiments/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iExperiment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scriptByVersion: async (version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('scriptByVersion', 'version', version)
            const localVarPath = `/experimentScriptByVersion/{version}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExperimentControllerApi - functional programming interface
 * @export
 */
export const ExperimentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExperimentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async all(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IExperimentProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.all(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allByUserId(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IExperimentProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allByUsername(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IExperimentProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allByUsername(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allExperimentStatuses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IExperimentStatusResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allExperimentStatuses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allVersionsByUserId(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IExperimentVersions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allVersionsByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExperiment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExperiment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} executionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDdlScriptByExecutionId(executionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperimentDDLScriptFromExperimentProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDdlScriptByExecutionId(executionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDdlScriptByExperimentId(experimentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperimentDDLScriptProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDdlScriptByExperimentId(experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} experimentExecutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentByExecutionId(experimentExecutionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperimentProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentByExecutionId(experimentExecutionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentById(experimentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperimentProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentById(experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentExecutionByExperimentId(experimentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IExperimentExecutionProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentExecutionByExperimentId(experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentExecutionById(experimentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperimentExecutionProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentExecutionById(experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} statusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentExecutionByStatusId(statusId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IExperimentExecutionProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentExecutionByStatusId(statusId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentStatusById(status: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperimentStatusProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentStatusById(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} statusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentStatusById1(statusId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperimentStatusProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentStatusById1(statusId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} experimentVersion 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScriptContentByVersionAndUserName(experimentVersion: string, userName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperimentScript>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScriptContentByVersionAndUserName(experimentVersion, userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersionsByUsername(userName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IExperimentVersions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersionsByUsername(userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} experimentVersion 
         * @param {number} userId 
         * @param {IDdlScript} iDdlScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newDdlRealScript(experimentVersion: string, userId: number, iDdlScript: IDdlScript, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperiment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newDdlRealScript(experimentVersion, userId, iDdlScript, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} experimentVersion 
         * @param {string} userName 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newDdlScriptContent(experimentVersion: string, userName: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperiment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newDdlScriptContent(experimentVersion, userName, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IExperiment} iExperiment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newExperiment(iExperiment: IExperiment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperiment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newExperiment(iExperiment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IExperimentExecutionRequest} iExperimentExecutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newExperimentExecution(iExperimentExecutionRequest: IExperimentExecutionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperimentExecution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newExperimentExecution(iExperimentExecutionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IExperiment} iExperiment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceExperiment(id: number, iExperiment: IExperiment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperiment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceExperiment(id, iExperiment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scriptByVersion(version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExperimentScript>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scriptByVersion(version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExperimentControllerApi - factory interface
 * @export
 */
export const ExperimentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExperimentControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        all(options?: any): AxiosPromise<Array<IExperimentProjection>> {
            return localVarFp.all(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allByUserId(userId: number, options?: any): AxiosPromise<Array<IExperimentProjection>> {
            return localVarFp.allByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allByUsername(username: string, options?: any): AxiosPromise<Array<IExperimentProjection>> {
            return localVarFp.allByUsername(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allExperimentStatuses(options?: any): AxiosPromise<Array<IExperimentStatusResponse>> {
            return localVarFp.allExperimentStatuses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allVersionsByUserId(userId: number, options?: any): AxiosPromise<Array<IExperimentVersions>> {
            return localVarFp.allVersionsByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExperiment(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteExperiment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} executionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDdlScriptByExecutionId(executionId: number, options?: any): AxiosPromise<IExperimentDDLScriptFromExperimentProjection> {
            return localVarFp.getDdlScriptByExecutionId(executionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDdlScriptByExperimentId(experimentId: number, options?: any): AxiosPromise<IExperimentDDLScriptProjection> {
            return localVarFp.getDdlScriptByExperimentId(experimentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} experimentExecutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentByExecutionId(experimentExecutionId: number, options?: any): AxiosPromise<IExperimentProjection> {
            return localVarFp.getExperimentByExecutionId(experimentExecutionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentById(experimentId: number, options?: any): AxiosPromise<IExperimentProjection> {
            return localVarFp.getExperimentById(experimentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentExecutionByExperimentId(experimentId: number, options?: any): AxiosPromise<Array<IExperimentExecutionProjection>> {
            return localVarFp.getExperimentExecutionByExperimentId(experimentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentExecutionById(experimentId: number, options?: any): AxiosPromise<IExperimentExecutionProjection> {
            return localVarFp.getExperimentExecutionById(experimentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} statusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentExecutionByStatusId(statusId: number, options?: any): AxiosPromise<Array<IExperimentExecutionProjection>> {
            return localVarFp.getExperimentExecutionByStatusId(statusId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentStatusById(status: string, options?: any): AxiosPromise<IExperimentStatusProjection> {
            return localVarFp.getExperimentStatusById(status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} statusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentStatusById1(statusId: number, options?: any): AxiosPromise<IExperimentStatusProjection> {
            return localVarFp.getExperimentStatusById1(statusId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} experimentVersion 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScriptContentByVersionAndUserName(experimentVersion: string, userName: string, options?: any): AxiosPromise<IExperimentScript> {
            return localVarFp.getScriptContentByVersionAndUserName(experimentVersion, userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionsByUsername(userName: string, options?: any): AxiosPromise<Array<IExperimentVersions>> {
            return localVarFp.getVersionsByUsername(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} experimentVersion 
         * @param {number} userId 
         * @param {IDdlScript} iDdlScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newDdlRealScript(experimentVersion: string, userId: number, iDdlScript: IDdlScript, options?: any): AxiosPromise<IExperiment> {
            return localVarFp.newDdlRealScript(experimentVersion, userId, iDdlScript, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} experimentVersion 
         * @param {string} userName 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newDdlScriptContent(experimentVersion: string, userName: string, body: string, options?: any): AxiosPromise<IExperiment> {
            return localVarFp.newDdlScriptContent(experimentVersion, userName, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IExperiment} iExperiment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newExperiment(iExperiment: IExperiment, options?: any): AxiosPromise<IExperiment> {
            return localVarFp.newExperiment(iExperiment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IExperimentExecutionRequest} iExperimentExecutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newExperimentExecution(iExperimentExecutionRequest: IExperimentExecutionRequest, options?: any): AxiosPromise<IExperimentExecution> {
            return localVarFp.newExperimentExecution(iExperimentExecutionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {IExperiment} iExperiment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceExperiment(id: number, iExperiment: IExperiment, options?: any): AxiosPromise<IExperiment> {
            return localVarFp.replaceExperiment(id, iExperiment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scriptByVersion(version: string, options?: any): AxiosPromise<IExperimentScript> {
            return localVarFp.scriptByVersion(version, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExperimentControllerApi - object-oriented interface
 * @export
 * @class ExperimentControllerApi
 * @extends {BaseAPI}
 */
export class ExperimentControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public all(options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).all(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public allByUserId(userId: number, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).allByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public allByUsername(username: string, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).allByUsername(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public allExperimentStatuses(options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).allExperimentStatuses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public allVersionsByUserId(userId: number, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).allVersionsByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public deleteExperiment(id: number, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).deleteExperiment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} executionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getDdlScriptByExecutionId(executionId: number, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getDdlScriptByExecutionId(executionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} experimentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getDdlScriptByExperimentId(experimentId: number, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getDdlScriptByExperimentId(experimentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} experimentExecutionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getExperimentByExecutionId(experimentExecutionId: number, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getExperimentByExecutionId(experimentExecutionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} experimentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getExperimentById(experimentId: number, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getExperimentById(experimentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} experimentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getExperimentExecutionByExperimentId(experimentId: number, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getExperimentExecutionByExperimentId(experimentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} experimentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getExperimentExecutionById(experimentId: number, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getExperimentExecutionById(experimentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} statusId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getExperimentExecutionByStatusId(statusId: number, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getExperimentExecutionByStatusId(statusId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getExperimentStatusById(status: string, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getExperimentStatusById(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} statusId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getExperimentStatusById1(statusId: number, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getExperimentStatusById1(statusId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} experimentVersion 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getScriptContentByVersionAndUserName(experimentVersion: string, userName: string, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getScriptContentByVersionAndUserName(experimentVersion, userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getVersionsByUsername(userName: string, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getVersionsByUsername(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} experimentVersion 
     * @param {number} userId 
     * @param {IDdlScript} iDdlScript 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public newDdlRealScript(experimentVersion: string, userId: number, iDdlScript: IDdlScript, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).newDdlRealScript(experimentVersion, userId, iDdlScript, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} experimentVersion 
     * @param {string} userName 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public newDdlScriptContent(experimentVersion: string, userName: string, body: string, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).newDdlScriptContent(experimentVersion, userName, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IExperiment} iExperiment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public newExperiment(iExperiment: IExperiment, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).newExperiment(iExperiment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IExperimentExecutionRequest} iExperimentExecutionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public newExperimentExecution(iExperimentExecutionRequest: IExperimentExecutionRequest, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).newExperimentExecution(iExperimentExecutionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {IExperiment} iExperiment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public replaceExperiment(id: number, iExperiment: IExperiment, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).replaceExperiment(id, iExperiment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public scriptByVersion(version: string, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).scriptByVersion(version, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReservationControllerApi - axios parameter creator
 * @export
 */
export const ReservationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allApprovalLuts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/approvalLuts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReservation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteReservation', 'id', id)
            const localVarPath = `/deleteReservation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IReservationRequest} iReservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editReservation: async (iReservationRequest: IReservationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iReservationRequest' is not null or undefined
            assertParamExists('editReservation', 'iReservationRequest', iReservationRequest)
            const localVarPath = `/editReservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} validFromString 
         * @param {string} validUntilString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBetween: async (validFromString: string, validUntilString: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validFromString' is not null or undefined
            assertParamExists('findBetween', 'validFromString', validFromString)
            // verify required parameter 'validUntilString' is not null or undefined
            assertParamExists('findBetween', 'validUntilString', validUntilString)
            const localVarPath = `/reservations/{validFromString}/{validUntilString}`
                .replace(`{${"validFromString"}}`, encodeURIComponent(String(validFromString)))
                .replace(`{${"validUntilString"}}`, encodeURIComponent(String(validUntilString)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} validFromString 
         * @param {string} validUntilString 
         * @param {string} reservationStatus 
         * @param {number} testbedAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBetweenDatesUserArea: async (validFromString: string, validUntilString: string, reservationStatus: string, testbedAreaId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validFromString' is not null or undefined
            assertParamExists('findBetweenDatesUserArea', 'validFromString', validFromString)
            // verify required parameter 'validUntilString' is not null or undefined
            assertParamExists('findBetweenDatesUserArea', 'validUntilString', validUntilString)
            // verify required parameter 'reservationStatus' is not null or undefined
            assertParamExists('findBetweenDatesUserArea', 'reservationStatus', reservationStatus)
            // verify required parameter 'testbedAreaId' is not null or undefined
            assertParamExists('findBetweenDatesUserArea', 'testbedAreaId', testbedAreaId)
            const localVarPath = `/reservationsByStatusArea/{validFromString}/{validUntilString}/{reservationStatus}/{testbedAreaId}`
                .replace(`{${"validFromString"}}`, encodeURIComponent(String(validFromString)))
                .replace(`{${"validUntilString"}}`, encodeURIComponent(String(validUntilString)))
                .replace(`{${"reservationStatus"}}`, encodeURIComponent(String(reservationStatus)))
                .replace(`{${"testbedAreaId"}}`, encodeURIComponent(String(testbedAreaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} validFromString 
         * @param {string} validUntilString 
         * @param {string} reservationStatus 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBetweenDatesUserArea1: async (validFromString: string, validUntilString: string, reservationStatus: string, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validFromString' is not null or undefined
            assertParamExists('findBetweenDatesUserArea1', 'validFromString', validFromString)
            // verify required parameter 'validUntilString' is not null or undefined
            assertParamExists('findBetweenDatesUserArea1', 'validUntilString', validUntilString)
            // verify required parameter 'reservationStatus' is not null or undefined
            assertParamExists('findBetweenDatesUserArea1', 'reservationStatus', reservationStatus)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findBetweenDatesUserArea1', 'username', username)
            const localVarPath = `/reservations/{validFromString}/{validUntilString}/{reservationStatus}/{username}`
                .replace(`{${"validFromString"}}`, encodeURIComponent(String(validFromString)))
                .replace(`{${"validUntilString"}}`, encodeURIComponent(String(validUntilString)))
                .replace(`{${"reservationStatus"}}`, encodeURIComponent(String(reservationStatus)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} validFromString 
         * @param {string} validUntilString 
         * @param {string} reservationStatus 
         * @param {string} username 
         * @param {number} testbedAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBetweenDatesUserArea2: async (validFromString: string, validUntilString: string, reservationStatus: string, username: string, testbedAreaId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validFromString' is not null or undefined
            assertParamExists('findBetweenDatesUserArea2', 'validFromString', validFromString)
            // verify required parameter 'validUntilString' is not null or undefined
            assertParamExists('findBetweenDatesUserArea2', 'validUntilString', validUntilString)
            // verify required parameter 'reservationStatus' is not null or undefined
            assertParamExists('findBetweenDatesUserArea2', 'reservationStatus', reservationStatus)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findBetweenDatesUserArea2', 'username', username)
            // verify required parameter 'testbedAreaId' is not null or undefined
            assertParamExists('findBetweenDatesUserArea2', 'testbedAreaId', testbedAreaId)
            const localVarPath = `/reservations/{validFromString}/{validUntilString}/{reservationStatus}/{username}/{testbedAreaId}`
                .replace(`{${"validFromString"}}`, encodeURIComponent(String(validFromString)))
                .replace(`{${"validUntilString"}}`, encodeURIComponent(String(validUntilString)))
                .replace(`{${"reservationStatus"}}`, encodeURIComponent(String(reservationStatus)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"testbedAreaId"}}`, encodeURIComponent(String(testbedAreaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByStatus: async (reservationStatus: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationStatus' is not null or undefined
            assertParamExists('findByStatus', 'reservationStatus', reservationStatus)
            const localVarPath = `/reservationsByStatus/{reservationStatus}`
                .replace(`{${"reservationStatus"}}`, encodeURIComponent(String(reservationStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsByManager: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reservationsByManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsByUserTestbeds: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findReservationsByUserTestbeds', 'username', username)
            const localVarPath = `/INCLUDINGREST/rest/testbedarea/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsByUserUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reservationsCustom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsByUserUser1: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findReservationsByUserUser1', 'username', username)
            const localVarPath = `/INCLUDINGREST/rest/testbeds/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsByUsername: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findReservationsByUsername', 'username', username)
            const localVarPath = `/reservationsByUser/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {string} testbedAreaName 
         * @param {string} validFromDateString 
         * @param {string} validFromTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsByUsernameAndTestbedArea: async (username: string, testbedAreaName: string, validFromDateString: string, validFromTimeString: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findReservationsByUsernameAndTestbedArea', 'username', username)
            // verify required parameter 'testbedAreaName' is not null or undefined
            assertParamExists('findReservationsByUsernameAndTestbedArea', 'testbedAreaName', testbedAreaName)
            // verify required parameter 'validFromDateString' is not null or undefined
            assertParamExists('findReservationsByUsernameAndTestbedArea', 'validFromDateString', validFromDateString)
            // verify required parameter 'validFromTimeString' is not null or undefined
            assertParamExists('findReservationsByUsernameAndTestbedArea', 'validFromTimeString', validFromTimeString)
            const localVarPath = `/reservationByTimestamp/{username}/{testbedAreaName}/{validFromDateString}/{validFromTimeString}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"testbedAreaName"}}`, encodeURIComponent(String(testbedAreaName)))
                .replace(`{${"validFromDateString"}}`, encodeURIComponent(String(validFromDateString)))
                .replace(`{${"validFromTimeString"}}`, encodeURIComponent(String(validFromTimeString)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reservationStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsStatusById: async (reservationStatus: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationStatus' is not null or undefined
            assertParamExists('findReservationsStatusById', 'reservationStatus', reservationStatus)
            const localVarPath = `/reservationStatusByName/{reservation_status}`
                .replace(`{${"reservation_status"}}`, encodeURIComponent(String(reservationStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} reservationStatusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsStatusById1: async (reservationStatusId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationStatusId' is not null or undefined
            assertParamExists('findReservationsStatusById1', 'reservationStatusId', reservationStatusId)
            const localVarPath = `/reservationStatus/{reservation_status_id}`
                .replace(`{${"reservation_status_id"}}`, encodeURIComponent(String(reservationStatusId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {string} testbedAreaName 
         * @param {string} validFromDateString 
         * @param {string} validFromTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findResourcesCustom: async (username: string, testbedAreaName: string, validFromDateString: string, validFromTimeString: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findResourcesCustom', 'username', username)
            // verify required parameter 'testbedAreaName' is not null or undefined
            assertParamExists('findResourcesCustom', 'testbedAreaName', testbedAreaName)
            // verify required parameter 'validFromDateString' is not null or undefined
            assertParamExists('findResourcesCustom', 'validFromDateString', validFromDateString)
            // verify required parameter 'validFromTimeString' is not null or undefined
            assertParamExists('findResourcesCustom', 'validFromTimeString', validFromTimeString)
            const localVarPath = `/INCLUDINGREST/rest/resources/{username}/{testbedAreaName}/{validFromDateString}/{validFromTimeString}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"testbedAreaName"}}`, encodeURIComponent(String(testbedAreaName)))
                .replace(`{${"validFromDateString"}}`, encodeURIComponent(String(validFromDateString)))
                .replace(`{${"validFromTimeString"}}`, encodeURIComponent(String(validFromTimeString)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findresourcesReservedByUser: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findresourcesReservedByUser', 'username', username)
            const localVarPath = `/resourcesReservedByUser/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {string} testbedAreaName 
         * @param {string} validFromDateString 
         * @param {string} validFromTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findresourcesReservedByUserAndTimestamp: async (username: string, testbedAreaName: string, validFromDateString: string, validFromTimeString: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findresourcesReservedByUserAndTimestamp', 'username', username)
            // verify required parameter 'testbedAreaName' is not null or undefined
            assertParamExists('findresourcesReservedByUserAndTimestamp', 'testbedAreaName', testbedAreaName)
            // verify required parameter 'validFromDateString' is not null or undefined
            assertParamExists('findresourcesReservedByUserAndTimestamp', 'validFromDateString', validFromDateString)
            // verify required parameter 'validFromTimeString' is not null or undefined
            assertParamExists('findresourcesReservedByUserAndTimestamp', 'validFromTimeString', validFromTimeString)
            const localVarPath = `/resourcesReservedByUserAndDateTime/{username}/{testbedAreaName}/{validFromDateString}/{validFromTimeString}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"testbedAreaName"}}`, encodeURIComponent(String(testbedAreaName)))
                .replace(`{${"validFromDateString"}}`, encodeURIComponent(String(validFromDateString)))
                .replace(`{${"validFromTimeString"}}`, encodeURIComponent(String(validFromTimeString)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IReservationRequest} iReservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newReservation: async (iReservationRequest: IReservationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iReservationRequest' is not null or undefined
            assertParamExists('newReservation', 'iReservationRequest', iReservationRequest)
            const localVarPath = `/saveReservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} reservationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        one1: async (reservationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('one1', 'reservationId', reservationId)
            const localVarPath = `/reservation/{reservationId}`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {IReservationItemPermissionRequest} iReservationItemPermissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceEquipment1: async (username: string, iReservationItemPermissionRequest: IReservationItemPermissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('replaceEquipment1', 'username', username)
            // verify required parameter 'iReservationItemPermissionRequest' is not null or undefined
            assertParamExists('replaceEquipment1', 'iReservationItemPermissionRequest', iReservationItemPermissionRequest)
            const localVarPath = `/setReservationItemPermission`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iReservationItemPermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} reservationStatusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceReservation: async (id: number, reservationStatusId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceReservation', 'id', id)
            // verify required parameter 'reservationStatusId' is not null or undefined
            assertParamExists('replaceReservation', 'reservationStatusId', reservationStatusId)
            const localVarPath = `/setReservationStatus/{id}/{reservation_status_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"reservation_status_id"}}`, encodeURIComponent(String(reservationStatusId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationItems: async (testbedId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testbedId' is not null or undefined
            assertParamExists('reservationItems', 'testbedId', testbedId)
            const localVarPath = `/reservationItems/{testbedId}`
                .replace(`{${"testbedId"}}`, encodeURIComponent(String(testbedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationItemsByManager: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reservationItemsByManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesByExperimentId: async (experimentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('resourcesByExperimentId', 'experimentId', experimentId)
            const localVarPath = `/resourcesByExperimentId/{experimentId}`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReservationControllerApi - functional programming interface
 * @export
 */
export const ReservationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReservationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allApprovalLuts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IApprovalLutResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allApprovalLuts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReservation(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReservation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IReservationRequest} iReservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editReservation(iReservationRequest: IReservationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editReservation(iReservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} validFromString 
         * @param {string} validUntilString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBetween(validFromString: string, validUntilString: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IReservationProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBetween(validFromString, validUntilString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} validFromString 
         * @param {string} validUntilString 
         * @param {string} reservationStatus 
         * @param {number} testbedAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBetweenDatesUserArea(validFromString: string, validUntilString: string, reservationStatus: string, testbedAreaId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IReservationProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBetweenDatesUserArea(validFromString, validUntilString, reservationStatus, testbedAreaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} validFromString 
         * @param {string} validUntilString 
         * @param {string} reservationStatus 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBetweenDatesUserArea1(validFromString: string, validUntilString: string, reservationStatus: string, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IReservationProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBetweenDatesUserArea1(validFromString, validUntilString, reservationStatus, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} validFromString 
         * @param {string} validUntilString 
         * @param {string} reservationStatus 
         * @param {string} username 
         * @param {number} testbedAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBetweenDatesUserArea2(validFromString: string, validUntilString: string, reservationStatus: string, username: string, testbedAreaId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IReservationProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBetweenDatesUserArea2(validFromString, validUntilString, reservationStatus, username, testbedAreaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reservationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByStatus(reservationStatus: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IReservationProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByStatus(reservationStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReservationsByManager(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IReservationProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReservationsByManager(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReservationsByUserTestbeds(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICustomTestbedAreasResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReservationsByUserTestbeds(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReservationsByUserUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IReservationProjectionCustom>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReservationsByUserUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReservationsByUserUser1(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICustomBookedTestbedResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReservationsByUserUser1(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReservationsByUsername(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IReservationProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReservationsByUsername(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {string} testbedAreaName 
         * @param {string} validFromDateString 
         * @param {string} validFromTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReservationsByUsernameAndTestbedArea(username: string, testbedAreaName: string, validFromDateString: string, validFromTimeString: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReservationTimestamps>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReservationsByUsernameAndTestbedArea(username, testbedAreaName, validFromDateString, validFromTimeString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReservationsStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IReservationStatusProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReservationsStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reservationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReservationsStatusById(reservationStatus: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReservationStatusProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReservationsStatusById(reservationStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} reservationStatusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReservationsStatusById1(reservationStatusId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReservationStatusProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReservationsStatusById1(reservationStatusId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {string} testbedAreaName 
         * @param {string} validFromDateString 
         * @param {string} validFromTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findResourcesCustom(username: string, testbedAreaName: string, validFromDateString: string, validFromTimeString: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICustomBookedResourcesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findResourcesCustom(username, testbedAreaName, validFromDateString, validFromTimeString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findresourcesReservedByUser(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IReservationProjectionReservedResources>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findresourcesReservedByUser(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {string} testbedAreaName 
         * @param {string} validFromDateString 
         * @param {string} validFromTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findresourcesReservedByUserAndTimestamp(username: string, testbedAreaName: string, validFromDateString: string, validFromTimeString: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReservationProjectionReservedResources>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findresourcesReservedByUserAndTimestamp(username, testbedAreaName, validFromDateString, validFromTimeString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IReservationRequest} iReservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newReservation(iReservationRequest: IReservationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newReservation(iReservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} reservationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async one1(reservationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReservationProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.one1(reservationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {IReservationItemPermissionRequest} iReservationItemPermissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceEquipment1(username: string, iReservationItemPermissionRequest: IReservationItemPermissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceEquipment1(username, iReservationItemPermissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} reservationStatusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceReservation(id: number, reservationStatusId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceReservation(id, reservationStatusId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationItems(testbedId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IReservationItemStatusProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationItems(testbedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationItemsByManager(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IReservationItemStatusProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationItemsByManager(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resourcesByExperimentId(experimentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IResourceDTOList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resourcesByExperimentId(experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReservationControllerApi - factory interface
 * @export
 */
export const ReservationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReservationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allApprovalLuts(options?: any): AxiosPromise<Array<IApprovalLutResponse>> {
            return localVarFp.allApprovalLuts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReservation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReservation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IReservationRequest} iReservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editReservation(iReservationRequest: IReservationRequest, options?: any): AxiosPromise<string> {
            return localVarFp.editReservation(iReservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} validFromString 
         * @param {string} validUntilString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBetween(validFromString: string, validUntilString: string, options?: any): AxiosPromise<Array<IReservationProjection>> {
            return localVarFp.findBetween(validFromString, validUntilString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} validFromString 
         * @param {string} validUntilString 
         * @param {string} reservationStatus 
         * @param {number} testbedAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBetweenDatesUserArea(validFromString: string, validUntilString: string, reservationStatus: string, testbedAreaId: number, options?: any): AxiosPromise<Array<IReservationProjection>> {
            return localVarFp.findBetweenDatesUserArea(validFromString, validUntilString, reservationStatus, testbedAreaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} validFromString 
         * @param {string} validUntilString 
         * @param {string} reservationStatus 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBetweenDatesUserArea1(validFromString: string, validUntilString: string, reservationStatus: string, username: string, options?: any): AxiosPromise<Array<IReservationProjection>> {
            return localVarFp.findBetweenDatesUserArea1(validFromString, validUntilString, reservationStatus, username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} validFromString 
         * @param {string} validUntilString 
         * @param {string} reservationStatus 
         * @param {string} username 
         * @param {number} testbedAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBetweenDatesUserArea2(validFromString: string, validUntilString: string, reservationStatus: string, username: string, testbedAreaId: number, options?: any): AxiosPromise<Array<IReservationProjection>> {
            return localVarFp.findBetweenDatesUserArea2(validFromString, validUntilString, reservationStatus, username, testbedAreaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reservationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByStatus(reservationStatus: string, options?: any): AxiosPromise<Array<IReservationProjection>> {
            return localVarFp.findByStatus(reservationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsByManager(options?: any): AxiosPromise<Array<IReservationProjection>> {
            return localVarFp.findReservationsByManager(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsByUserTestbeds(username: string, options?: any): AxiosPromise<Array<ICustomTestbedAreasResponse>> {
            return localVarFp.findReservationsByUserTestbeds(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsByUserUser(options?: any): AxiosPromise<Array<IReservationProjectionCustom>> {
            return localVarFp.findReservationsByUserUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsByUserUser1(username: string, options?: any): AxiosPromise<Array<ICustomBookedTestbedResponse>> {
            return localVarFp.findReservationsByUserUser1(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsByUsername(username: string, options?: any): AxiosPromise<Array<IReservationProjection>> {
            return localVarFp.findReservationsByUsername(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {string} testbedAreaName 
         * @param {string} validFromDateString 
         * @param {string} validFromTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsByUsernameAndTestbedArea(username: string, testbedAreaName: string, validFromDateString: string, validFromTimeString: string, options?: any): AxiosPromise<IReservationTimestamps> {
            return localVarFp.findReservationsByUsernameAndTestbedArea(username, testbedAreaName, validFromDateString, validFromTimeString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsStatus(options?: any): AxiosPromise<Array<IReservationStatusProjection>> {
            return localVarFp.findReservationsStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reservationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsStatusById(reservationStatus: string, options?: any): AxiosPromise<IReservationStatusProjection> {
            return localVarFp.findReservationsStatusById(reservationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} reservationStatusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationsStatusById1(reservationStatusId: number, options?: any): AxiosPromise<IReservationStatusProjection> {
            return localVarFp.findReservationsStatusById1(reservationStatusId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {string} testbedAreaName 
         * @param {string} validFromDateString 
         * @param {string} validFromTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findResourcesCustom(username: string, testbedAreaName: string, validFromDateString: string, validFromTimeString: string, options?: any): AxiosPromise<Array<ICustomBookedResourcesResponse>> {
            return localVarFp.findResourcesCustom(username, testbedAreaName, validFromDateString, validFromTimeString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findresourcesReservedByUser(username: string, options?: any): AxiosPromise<Array<IReservationProjectionReservedResources>> {
            return localVarFp.findresourcesReservedByUser(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {string} testbedAreaName 
         * @param {string} validFromDateString 
         * @param {string} validFromTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findresourcesReservedByUserAndTimestamp(username: string, testbedAreaName: string, validFromDateString: string, validFromTimeString: string, options?: any): AxiosPromise<IReservationProjectionReservedResources> {
            return localVarFp.findresourcesReservedByUserAndTimestamp(username, testbedAreaName, validFromDateString, validFromTimeString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IReservationRequest} iReservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newReservation(iReservationRequest: IReservationRequest, options?: any): AxiosPromise<IReservation> {
            return localVarFp.newReservation(iReservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} reservationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        one1(reservationId: number, options?: any): AxiosPromise<IReservationProjection> {
            return localVarFp.one1(reservationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {IReservationItemPermissionRequest} iReservationItemPermissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceEquipment1(username: string, iReservationItemPermissionRequest: IReservationItemPermissionRequest, options?: any): AxiosPromise<string> {
            return localVarFp.replaceEquipment1(username, iReservationItemPermissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} reservationStatusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceReservation(id: number, reservationStatusId: number, options?: any): AxiosPromise<string> {
            return localVarFp.replaceReservation(id, reservationStatusId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationItems(testbedId: number, options?: any): AxiosPromise<Array<IReservationItemStatusProjection>> {
            return localVarFp.reservationItems(testbedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationItemsByManager(options?: any): AxiosPromise<Array<IReservationItemStatusProjection>> {
            return localVarFp.reservationItemsByManager(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesByExperimentId(experimentId: number, options?: any): AxiosPromise<Array<IResourceDTOList>> {
            return localVarFp.resourcesByExperimentId(experimentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReservationControllerApi - object-oriented interface
 * @export
 * @class ReservationControllerApi
 * @extends {BaseAPI}
 */
export class ReservationControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public allApprovalLuts(options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).allApprovalLuts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public deleteReservation(id: number, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).deleteReservation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IReservationRequest} iReservationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public editReservation(iReservationRequest: IReservationRequest, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).editReservation(iReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} validFromString 
     * @param {string} validUntilString 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findBetween(validFromString: string, validUntilString: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findBetween(validFromString, validUntilString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} validFromString 
     * @param {string} validUntilString 
     * @param {string} reservationStatus 
     * @param {number} testbedAreaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findBetweenDatesUserArea(validFromString: string, validUntilString: string, reservationStatus: string, testbedAreaId: number, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findBetweenDatesUserArea(validFromString, validUntilString, reservationStatus, testbedAreaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} validFromString 
     * @param {string} validUntilString 
     * @param {string} reservationStatus 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findBetweenDatesUserArea1(validFromString: string, validUntilString: string, reservationStatus: string, username: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findBetweenDatesUserArea1(validFromString, validUntilString, reservationStatus, username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} validFromString 
     * @param {string} validUntilString 
     * @param {string} reservationStatus 
     * @param {string} username 
     * @param {number} testbedAreaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findBetweenDatesUserArea2(validFromString: string, validUntilString: string, reservationStatus: string, username: string, testbedAreaId: number, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findBetweenDatesUserArea2(validFromString, validUntilString, reservationStatus, username, testbedAreaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reservationStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findByStatus(reservationStatus: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findByStatus(reservationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findReservationsByManager(options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findReservationsByManager(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findReservationsByUserTestbeds(username: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findReservationsByUserTestbeds(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findReservationsByUserUser(options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findReservationsByUserUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findReservationsByUserUser1(username: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findReservationsByUserUser1(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findReservationsByUsername(username: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findReservationsByUsername(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {string} testbedAreaName 
     * @param {string} validFromDateString 
     * @param {string} validFromTimeString 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findReservationsByUsernameAndTestbedArea(username: string, testbedAreaName: string, validFromDateString: string, validFromTimeString: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findReservationsByUsernameAndTestbedArea(username, testbedAreaName, validFromDateString, validFromTimeString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findReservationsStatus(options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findReservationsStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reservationStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findReservationsStatusById(reservationStatus: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findReservationsStatusById(reservationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} reservationStatusId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findReservationsStatusById1(reservationStatusId: number, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findReservationsStatusById1(reservationStatusId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {string} testbedAreaName 
     * @param {string} validFromDateString 
     * @param {string} validFromTimeString 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findResourcesCustom(username: string, testbedAreaName: string, validFromDateString: string, validFromTimeString: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findResourcesCustom(username, testbedAreaName, validFromDateString, validFromTimeString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findresourcesReservedByUser(username: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findresourcesReservedByUser(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {string} testbedAreaName 
     * @param {string} validFromDateString 
     * @param {string} validFromTimeString 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public findresourcesReservedByUserAndTimestamp(username: string, testbedAreaName: string, validFromDateString: string, validFromTimeString: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).findresourcesReservedByUserAndTimestamp(username, testbedAreaName, validFromDateString, validFromTimeString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IReservationRequest} iReservationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public newReservation(iReservationRequest: IReservationRequest, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).newReservation(iReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} reservationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public one1(reservationId: number, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).one1(reservationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {IReservationItemPermissionRequest} iReservationItemPermissionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public replaceEquipment1(username: string, iReservationItemPermissionRequest: IReservationItemPermissionRequest, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).replaceEquipment1(username, iReservationItemPermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} reservationStatusId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public replaceReservation(id: number, reservationStatusId: number, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).replaceReservation(id, reservationStatusId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} testbedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public reservationItems(testbedId: number, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).reservationItems(testbedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public reservationItemsByManager(options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).reservationItemsByManager(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} experimentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public resourcesByExperimentId(experimentId: number, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).resourcesByExperimentId(experimentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ResourceControllerApi - axios parameter creator
 * @export
 */
export const ResourceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allEquipment: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allHealtStatuses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allOperatorCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/operatorCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allOperators: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/operators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allResourceTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/resourseTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allResources: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSensorTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sensorTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSensors: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sensors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUxVTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/uxvTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUxVs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/uxv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEquipment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEquipment', 'id', id)
            const localVarPath = `/equipment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOperator: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOperator', 'id', id)
            const localVarPath = `/operators/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSensor: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSensor', 'id', id)
            const localVarPath = `/sensor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUxV: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUxV', 'id', id)
            const localVarPath = `/uxvs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('equipmentById', 'id', id)
            const localVarPath = `/equipment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceIdByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getResourceIdByName', 'name', name)
            const localVarPath = `/resourceIdByName/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IEquipmentCreateRequest} iEquipmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newEquipment: async (iEquipmentCreateRequest: IEquipmentCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iEquipmentCreateRequest' is not null or undefined
            assertParamExists('newEquipment', 'iEquipmentCreateRequest', iEquipmentCreateRequest)
            const localVarPath = `/saveEquipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iEquipmentCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IOperatorCreateRequest} iOperatorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newOperator: async (iOperatorCreateRequest: IOperatorCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iOperatorCreateRequest' is not null or undefined
            assertParamExists('newOperator', 'iOperatorCreateRequest', iOperatorCreateRequest)
            const localVarPath = `/saveOperator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOperatorCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ISensorCreateRequest} iSensorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newSensor: async (iSensorCreateRequest: ISensorCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iSensorCreateRequest' is not null or undefined
            assertParamExists('newSensor', 'iSensorCreateRequest', iSensorCreateRequest)
            const localVarPath = `/saveSensor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iSensorCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IUxVCreateRequest} iUxVCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newUxV: async (iUxVCreateRequest: IUxVCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iUxVCreateRequest' is not null or undefined
            assertParamExists('newUxV', 'iUxVCreateRequest', iUxVCreateRequest)
            const localVarPath = `/saveUxV`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUxVCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('operatorById', 'id', id)
            const localVarPath = `/operator/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IEquipmentCreateRequest} iEquipmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceEquipment: async (id: number, iEquipmentCreateRequest: IEquipmentCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceEquipment', 'id', id)
            // verify required parameter 'iEquipmentCreateRequest' is not null or undefined
            assertParamExists('replaceEquipment', 'iEquipmentCreateRequest', iEquipmentCreateRequest)
            const localVarPath = `/updateEquipment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iEquipmentCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IOperatorCreateRequest} iOperatorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOperator: async (id: number, iOperatorCreateRequest: IOperatorCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceOperator', 'id', id)
            // verify required parameter 'iOperatorCreateRequest' is not null or undefined
            assertParamExists('replaceOperator', 'iOperatorCreateRequest', iOperatorCreateRequest)
            const localVarPath = `/updateOperator/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOperatorCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ISensorCreateRequest} iSensorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceSensor: async (id: number, iSensorCreateRequest: ISensorCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceSensor', 'id', id)
            // verify required parameter 'iSensorCreateRequest' is not null or undefined
            assertParamExists('replaceSensor', 'iSensorCreateRequest', iSensorCreateRequest)
            const localVarPath = `/sensors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iSensorCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IUxVCreateRequest} iUxVCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceUxV: async (id: number, iUxVCreateRequest: IUxVCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceUxV', 'id', id)
            // verify required parameter 'iUxVCreateRequest' is not null or undefined
            assertParamExists('replaceUxV', 'iUxVCreateRequest', iUxVCreateRequest)
            const localVarPath = `/updateUxV/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUxVCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resourceById', 'id', id)
            const localVarPath = `/resource/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceByManager: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/resourcesByManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('resourceByName', 'name', name)
            const localVarPath = `/resourceByName/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} testbedAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceByTestbedAreaId: async (testbedAreaId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testbedAreaId' is not null or undefined
            assertParamExists('resourceByTestbedAreaId', 'testbedAreaId', testbedAreaId)
            const localVarPath = `/resourcesByTestbedAreaId/{testbedAreaId}`
                .replace(`{${"testbedAreaId"}}`, encodeURIComponent(String(testbedAreaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceByTestbedId: async (testbedId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testbedId' is not null or undefined
            assertParamExists('resourceByTestbedId', 'testbedId', testbedId)
            const localVarPath = `/resourcesByTestbedId/{testbedId}`
                .replace(`{${"testbedId"}}`, encodeURIComponent(String(testbedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sensorById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sensorById', 'id', id)
            const localVarPath = `/sensor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sensorsOfUxV: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sensorsOfUxV', 'id', id)
            const localVarPath = `/sensorsOfUxV/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sensorsOfUxVByUxVId: async (resourceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('sensorsOfUxVByUxVId', 'resourceId', resourceId)
            const localVarPath = `/sensorsOfUxVByUxVId/{resourceId}`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uxvById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uxvById', 'id', id)
            const localVarPath = `/uxv/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceControllerApi - functional programming interface
 * @export
 */
export const ResourceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResourceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allEquipment(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IResourceProjectionEquipment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allEquipment(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allHealtStatuses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IHealthStatusResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allHealtStatuses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allOperatorCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IOperatorCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allOperatorCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allOperators(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IResourceProjectionOperator>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allOperators(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allResourceTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IResourceTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allResourceTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allResources(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IResourceProjectionResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allResources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSensorTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ISensorTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSensorTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSensors(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IResourceProjectionSensor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSensors(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUxVTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IUXVTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUxVTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUxVs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IResourceProjectionUxV>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUxVs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEquipment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEquipment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOperator(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOperator(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSensor(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSensor(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUxV(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUxV(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResourceProjectionEquipment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourceIdByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IResourceProjectionResourceId>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourceIdByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IEquipmentCreateRequest} iEquipmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newEquipment(iEquipmentCreateRequest: IEquipmentCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newEquipment(iEquipmentCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IOperatorCreateRequest} iOperatorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newOperator(iOperatorCreateRequest: IOperatorCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newOperator(iOperatorCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ISensorCreateRequest} iSensorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newSensor(iSensorCreateRequest: ISensorCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newSensor(iSensorCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IUxVCreateRequest} iUxVCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newUxV(iUxVCreateRequest: IUxVCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newUxV(iUxVCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async operatorById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResourceProjectionOperator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.operatorById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IEquipmentCreateRequest} iEquipmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceEquipment(id: number, iEquipmentCreateRequest: IEquipmentCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceEquipment(id, iEquipmentCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IOperatorCreateRequest} iOperatorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOperator(id: number, iOperatorCreateRequest: IOperatorCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceOperator(id, iOperatorCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ISensorCreateRequest} iSensorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceSensor(id: number, iSensorCreateRequest: ISensorCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceSensor(id, iSensorCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IUxVCreateRequest} iUxVCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceUxV(id: number, iUxVCreateRequest: IUxVCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceUxV(id, iUxVCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resourceById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResourceProjectionResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resourceById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resourceByManager(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IResourceProjectionResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resourceByManager(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resourceByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResourceProjectionResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resourceByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} testbedAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resourceByTestbedAreaId(testbedAreaId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IResourceProjectionResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resourceByTestbedAreaId(testbedAreaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resourceByTestbedId(testbedId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IResourceProjectionResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resourceByTestbedId(testbedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sensorById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResourceProjectionSensor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sensorById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sensorsOfUxV(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sensorsOfUxV(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sensorsOfUxVByUxVId(resourceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUxVSensorDTOList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sensorsOfUxVByUxVId(resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uxvById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResourceProjectionUxV>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uxvById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResourceControllerApi - factory interface
 * @export
 */
export const ResourceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResourceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allEquipment(options?: any): AxiosPromise<Array<IResourceProjectionEquipment>> {
            return localVarFp.allEquipment(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allHealtStatuses(options?: any): AxiosPromise<Array<IHealthStatusResponse>> {
            return localVarFp.allHealtStatuses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allOperatorCategories(options?: any): AxiosPromise<Array<IOperatorCategoryResponse>> {
            return localVarFp.allOperatorCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allOperators(options?: any): AxiosPromise<Array<IResourceProjectionOperator>> {
            return localVarFp.allOperators(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allResourceTypes(options?: any): AxiosPromise<Array<IResourceTypeResponse>> {
            return localVarFp.allResourceTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allResources(options?: any): AxiosPromise<Array<IResourceProjectionResource>> {
            return localVarFp.allResources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSensorTypes(options?: any): AxiosPromise<Array<ISensorTypeResponse>> {
            return localVarFp.allSensorTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSensors(options?: any): AxiosPromise<Array<IResourceProjectionSensor>> {
            return localVarFp.allSensors(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUxVTypes(options?: any): AxiosPromise<Array<IUXVTypeResponse>> {
            return localVarFp.allUxVTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUxVs(options?: any): AxiosPromise<Array<IResourceProjectionUxV>> {
            return localVarFp.allUxVs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEquipment(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEquipment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOperator(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOperator(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSensor(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSensor(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUxV(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUxV(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentById(id: number, options?: any): AxiosPromise<IResourceProjectionEquipment> {
            return localVarFp.equipmentById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceIdByName(name: string, options?: any): AxiosPromise<Array<IResourceProjectionResourceId>> {
            return localVarFp.getResourceIdByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IEquipmentCreateRequest} iEquipmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newEquipment(iEquipmentCreateRequest: IEquipmentCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.newEquipment(iEquipmentCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IOperatorCreateRequest} iOperatorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newOperator(iOperatorCreateRequest: IOperatorCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.newOperator(iOperatorCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ISensorCreateRequest} iSensorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newSensor(iSensorCreateRequest: ISensorCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.newSensor(iSensorCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IUxVCreateRequest} iUxVCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newUxV(iUxVCreateRequest: IUxVCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.newUxV(iUxVCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorById(id: number, options?: any): AxiosPromise<IResourceProjectionOperator> {
            return localVarFp.operatorById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {IEquipmentCreateRequest} iEquipmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceEquipment(id: number, iEquipmentCreateRequest: IEquipmentCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.replaceEquipment(id, iEquipmentCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {IOperatorCreateRequest} iOperatorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOperator(id: number, iOperatorCreateRequest: IOperatorCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.replaceOperator(id, iOperatorCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ISensorCreateRequest} iSensorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceSensor(id: number, iSensorCreateRequest: ISensorCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.replaceSensor(id, iSensorCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {IUxVCreateRequest} iUxVCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceUxV(id: number, iUxVCreateRequest: IUxVCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.replaceUxV(id, iUxVCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceById(id: number, options?: any): AxiosPromise<IResourceProjectionResource> {
            return localVarFp.resourceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceByManager(options?: any): AxiosPromise<Array<IResourceProjectionResource>> {
            return localVarFp.resourceByManager(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceByName(name: string, options?: any): AxiosPromise<IResourceProjectionResource> {
            return localVarFp.resourceByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} testbedAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceByTestbedAreaId(testbedAreaId: number, options?: any): AxiosPromise<Array<IResourceProjectionResource>> {
            return localVarFp.resourceByTestbedAreaId(testbedAreaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceByTestbedId(testbedId: number, options?: any): AxiosPromise<Array<IResourceProjectionResource>> {
            return localVarFp.resourceByTestbedId(testbedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sensorById(id: number, options?: any): AxiosPromise<IResourceProjectionSensor> {
            return localVarFp.sensorById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sensorsOfUxV(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.sensorsOfUxV(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sensorsOfUxVByUxVId(resourceId: number, options?: any): AxiosPromise<IUxVSensorDTOList> {
            return localVarFp.sensorsOfUxVByUxVId(resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uxvById(id: number, options?: any): AxiosPromise<IResourceProjectionUxV> {
            return localVarFp.uxvById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResourceControllerApi - object-oriented interface
 * @export
 * @class ResourceControllerApi
 * @extends {BaseAPI}
 */
export class ResourceControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public allEquipment(options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).allEquipment(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public allHealtStatuses(options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).allHealtStatuses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public allOperatorCategories(options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).allOperatorCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public allOperators(options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).allOperators(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public allResourceTypes(options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).allResourceTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public allResources(options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).allResources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public allSensorTypes(options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).allSensorTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public allSensors(options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).allSensors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public allUxVTypes(options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).allUxVTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public allUxVs(options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).allUxVs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public deleteEquipment(id: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).deleteEquipment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public deleteOperator(id: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).deleteOperator(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public deleteSensor(id: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).deleteSensor(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public deleteUxV(id: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).deleteUxV(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public equipmentById(id: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).equipmentById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public getResourceIdByName(name: string, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).getResourceIdByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IEquipmentCreateRequest} iEquipmentCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public newEquipment(iEquipmentCreateRequest: IEquipmentCreateRequest, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).newEquipment(iEquipmentCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IOperatorCreateRequest} iOperatorCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public newOperator(iOperatorCreateRequest: IOperatorCreateRequest, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).newOperator(iOperatorCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ISensorCreateRequest} iSensorCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public newSensor(iSensorCreateRequest: ISensorCreateRequest, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).newSensor(iSensorCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IUxVCreateRequest} iUxVCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public newUxV(iUxVCreateRequest: IUxVCreateRequest, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).newUxV(iUxVCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public operatorById(id: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).operatorById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {IEquipmentCreateRequest} iEquipmentCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public replaceEquipment(id: number, iEquipmentCreateRequest: IEquipmentCreateRequest, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).replaceEquipment(id, iEquipmentCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {IOperatorCreateRequest} iOperatorCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public replaceOperator(id: number, iOperatorCreateRequest: IOperatorCreateRequest, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).replaceOperator(id, iOperatorCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ISensorCreateRequest} iSensorCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public replaceSensor(id: number, iSensorCreateRequest: ISensorCreateRequest, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).replaceSensor(id, iSensorCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {IUxVCreateRequest} iUxVCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public replaceUxV(id: number, iUxVCreateRequest: IUxVCreateRequest, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).replaceUxV(id, iUxVCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public resourceById(id: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).resourceById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public resourceByManager(options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).resourceByManager(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public resourceByName(name: string, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).resourceByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} testbedAreaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public resourceByTestbedAreaId(testbedAreaId: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).resourceByTestbedAreaId(testbedAreaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} testbedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public resourceByTestbedId(testbedId: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).resourceByTestbedId(testbedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public sensorById(id: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).sensorById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public sensorsOfUxV(id: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).sensorsOfUxV(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public sensorsOfUxVByUxVId(resourceId: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).sensorsOfUxVByUxVId(resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceControllerApi
     */
    public uxvById(id: number, options?: AxiosRequestConfig) {
        return ResourceControllerApiFp(this.configuration).uxvById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestbedAreaControllerApi - axios parameter creator
 * @export
 */
export const TestbedAreaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTestbedAreas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/testbedAreas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTestbedAreasByManager: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/testbedAreasByManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTestbedAreasByTestbedId: async (testbedId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testbedId' is not null or undefined
            assertParamExists('allTestbedAreasByTestbedId', 'testbedId', testbedId)
            const localVarPath = `/testbedAreasByTestbedId/{testbedId}`
                .replace(`{${"testbedId"}}`, encodeURIComponent(String(testbedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestbedArea: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTestbedArea', 'id', id)
            const localVarPath = `/deleteTestbedArea/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndoorByTestbedName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getIndoorByTestbedName', 'name', name)
            const localVarPath = `/testbedAreaIndoorByName/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getLocationByName', 'name', name)
            const localVarPath = `/testbedAreaLocation/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObstaclesByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getObstaclesByName', 'name', name)
            const localVarPath = `/testbedAreaObstacles/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointInsideArea: async (name: string, location: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getPointInsideArea', 'name', name)
            // verify required parameter 'location' is not null or undefined
            assertParamExists('getPointInsideArea', 'location', location)
            const localVarPath = `/isPointInsideTestbedArea/{location}/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"location"}}`, encodeURIComponent(String(location)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointInsideObstacle: async (name: string, location: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getPointInsideObstacle', 'name', name)
            // verify required parameter 'location' is not null or undefined
            assertParamExists('getPointInsideObstacle', 'location', location)
            const localVarPath = `/isPointInsideObstacle/{location}/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"location"}}`, encodeURIComponent(String(location)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolygonByTestbedName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getPolygonByTestbedName', 'name', name)
            const localVarPath = `/testbedAreaPolygonAndid/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedAreaByExperimentId: async (experimentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('getTestbedAreaByExperimentId', 'experimentId', experimentId)
            const localVarPath = `/testbedAreaByExperimentId/{experimentId}`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedAreaById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTestbedAreaById', 'id', id)
            const localVarPath = `/testbedArea/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ITestbedAreaCreateRequest} iTestbedAreaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newTestbedArea: async (iTestbedAreaCreateRequest: ITestbedAreaCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iTestbedAreaCreateRequest' is not null or undefined
            assertParamExists('newTestbedArea', 'iTestbedAreaCreateRequest', iTestbedAreaCreateRequest)
            const localVarPath = `/saveTestbedArea`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iTestbedAreaCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ITestbedAreaCreateRequest} iTestbedAreaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestbedArea: async (id: number, iTestbedAreaCreateRequest: ITestbedAreaCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTestbedArea', 'id', id)
            // verify required parameter 'iTestbedAreaCreateRequest' is not null or undefined
            assertParamExists('updateTestbedArea', 'iTestbedAreaCreateRequest', iTestbedAreaCreateRequest)
            const localVarPath = `/updateTestbedArea/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iTestbedAreaCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestbedAreaControllerApi - functional programming interface
 * @export
 */
export const TestbedAreaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestbedAreaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTestbedAreas(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ITestbedAreaProjectionOptions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTestbedAreas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTestbedAreasByManager(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ITestbedAreaProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTestbedAreasByManager(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTestbedAreasByTestbedId(testbedId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ITestbedAreaProjectionOptions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTestbedAreasByTestbedId(testbedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTestbedArea(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTestbedArea(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndoorByTestbedName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedAreaProjectionOptionsTestbedAreaIsIndoor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndoorByTestbedName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedAreaProjectionOptionsTestbedAreaLocation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObstaclesByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedAreaProjectionOptionsTestbedAreaObstacles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObstaclesByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPointInsideArea(name: string, location: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPointInsideArea(name, location, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPointInsideObstacle(name: string, location: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPointInsideObstacle(name, location, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPolygonByTestbedName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPolygonByTestbedName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestbedAreaByExperimentId(experimentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedAreaProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestbedAreaByExperimentId(experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestbedAreaById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedAreaProjectionOptions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestbedAreaById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ITestbedAreaCreateRequest} iTestbedAreaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newTestbedArea(iTestbedAreaCreateRequest: ITestbedAreaCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newTestbedArea(iTestbedAreaCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ITestbedAreaCreateRequest} iTestbedAreaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTestbedArea(id: number, iTestbedAreaCreateRequest: ITestbedAreaCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTestbedArea(id, iTestbedAreaCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestbedAreaControllerApi - factory interface
 * @export
 */
export const TestbedAreaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestbedAreaControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTestbedAreas(options?: any): AxiosPromise<Array<ITestbedAreaProjectionOptions>> {
            return localVarFp.allTestbedAreas(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTestbedAreasByManager(options?: any): AxiosPromise<Array<ITestbedAreaProjection>> {
            return localVarFp.allTestbedAreasByManager(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTestbedAreasByTestbedId(testbedId: number, options?: any): AxiosPromise<Array<ITestbedAreaProjectionOptions>> {
            return localVarFp.allTestbedAreasByTestbedId(testbedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestbedArea(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTestbedArea(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndoorByTestbedName(name: string, options?: any): AxiosPromise<ITestbedAreaProjectionOptionsTestbedAreaIsIndoor> {
            return localVarFp.getIndoorByTestbedName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationByName(name: string, options?: any): AxiosPromise<ITestbedAreaProjectionOptionsTestbedAreaLocation> {
            return localVarFp.getLocationByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObstaclesByName(name: string, options?: any): AxiosPromise<ITestbedAreaProjectionOptionsTestbedAreaObstacles> {
            return localVarFp.getObstaclesByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointInsideArea(name: string, location: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.getPointInsideArea(name, location, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointInsideObstacle(name: string, location: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.getPointInsideObstacle(name, location, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolygonByTestbedName(name: string, options?: any): AxiosPromise<ITestbedAreaProjectionOptionsTestbedAreaPolygonAndId> {
            return localVarFp.getPolygonByTestbedName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedAreaByExperimentId(experimentId: number, options?: any): AxiosPromise<ITestbedAreaProjection> {
            return localVarFp.getTestbedAreaByExperimentId(experimentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedAreaById(id: number, options?: any): AxiosPromise<ITestbedAreaProjectionOptions> {
            return localVarFp.getTestbedAreaById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ITestbedAreaCreateRequest} iTestbedAreaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newTestbedArea(iTestbedAreaCreateRequest: ITestbedAreaCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.newTestbedArea(iTestbedAreaCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ITestbedAreaCreateRequest} iTestbedAreaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestbedArea(id: number, iTestbedAreaCreateRequest: ITestbedAreaCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.updateTestbedArea(id, iTestbedAreaCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestbedAreaControllerApi - object-oriented interface
 * @export
 * @class TestbedAreaControllerApi
 * @extends {BaseAPI}
 */
export class TestbedAreaControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public allTestbedAreas(options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).allTestbedAreas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public allTestbedAreasByManager(options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).allTestbedAreasByManager(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} testbedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public allTestbedAreasByTestbedId(testbedId: number, options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).allTestbedAreasByTestbedId(testbedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public deleteTestbedArea(id: number, options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).deleteTestbedArea(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public getIndoorByTestbedName(name: string, options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).getIndoorByTestbedName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public getLocationByName(name: string, options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).getLocationByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public getObstaclesByName(name: string, options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).getObstaclesByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} location 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public getPointInsideArea(name: string, location: string, options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).getPointInsideArea(name, location, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} location 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public getPointInsideObstacle(name: string, location: string, options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).getPointInsideObstacle(name, location, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public getPolygonByTestbedName(name: string, options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).getPolygonByTestbedName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} experimentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public getTestbedAreaByExperimentId(experimentId: number, options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).getTestbedAreaByExperimentId(experimentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public getTestbedAreaById(id: number, options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).getTestbedAreaById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ITestbedAreaCreateRequest} iTestbedAreaCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public newTestbedArea(iTestbedAreaCreateRequest: ITestbedAreaCreateRequest, options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).newTestbedArea(iTestbedAreaCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ITestbedAreaCreateRequest} iTestbedAreaCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedAreaControllerApi
     */
    public updateTestbedArea(id: number, iTestbedAreaCreateRequest: ITestbedAreaCreateRequest, options?: AxiosRequestConfig) {
        return TestbedAreaControllerApiFp(this.configuration).updateTestbedArea(id, iTestbedAreaCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestbedControllerApi - axios parameter creator
 * @export
 */
export const TestbedControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTestbeds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/testbeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTestbedsByManager: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/testbedsByManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestbed: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTestbed', 'id', id)
            const localVarPath = `/deleteTestbed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedByExperimentId: async (experimentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('getTestbedByExperimentId', 'experimentId', experimentId)
            const localVarPath = `/testbedByExperimentId/{experimentId}`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedById: async (testbedId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testbedId' is not null or undefined
            assertParamExists('getTestbedById', 'testbedId', testbedId)
            const localVarPath = `/testbed/{testbedId}`
                .replace(`{${"testbedId"}}`, encodeURIComponent(String(testbedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getTestbedByName', 'name', name)
            const localVarPath = `/testbedByName/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} operationStartTimeString 
         * @param {string} operationEndTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedByTime: async (operationStartTimeString: string, operationEndTimeString: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operationStartTimeString' is not null or undefined
            assertParamExists('getTestbedByTime', 'operationStartTimeString', operationStartTimeString)
            // verify required parameter 'operationEndTimeString' is not null or undefined
            assertParamExists('getTestbedByTime', 'operationEndTimeString', operationEndTimeString)
            const localVarPath = `/testbed/{operationStartTimeString}/{operationEndTimeString}`
                .replace(`{${"operationStartTimeString"}}`, encodeURIComponent(String(operationStartTimeString)))
                .replace(`{${"operationEndTimeString"}}`, encodeURIComponent(String(operationEndTimeString)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} operationStartTimeString 
         * @param {string} operationEndTimeString 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedByTimeAndId: async (operationStartTimeString: string, operationEndTimeString: string, testbedId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operationStartTimeString' is not null or undefined
            assertParamExists('getTestbedByTimeAndId', 'operationStartTimeString', operationStartTimeString)
            // verify required parameter 'operationEndTimeString' is not null or undefined
            assertParamExists('getTestbedByTimeAndId', 'operationEndTimeString', operationEndTimeString)
            // verify required parameter 'testbedId' is not null or undefined
            assertParamExists('getTestbedByTimeAndId', 'testbedId', testbedId)
            const localVarPath = `/testbed/{operationStartTimeString}/{operationEndTimeString}/{testbedId}`
                .replace(`{${"operationStartTimeString"}}`, encodeURIComponent(String(operationStartTimeString)))
                .replace(`{${"operationEndTimeString"}}`, encodeURIComponent(String(operationEndTimeString)))
                .replace(`{${"testbedId"}}`, encodeURIComponent(String(testbedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedDTOById: async (testbedId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testbedId' is not null or undefined
            assertParamExists('getTestbedDTOById', 'testbedId', testbedId)
            const localVarPath = `/testbedDTO/{testbedId}`
                .replace(`{${"testbedId"}}`, encodeURIComponent(String(testbedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedLocationByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getTestbedLocationByName', 'name', name)
            const localVarPath = `/testbed/location/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ITestbedCreateRequest} iTestbedCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newTestbed: async (iTestbedCreateRequest: ITestbedCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iTestbedCreateRequest' is not null or undefined
            assertParamExists('newTestbed', 'iTestbedCreateRequest', iTestbedCreateRequest)
            const localVarPath = `/saveTestbed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iTestbedCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ITestbedCreateRequest} iTestbedCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceTestbed: async (id: number, iTestbedCreateRequest: ITestbedCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceTestbed', 'id', id)
            // verify required parameter 'iTestbedCreateRequest' is not null or undefined
            assertParamExists('replaceTestbed', 'iTestbedCreateRequest', iTestbedCreateRequest)
            const localVarPath = `/updateTestbed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iTestbedCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestbedControllerApi - functional programming interface
 * @export
 */
export const TestbedControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestbedControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTestbeds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ITestbedProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTestbeds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTestbedsByManager(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ITestbedProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTestbedsByManager(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTestbed(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTestbed(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestbedByExperimentId(experimentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestbedByExperimentId(experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestbedById(testbedId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestbedById(testbedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestbedByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestbedByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} operationStartTimeString 
         * @param {string} operationEndTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestbedByTime(operationStartTimeString: string, operationEndTimeString: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestbedByTime(operationStartTimeString, operationEndTimeString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} operationStartTimeString 
         * @param {string} operationEndTimeString 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestbedByTimeAndId(operationStartTimeString: string, operationEndTimeString: string, testbedId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestbedByTimeAndId(operationStartTimeString, operationEndTimeString, testbedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestbedDTOById(testbedId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestbedDTOById(testbedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestbedLocationByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbedProjectionTestbedLocation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestbedLocationByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ITestbedCreateRequest} iTestbedCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newTestbed(iTestbedCreateRequest: ITestbedCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITestbed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newTestbed(iTestbedCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ITestbedCreateRequest} iTestbedCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceTestbed(id: number, iTestbedCreateRequest: ITestbedCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceTestbed(id, iTestbedCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestbedControllerApi - factory interface
 * @export
 */
export const TestbedControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestbedControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTestbeds(options?: any): AxiosPromise<Array<ITestbedProjection>> {
            return localVarFp.allTestbeds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTestbedsByManager(options?: any): AxiosPromise<Array<ITestbedProjection>> {
            return localVarFp.allTestbedsByManager(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestbed(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTestbed(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedByExperimentId(experimentId: number, options?: any): AxiosPromise<ITestbedProjection> {
            return localVarFp.getTestbedByExperimentId(experimentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedById(testbedId: number, options?: any): AxiosPromise<ITestbedProjection> {
            return localVarFp.getTestbedById(testbedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedByName(name: string, options?: any): AxiosPromise<ITestbedProjection> {
            return localVarFp.getTestbedByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} operationStartTimeString 
         * @param {string} operationEndTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedByTime(operationStartTimeString: string, operationEndTimeString: string, options?: any): AxiosPromise<ITestbedProjection> {
            return localVarFp.getTestbedByTime(operationStartTimeString, operationEndTimeString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} operationStartTimeString 
         * @param {string} operationEndTimeString 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedByTimeAndId(operationStartTimeString: string, operationEndTimeString: string, testbedId: number, options?: any): AxiosPromise<ITestbedProjection> {
            return localVarFp.getTestbedByTimeAndId(operationStartTimeString, operationEndTimeString, testbedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} testbedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedDTOById(testbedId: number, options?: any): AxiosPromise<ITestbedDTO> {
            return localVarFp.getTestbedDTOById(testbedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestbedLocationByName(name: string, options?: any): AxiosPromise<ITestbedProjectionTestbedLocation> {
            return localVarFp.getTestbedLocationByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ITestbedCreateRequest} iTestbedCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newTestbed(iTestbedCreateRequest: ITestbedCreateRequest, options?: any): AxiosPromise<ITestbed> {
            return localVarFp.newTestbed(iTestbedCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ITestbedCreateRequest} iTestbedCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceTestbed(id: number, iTestbedCreateRequest: ITestbedCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.replaceTestbed(id, iTestbedCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestbedControllerApi - object-oriented interface
 * @export
 * @class TestbedControllerApi
 * @extends {BaseAPI}
 */
export class TestbedControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedControllerApi
     */
    public allTestbeds(options?: AxiosRequestConfig) {
        return TestbedControllerApiFp(this.configuration).allTestbeds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedControllerApi
     */
    public allTestbedsByManager(options?: AxiosRequestConfig) {
        return TestbedControllerApiFp(this.configuration).allTestbedsByManager(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedControllerApi
     */
    public deleteTestbed(id: number, options?: AxiosRequestConfig) {
        return TestbedControllerApiFp(this.configuration).deleteTestbed(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} experimentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedControllerApi
     */
    public getTestbedByExperimentId(experimentId: number, options?: AxiosRequestConfig) {
        return TestbedControllerApiFp(this.configuration).getTestbedByExperimentId(experimentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} testbedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedControllerApi
     */
    public getTestbedById(testbedId: number, options?: AxiosRequestConfig) {
        return TestbedControllerApiFp(this.configuration).getTestbedById(testbedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedControllerApi
     */
    public getTestbedByName(name: string, options?: AxiosRequestConfig) {
        return TestbedControllerApiFp(this.configuration).getTestbedByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} operationStartTimeString 
     * @param {string} operationEndTimeString 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedControllerApi
     */
    public getTestbedByTime(operationStartTimeString: string, operationEndTimeString: string, options?: AxiosRequestConfig) {
        return TestbedControllerApiFp(this.configuration).getTestbedByTime(operationStartTimeString, operationEndTimeString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} operationStartTimeString 
     * @param {string} operationEndTimeString 
     * @param {number} testbedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedControllerApi
     */
    public getTestbedByTimeAndId(operationStartTimeString: string, operationEndTimeString: string, testbedId: number, options?: AxiosRequestConfig) {
        return TestbedControllerApiFp(this.configuration).getTestbedByTimeAndId(operationStartTimeString, operationEndTimeString, testbedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} testbedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedControllerApi
     */
    public getTestbedDTOById(testbedId: number, options?: AxiosRequestConfig) {
        return TestbedControllerApiFp(this.configuration).getTestbedDTOById(testbedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedControllerApi
     */
    public getTestbedLocationByName(name: string, options?: AxiosRequestConfig) {
        return TestbedControllerApiFp(this.configuration).getTestbedLocationByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ITestbedCreateRequest} iTestbedCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedControllerApi
     */
    public newTestbed(iTestbedCreateRequest: ITestbedCreateRequest, options?: AxiosRequestConfig) {
        return TestbedControllerApiFp(this.configuration).newTestbed(iTestbedCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ITestbedCreateRequest} iTestbedCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestbedControllerApi
     */
    public replaceTestbed(id: number, iTestbedCreateRequest: ITestbedCreateRequest, options?: AxiosRequestConfig) {
        return TestbedControllerApiFp(this.configuration).replaceTestbed(id, iTestbedCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/userRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUsernames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usernames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByUsername: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getByUsername', 'username', username)
            const localVarPath = `/userByUsername/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        one: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('one', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        read: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('read', 'userId', userId)
            const localVarPath = `/userTestbedsAssociation/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUserRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IUserRoleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUserRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUsernames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IUserProjectionUsername>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUsernames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IUserProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByUsername(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByUsername(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async one(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserProjection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.one(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async read(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IUserAssociationView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.read(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserRoles(options?: any): AxiosPromise<Array<IUserRoleResponse>> {
            return localVarFp.allUserRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUsernames(options?: any): AxiosPromise<Array<IUserProjectionUsername>> {
            return localVarFp.allUsernames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUsers(options?: any): AxiosPromise<Array<IUserProjection>> {
            return localVarFp.allUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByUsername(username: string, options?: any): AxiosPromise<IUserProjection> {
            return localVarFp.getByUsername(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        one(id: number, options?: any): AxiosPromise<IUserProjection> {
            return localVarFp.one(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        read(userId: number, options?: any): AxiosPromise<Array<IUserAssociationView>> {
            return localVarFp.read(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public allUserRoles(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).allUserRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public allUsernames(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).allUsernames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public allUsers(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).allUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteUser(id: number, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getByUsername(username: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getByUsername(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public one(id: number, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).one(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public read(userId: number, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).read(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VrEnvironmentControllerApi - axios parameter creator
 * @export
 */
export const VrEnvironmentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete2: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete2', 'id', id)
            const localVarPath = `/vrenvironment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vrenvironment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById2: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById2', 'id', id)
            const localVarPath = `/vrenvironment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IVREnvironment} iVREnvironment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save2: async (iVREnvironment: IVREnvironment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iVREnvironment' is not null or undefined
            assertParamExists('save2', 'iVREnvironment', iVREnvironment)
            const localVarPath = `/vrenvironment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iVREnvironment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IVREnvironment} iVREnvironment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update2: async (id: number, iVREnvironment: IVREnvironment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update2', 'id', id)
            // verify required parameter 'iVREnvironment' is not null or undefined
            assertParamExists('update2', 'iVREnvironment', iVREnvironment)
            const localVarPath = `/vrenvironment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iVREnvironment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VrEnvironmentControllerApi - functional programming interface
 * @export
 */
export const VrEnvironmentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VrEnvironmentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete2(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IVREnvironment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById2(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IVREnvironment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IVREnvironment} iVREnvironment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save2(iVREnvironment: IVREnvironment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IVREnvironment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save2(iVREnvironment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IVREnvironment} iVREnvironment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update2(id: number, iVREnvironment: IVREnvironment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IVREnvironment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update2(id, iVREnvironment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VrEnvironmentControllerApi - factory interface
 * @export
 */
export const VrEnvironmentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VrEnvironmentControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete2(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll2(options?: any): AxiosPromise<Array<IVREnvironment>> {
            return localVarFp.getAll2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById2(id: number, options?: any): AxiosPromise<IVREnvironment> {
            return localVarFp.getById2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IVREnvironment} iVREnvironment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save2(iVREnvironment: IVREnvironment, options?: any): AxiosPromise<IVREnvironment> {
            return localVarFp.save2(iVREnvironment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {IVREnvironment} iVREnvironment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update2(id: number, iVREnvironment: IVREnvironment, options?: any): AxiosPromise<IVREnvironment> {
            return localVarFp.update2(id, iVREnvironment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VrEnvironmentControllerApi - object-oriented interface
 * @export
 * @class VrEnvironmentControllerApi
 * @extends {BaseAPI}
 */
export class VrEnvironmentControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrEnvironmentControllerApi
     */
    public delete2(id: number, options?: AxiosRequestConfig) {
        return VrEnvironmentControllerApiFp(this.configuration).delete2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrEnvironmentControllerApi
     */
    public getAll2(options?: AxiosRequestConfig) {
        return VrEnvironmentControllerApiFp(this.configuration).getAll2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrEnvironmentControllerApi
     */
    public getById2(id: number, options?: AxiosRequestConfig) {
        return VrEnvironmentControllerApiFp(this.configuration).getById2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IVREnvironment} iVREnvironment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrEnvironmentControllerApi
     */
    public save2(iVREnvironment: IVREnvironment, options?: AxiosRequestConfig) {
        return VrEnvironmentControllerApiFp(this.configuration).save2(iVREnvironment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {IVREnvironment} iVREnvironment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrEnvironmentControllerApi
     */
    public update2(id: number, iVREnvironment: IVREnvironment, options?: AxiosRequestConfig) {
        return VrEnvironmentControllerApiFp(this.configuration).update2(id, iVREnvironment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VrTrainingControllerApi - axios parameter creator
 * @export
 */
export const VrTrainingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/vrtraining/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vrtraining`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/vrtraining/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IVRTraining} iVRTraining 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: async (iVRTraining: IVRTraining, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iVRTraining' is not null or undefined
            assertParamExists('save', 'iVRTraining', iVRTraining)
            const localVarPath = `/vrtraining`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iVRTraining, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IVRTraining} iVRTraining 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, iVRTraining: IVRTraining, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'iVRTraining' is not null or undefined
            assertParamExists('update', 'iVRTraining', iVRTraining)
            const localVarPath = `/vrtraining/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iVRTraining, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VrTrainingControllerApi - functional programming interface
 * @export
 */
export const VrTrainingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VrTrainingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IVRTraining>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IVRTraining>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IVRTraining} iVRTraining 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save(iVRTraining: IVRTraining, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IVRTraining>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save(iVRTraining, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IVRTraining} iVRTraining 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, iVRTraining: IVRTraining, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IVRTraining>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, iVRTraining, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VrTrainingControllerApi - factory interface
 * @export
 */
export const VrTrainingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VrTrainingControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<Array<IVRTraining>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: number, options?: any): AxiosPromise<IVRTraining> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IVRTraining} iVRTraining 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(iVRTraining: IVRTraining, options?: any): AxiosPromise<IVRTraining> {
            return localVarFp.save(iVRTraining, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {IVRTraining} iVRTraining 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, iVRTraining: IVRTraining, options?: any): AxiosPromise<IVRTraining> {
            return localVarFp.update(id, iVRTraining, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VrTrainingControllerApi - object-oriented interface
 * @export
 * @class VrTrainingControllerApi
 * @extends {BaseAPI}
 */
export class VrTrainingControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrTrainingControllerApi
     */
    public _delete(id: number, options?: AxiosRequestConfig) {
        return VrTrainingControllerApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrTrainingControllerApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return VrTrainingControllerApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrTrainingControllerApi
     */
    public getById(id: number, options?: AxiosRequestConfig) {
        return VrTrainingControllerApiFp(this.configuration).getById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IVRTraining} iVRTraining 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrTrainingControllerApi
     */
    public save(iVRTraining: IVRTraining, options?: AxiosRequestConfig) {
        return VrTrainingControllerApiFp(this.configuration).save(iVRTraining, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {IVRTraining} iVRTraining 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrTrainingControllerApi
     */
    public update(id: number, iVRTraining: IVRTraining, options?: AxiosRequestConfig) {
        return VrTrainingControllerApiFp(this.configuration).update(id, iVRTraining, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VrTrainingSessionControllerApi - axios parameter creator
 * @export
 */
export const VrTrainingSessionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete1', 'id', id)
            const localVarPath = `/vrtraining-session/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vrtraining-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById1', 'id', id)
            const localVarPath = `/vrtraining-session/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IVRTrainingSession} iVRTrainingSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save1: async (iVRTrainingSession: IVRTrainingSession, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iVRTrainingSession' is not null or undefined
            assertParamExists('save1', 'iVRTrainingSession', iVRTrainingSession)
            const localVarPath = `/vrtraining-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iVRTrainingSession, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IVRTrainingSession} iVRTrainingSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1: async (id: number, iVRTrainingSession: IVRTrainingSession, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update1', 'id', id)
            // verify required parameter 'iVRTrainingSession' is not null or undefined
            assertParamExists('update1', 'iVRTrainingSession', iVRTrainingSession)
            const localVarPath = `/vrtraining-session/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iVRTrainingSession, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VrTrainingSessionControllerApi - functional programming interface
 * @export
 */
export const VrTrainingSessionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VrTrainingSessionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IVRTrainingSession>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IVRTrainingSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IVRTrainingSession} iVRTrainingSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save1(iVRTrainingSession: IVRTrainingSession, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IVRTrainingSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save1(iVRTrainingSession, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IVRTrainingSession} iVRTrainingSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update1(id: number, iVRTrainingSession: IVRTrainingSession, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IVRTrainingSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update1(id, iVRTrainingSession, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VrTrainingSessionControllerApi - factory interface
 * @export
 */
export const VrTrainingSessionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VrTrainingSessionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll1(options?: any): AxiosPromise<Array<IVRTrainingSession>> {
            return localVarFp.getAll1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById1(id: number, options?: any): AxiosPromise<IVRTrainingSession> {
            return localVarFp.getById1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IVRTrainingSession} iVRTrainingSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save1(iVRTrainingSession: IVRTrainingSession, options?: any): AxiosPromise<IVRTrainingSession> {
            return localVarFp.save1(iVRTrainingSession, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {IVRTrainingSession} iVRTrainingSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(id: number, iVRTrainingSession: IVRTrainingSession, options?: any): AxiosPromise<IVRTrainingSession> {
            return localVarFp.update1(id, iVRTrainingSession, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VrTrainingSessionControllerApi - object-oriented interface
 * @export
 * @class VrTrainingSessionControllerApi
 * @extends {BaseAPI}
 */
export class VrTrainingSessionControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrTrainingSessionControllerApi
     */
    public delete1(id: number, options?: AxiosRequestConfig) {
        return VrTrainingSessionControllerApiFp(this.configuration).delete1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrTrainingSessionControllerApi
     */
    public getAll1(options?: AxiosRequestConfig) {
        return VrTrainingSessionControllerApiFp(this.configuration).getAll1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrTrainingSessionControllerApi
     */
    public getById1(id: number, options?: AxiosRequestConfig) {
        return VrTrainingSessionControllerApiFp(this.configuration).getById1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IVRTrainingSession} iVRTrainingSession 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrTrainingSessionControllerApi
     */
    public save1(iVRTrainingSession: IVRTrainingSession, options?: AxiosRequestConfig) {
        return VrTrainingSessionControllerApiFp(this.configuration).save1(iVRTrainingSession, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {IVRTrainingSession} iVRTrainingSession 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VrTrainingSessionControllerApi
     */
    public update1(id: number, iVRTrainingSession: IVRTrainingSession, options?: AxiosRequestConfig) {
        return VrTrainingSessionControllerApiFp(this.configuration).update1(id, iVRTrainingSession, options).then((request) => request(this.axios, this.basePath));
    }
}


