import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import { Theme } from '_theme'

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Typography variant="body2" color="textSecondary" align="left">
        {'Find me on: '}
        <Link color="primary" href="https://github.com/bcand/including-testbed-manager.git">
          INCLUDING Testbed Manager
        </Link>
      </Typography>
    </footer>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    background: '#fff',
    padding: '0.5rem 1rem',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    justifyContent: 'space-between',
  },
}))

export default Footer
