import React, {useState} from "react";
import PageContainer from "../_common/PageContainer";
import {Checkbox, TextField, Select} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {selectTestbedsList} from "../_redux/testbeds/testbed.selectors";
import DataTable from "react-data-table-component";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {useFormik} from "formik";
import Box from '@mui/material/Box'
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  ITestbedAreaCreateRequest,
  ITestbedAreaProjection,
} from "../_api/openAPI";
import {Col, Form, Row} from "react-bootstrap";
import {selectTestbedAreasList, selectTestbedAreasLoading} from "../_redux/testbed-areas/testbed-areas.selectors";
import {testbedAreasCreateStart, testbedAreasDeleteStart} from "../_redux/testbed-areas/testbed-areas.actions";

const columns = [
  {
    name: 'Testbed Area Id',
    selector: (row: ITestbedAreaProjection) => row.testbedAreaId,
    sortable: true
  },
  {
    name: 'Name',
    selector: (row: ITestbedAreaProjection) => String(row.name),
    sortable: true

  },
  {
    name: 'Testbed',
    selector: (row: ITestbedAreaProjection) => row.testbed,
    sortable: true
  },
  {
    name: 'Indoor',
    selector: (row: ITestbedAreaProjection) => String(row.indoor),
    sortable: true
  },
  {
    name: 'Description',
    selector: (row: ITestbedAreaProjection) => row.description,
    sortable: true
  },
];

const INITIAL_VALUES: ITestbedAreaCreateRequest = {
  name: '',
  description: '',
  area: '',
  areaValidation: '',
  indoor: false,
  mapImageName: '',
  maxAltitude: 0,
  obstacles: '',
  srid: 0,
  location: '',
  testbedId: undefined
}


const TestbedAreaView: React.FC<{}> = () => {
  const [toggleForm, setToggleForm] = useState(false);
  const testbedAreasList = useSelector(selectTestbedAreasList);
  const loading = useSelector(selectTestbedAreasLoading);
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (values, formikHelpers) =>{
      dispatch(testbedAreasCreateStart(values));
      setToggleForm(false);
    }
  })

  const testbedList = useSelector(selectTestbedsList);

  const createRow = () => {
    setToggleForm(true);
    form.setValues(INITIAL_VALUES);
    form.setSubmitting(false);
  };

  const deleteRow = (id: number) => {
    dispatch(testbedAreasDeleteStart(id));
    setToggleForm(false);

  };

  const cancelAll = () => {
    setToggleForm(false);
  };

  const handleOnRowClicked = (row: ITestbedAreaProjection) => {
    setToggleForm(true);
    form.setValues(row as ITestbedAreaCreateRequest, true);
    form.setSubmitting(false);
    console.log(row)
  };

  return (
    (loading) ? <div>Loading....</div> :
      <PageContainer>
        <section>
          <Row>
            <Col sm={12}>
              <DataTable
                title="Testbeds"
                columns={columns}
                data={testbedAreasList}
                dense
                pagination
                sortIcon={<ArrowDownward/>}
                highlightOnHover={true}
                onRowClicked={handleOnRowClicked}/>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-row align-items-end justify-content-end">
              <Button
                color={"primary"}
                startIcon={<AddCircleIcon/>}
                onClick={createRow}
                size="large"
              />
            </Col>
          </Row>
        </section>
        {
          toggleForm &&
          <section className="my-4">
            <Row className="mb-3">
              <Col className="d-flex flex-row justify-content-between align-items-center">
                <Box>
                  {form.values.testbedAreaId ? `Testbed ID: ${form.values.testbedAreaId}` : `Create new Testbed Area`}
                </Box>
                <Box className="d-flex flex-row">
                  {
                    form.values.testbedAreaId &&
                    <Button style={{color: "red"}} className="me-2" variant={"outlined"} startIcon={<DeleteIcon/>}
                            onClick={()=>deleteRow(form.values.testbedAreaId as number)}>DELETE</Button>
                  }
                  <Button color={"secondary"} variant={"outlined"} startIcon={<CancelIcon/>}
                          onClick={cancelAll}>CANCEL</Button>
                </Box>
              </Col>
            </Row>
            <Row>
              <Form onSubmit={form.submitForm}>
                <Box>
                  <Row>
                    <Col sm={4}>
                      <TextField
                          className = "w-100"
                        label="Name"
                        id="name"
                        name="name"
                        placeholder="Name"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.name}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        className = "w-100"
                        label="Description"
                        id="description"
                        name="description"
                        placeholder="Description"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.description}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        className = "w-100"
                        label="Area"
                        id="area"
                        name="area"
                        placeholder="Area"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.area}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        className = "w-100"
                        label="Validation Area"
                        id="areaValidation"
                        name="areaValidation"
                        placeholder="Validation Area"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.areaValidation}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        className = "w-100"
                        label="Location"
                        id="location"
                        name="location"
                        placeholder="Location"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.location}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        className = "w-100"
                        label="Map Image Name"
                        id="mapImageName"
                        name="mapImageName"
                        placeholder="Map Image Name"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"text"}
                        value={form.values.mapImageName}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        className = "w-100"
                        label="Max Altitude"
                        id="maxAltitude"
                        name="maxAltitude"
                        placeholder="Max Altitude"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"number"}
                        value={form.values.maxAltitude}
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        className = "w-100"
                        label="SRID"
                        id="srid"
                        name="srid"
                        placeholder="SRID"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        type={"number"}
                        value={form.values.srid}
                      />
                    </Col>
                    <Col sm={4}>
                      <Select
                        className = "my-3 w-100"
                        label="Testbed ID"
                        id="testbedId"
                        name="testbedId"
                        placeholder="Testbed ID"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.testbedId}
                      >
                        {testbedList.map(i =>(
                          <option value={i.testbedId}> {i.name} </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  {/*<Row>*/}
                  {/*  <Col sm={12}>*/}
                  {/*    <TextareaAutosize*/}
                  {/*      className = "w-100"*/}
                  {/*      id="obstacles"*/}
                  {/*      name="obstacles"*/}
                  {/*      placeholder="Obstacles"*/}
                  {/*      onChange={form.handleChange}*/}
                  {/*      onBlur={form.handleBlur}*/}
                  {/*      value={form.values.obstacles}*/}
                  {/*    />*/}
                  {/*  </Col>*/}
                  {/*</Row>*/}
                  <Row className="my-3">
                    <Col sm={4}>
                      <Checkbox id="indoor" name="indoor" checked={form.values.indoor} onChange={form.handleChange}/>
                      <label htmlFor="indoor">Indoor</label>
                    </Col>

                  </Row>
                  <Row>
                    <Col className="d-flex flex-row justify-content-end align-items-center">
                      <Button color={"primary"} type="button" variant={"outlined"} disabled={!form.isValid || form.isSubmitting} startIcon={<SaveIcon/>} onClick={form.submitForm} >SUBMIT</Button>
                    </Col>
                  </Row>
                </Box>
              </Form>
            </Row>
          </section>
        }
      </PageContainer>
  )
}





export default TestbedAreaView;
