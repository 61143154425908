import reservationsActionTypes, { IReservationsAction, IReservationsState } from "./reservations.types"

const INITIAL_STATE: IReservationsState = {
    reservationList: [],
    loading: false
}

const reservationsReducer = (state:IReservationsState = INITIAL_STATE, action: IReservationsAction) => {
    switch (action.type) {
        case reservationsActionTypes.FETCH_RESERVATIONS_START:
            return {
                ...state,
                loading: true
            }
        case reservationsActionTypes.FETCH_RESERVATIONS_FINISH:
            return {
                ...state,
                reservationList: action.payload,
                loading: false
        }
        default:
            return {
                ...state
            }
    }
}

export default reservationsReducer;
