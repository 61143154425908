import 'typeface-roboto'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import {ThemeProvider} from "@material-ui/styles";
import {Provider} from "react-redux";
import {persistor, store} from "./_redux/store";
import { PersistGate } from 'redux-persist/integration/react'
import theme from "./_theme";
import {Provider as AuthProvider} from "./core/auth.context";

import './scss/global.scss'
import {KeycloakConfig} from "keycloak-js";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const authConfig: KeycloakConfig = {
    url: "https://idppcomp.di.uoa.gr",
    realm: "including",
    clientId: "testbed-manager-spa",
}

ReactDOM.render(
    <AuthProvider keycloakConf={authConfig}>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <App/>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                  />
                </PersistGate>
            </Provider>
        </ThemeProvider>
    </AuthProvider>
,
  document.getElementById('root'))

