import {all, fork} from "redux-saga/effects"
import {testbedsSagas} from "./testbeds/testbeds.saga";
import {resourcesSagas} from "./resources/resources.saga";
import {testbedAreasSagas} from "./testbed-areas/testbed-areas.saga";
import {reservationsSagas} from "./reservations/reservations.saga";

export default function* rootSaga() {
  yield all([
    // call(usersSagas),
    fork(testbedsSagas),
    fork(testbedAreasSagas),
    fork(resourcesSagas),
    fork(reservationsSagas)
  ])
}
