import {useDispatch} from "react-redux";
import {testbedsFetchStart} from "../_redux/testbeds/testbeds.actions";
import React, {useEffect} from "react";
import TestbedView from "./testbed-view.component"

const TestbedWrapper: React.FC<{}> = ({}) => {
  return(
    <TestbedView/>
  )
}


export default TestbedWrapper
