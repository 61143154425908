import resourcesActionTypes, {IResourcesAction, IResourcesState} from "./resources.types";


const INITIAL_STATE: IResourcesState = {
  resourceList: [],
  loading: false
}

const resourcesReducer = (state: IResourcesState = INITIAL_STATE, action: IResourcesAction) => {
  switch (action.type) {
    case resourcesActionTypes.FETCH_RESOURCES_START :
      return {
        ...state,
        loading: true
      }
    case resourcesActionTypes.FETCH_RESOURCES_FINISH:
      return {
        ...state,
        resourceList: action.payload,
        loading: false
      }
    case resourcesActionTypes.RESOURCE_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return {
        ...state
      }
  }
}

export default resourcesReducer;
