import {call, all, takeLatest, put} from "redux-saga/effects";
import Service from "../../_api/api-service";
import {IResourceLocal, IResourcesAction} from "./resources.types";
import {resourcesFetchFinish, resourcesError} from "./resources.actions";
import {
  IEquipmentCreateRequest, IOperatorCreateRequest,
  IResource,
  IResourceProjectionSensor,
  ISensorCreateRequest,
  IUxVCreateRequest
} from "../../_api/openAPI";
import resourcesActionTypes from "./resources.types";
import _ from "lodash";
import {AxiosResponse} from "axios";


function* fetchResources() {
  try {
    const response:AxiosResponse<IResource[]> = yield Service.resourceControllerApi.resourceByManager("test")
    const resources = response.data as IResourceLocal[]
    yield all(resources.map(function* (item, index) {
      if(item.resourceType === 'Sensor') {
         const r = yield call([Service.resourceControllerApi, Service.resourceControllerApi.sensorById], item.resourceId as number);
         resources[index].meta = r.data.sensor
      }
      if(item.resourceType === 'UxV') {
        const r = yield call([Service.resourceControllerApi, Service.resourceControllerApi.uxvById], item.resourceId as number);
        resources[index].meta = r.data.uxV
      }
      if(item.resourceType === 'Operator') {
        const r = yield call([Service.resourceControllerApi, Service.resourceControllerApi.operatorById], item.resourceId as number);
        // console.log('Operator',r)
        resources[index].meta = r.data.operator
      }
      if(item.resourceType === 'Equipment') {
        const r = yield call([Service.resourceControllerApi, Service.resourceControllerApi.equipmentById], item.resourceId as number);
        resources[index].meta = r.data.equipment
      }
    }))
    yield put<IResourcesAction>(resourcesFetchFinish(resources));
  } catch (e) {
    yield put(resourcesError());
    console.log(e)
  }
}

function* createUpdateSensors({type, payload}: IResourcesAction) {
  try {
    const data: IResourceLocal = payload
    if(data.resourceId) {
      yield Service.resourceControllerApi.replaceSensor(data.resourceId as number, {...data, ...data.meta} as ISensorCreateRequest)
    }
    else{
      yield Service.resourceControllerApi.newSensor({...data, ...data.meta} as ISensorCreateRequest)
    }
    yield fetchResources();

  } catch (e) {
    console.log(e)
  }
}

function* createUpdateUxV({type, payload}: IResourcesAction) {
  try {
    const data: IResourceLocal = payload
    if(data.resourceId) {
      yield Service.resourceControllerApi.replaceUxV(data.resourceId as number, {...data, ...data.meta} as IUxVCreateRequest)
    }
    else{
      yield Service.resourceControllerApi.newUxV({...data, ...data.meta} as IUxVCreateRequest)
    }
    yield fetchResources();

  } catch (e) {
    console.log(e)
  }
}

function* createUpdateEquipment({type, payload}: IResourcesAction) {
  try {
    const data: IResourceLocal = payload
    if(data.resourceId) {
      yield Service.resourceControllerApi.replaceEquipment(data.resourceId as number, {...data, ...data.meta} as IEquipmentCreateRequest)
    }
    else{
      yield Service.resourceControllerApi.newEquipment({...data, ...data.meta} as IEquipmentCreateRequest)
    }
    yield fetchResources();

  } catch (e) {
    console.log(e)
  }
}

function* createUpdateOperator({type, payload}: IResourcesAction) {
  try {
    const data: IResourceLocal = payload
    if(data.resourceId) {
      yield Service.resourceControllerApi.replaceOperator(data.resourceId as number, {...data, ...data.meta} as IOperatorCreateRequest)
    }
    else{
      yield Service.resourceControllerApi.newOperator({...data, ...data.meta} as IOperatorCreateRequest)
    }
    yield fetchResources();

  } catch (e) {
    console.log(e)
  }
}

function* deleteSensor({payload}: IResourcesAction) {
  try {
    if(payload) {
      yield Service.resourceControllerApi.deleteSensor(payload as number);
      yield fetchResources();
    }
  } catch (e) {
    console.log(e)
  }
}

function* deleteUxv({payload}: IResourcesAction) {
  try {
    if(payload) {
      yield Service.resourceControllerApi.deleteUxV(payload as number);
      yield fetchResources();
    }
  } catch (e) {
    console.log(e)
  }
}

function* deleteOperator({payload}: IResourcesAction) {
  try {
    if(payload) {
      yield Service.resourceControllerApi.deleteOperator(payload as number);
      yield fetchResources();
    }
  } catch (e) {
    console.log(e)
  }
}

function* deleteEquipment({payload}: IResourcesAction) {
  try {
    if(payload) {
      yield Service.resourceControllerApi.deleteEquipment(payload as number);
      yield fetchResources();
    }
  } catch (e) {
    console.log(e)
  }
}

function* onSensorsFetchStart() {
  yield takeLatest(resourcesActionTypes.FETCH_RESOURCES_START, fetchResources)
}

function* onSensorCreateStart() {
  yield takeLatest(resourcesActionTypes.CREATE_OR_UPDATE_SENSOR_START, createUpdateSensors)
}

function* onUxVCreateStart() {
  yield takeLatest(resourcesActionTypes.CREATE_OR_UPDATE_UxV_START, createUpdateUxV)
}

function* onOperatorCreateStart() {
  yield takeLatest(resourcesActionTypes.CREATE_OR_UPDATE_OPERATOR_START, createUpdateOperator)
}

function* onEquipmentCreateStart() {
  yield takeLatest(resourcesActionTypes.CREATE_OR_UPDATE_EQUIPMENT_START, createUpdateEquipment)
}

function* onSensorDeleteStart() {
  yield takeLatest(resourcesActionTypes.DELETE_SENSOR_START, deleteSensor)
}

function* onUxVDeleteStart() {
  yield takeLatest(resourcesActionTypes.DELETE_UxV_START, deleteUxv)
}

function* onOperatorDeleteStart() {
  yield takeLatest(resourcesActionTypes.DELETE_OPERATOR_START, deleteOperator)
}

function* onEquipmentDeleteStart() {
  yield takeLatest(resourcesActionTypes.DELETE_EQUIPMENT_START, deleteEquipment)
}

export function* resourcesSagas() {
  yield all([
    call(onOperatorCreateStart),
    call(onUxVCreateStart),
    call(onEquipmentCreateStart),
    call(onSensorsFetchStart),
    call(onSensorCreateStart),
    call(onUxVDeleteStart),
    call(onOperatorDeleteStart),
    call(onEquipmentDeleteStart),
    call(onSensorDeleteStart)
  ])
}

