import React from 'react'
import {
  HashRouter,
  BrowserRouter,
  Route,
  RouteProps,
  Redirect,
  Switch,
} from 'react-router-dom' //

import config from './_config'
import DashboardLayout from '_layouts/DashboardLayout'

// Import core modules
import Auth from './Auth/Auth'
import NotFound from './Misc/NotFound'
import Sensors from "./Sensors/Sensors";
import TestbedWrapper from "./Testbed/testbeds-wrapper.component";
import Reservations from "./Reservations/Reservations";
import TestbedAreaWrapper from "./TestbedArea/testbed-area-wrapper.component";

const LoggedInRouter = () => {

  return (
    <Switch>
      <Route exact path="/" component={()=><Redirect to={'/testbeds'}/> } />
      <RouteWithLayout path={`/testbeds`} component={TestbedWrapper} layout={DashboardLayout} />
      {/*<RouteWithLayout path={`/uxv_resources`} component={UxVResources} layout={DashboardLayout} />*/}
      {/*<RouteWithLayout path={`/net_interfaces`} component={NetInterfaces} layout={DashboardLayout} />*/}
      <RouteWithLayout path={`/resources`} component={Sensors} layout={DashboardLayout} />
      <RouteWithLayout path={`/testbed-areas`} component={TestbedAreaWrapper} layout={DashboardLayout} />
      <RouteWithLayout path={`/reservations`} component={Reservations} layout={DashboardLayout} />
      {/*<RouteWithLayout path={`/users`} component={Users} layout={DashboardLayout} />*/}
      {/*<RouteWithLayout path={`/monitoring`} component={Monitoring} layout={DashboardLayout} />*/}
      {/*<RouteWithLayout path={`/services`} component={Services} layout={DashboardLayout} />*/}
      {/*<RouteWithLayout path={`/statistics`} component={Statistics} layout={DashboardLayout} />*/}
      {/*<RouteWithLayout path={`/help`} component={Help} layout={DashboardLayout} />*/}
      <RouteWithLayout component={NotFound} layout={DashboardLayout} />
    </Switch>
  )
}

// // Use different router type depending on configuration
const AppRouterComponent: React.ComponentType<any> =
  config.navigationType === 'history' ? BrowserRouter : HashRouter

const AppRouter: React.FC = () => (
   <AppRouterComponent>
    <RoutePrivate path="/" component={LoggedInRouter} />
    {/*<Switch>*/}
    {/*  <Route path="/auth" component={Auth} />*/}
    {/*  <RoutePrivate path="/" component={LoggedInRouter} />*/}
    {/*</Switch>*/}
   </AppRouterComponent>
)

const RouteWithLayout = ({ component: Component, layout: Layout, ...rest }: any) => (
  <Route
    {...rest}
    render={props => {
      if (Layout) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      } else {
        return <Component {...props} />
      }
    }}
  />
)

// See https://reacttraining.com/react-router/web/example/auth-workflow
const RoutePrivate: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}: RouteProps) => {
  if (!Component) {
    return <Route {...rest} />
  }

  return (
    <Route
      {...rest}
      render={props => true
         ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
            }}
          />
        )
      }
    />
  )
}

export default AppRouter
