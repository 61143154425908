import resourcesActionTypes, {IResourceLocal, IResourcesAction} from "./resources.types";

export const resourcesFetchStart = ():IResourcesAction => ({
  type: resourcesActionTypes.FETCH_RESOURCES_START
})

export const resourcesFetchFinish = (resources: IResourceLocal[]):IResourcesAction => ({
  type: resourcesActionTypes.FETCH_RESOURCES_FINISH,
  payload: resources
})

// Not sure if these are needed
export const sensorsCreateOrUpdateStart = (item: IResourceLocal):IResourcesAction => ({
  type: resourcesActionTypes.CREATE_OR_UPDATE_SENSOR_START,
  payload: item
})

export const uxVsCreateOrUpdateStart = (item: IResourceLocal):IResourcesAction => ({
  type: resourcesActionTypes.CREATE_OR_UPDATE_UxV_START,
  payload: item
})

export const operatorCreateOrUpdateStart = (item: IResourceLocal):IResourcesAction => ({
  type: resourcesActionTypes.CREATE_OR_UPDATE_OPERATOR_START,
  payload: item
})

export const eqipmentCreateOrUpdateStart = (item: IResourceLocal):IResourcesAction => ({
  type: resourcesActionTypes.CREATE_OR_UPDATE_EQUIPMENT_START,
  payload: item
})

export const sensorDeleteStart = (id:number):IResourcesAction => ({
  type: resourcesActionTypes.DELETE_SENSOR_START,
  payload: id
})

export const uxvDeleteStart = (id:number):IResourcesAction => ({
  type: resourcesActionTypes.DELETE_UxV_START,
  payload: id
})

export const operatorDeleteStart = (id:number):IResourcesAction => ({
  type: resourcesActionTypes.DELETE_OPERATOR_START,
  payload: id
})

export const equipmentDeleteStart = (id:number):IResourcesAction => ({
  type: resourcesActionTypes.DELETE_EQUIPMENT_START,
  payload: id
})
export const resourcesError = (): IResourcesAction =>({
  type: resourcesActionTypes.RESOURCE_ERROR
})
