import testbedAreasActionTypes , {ITestbedAreasAction} from "./testbed-areas.types";
import {ITestbedArea, ITestbedAreaCreateRequest, ITestbedAreaProjection} from "../../_api/openAPI";

export const testbedAreasFetchStart = ():ITestbedAreasAction => ({
  type: testbedAreasActionTypes.FETCH_TESTBED_AREAS_START
})

export const testbedAreasFetchFinish = (data: ITestbedAreaProjection[]):ITestbedAreasAction => ({
  type: testbedAreasActionTypes.FETCH_TESTBED_AREAS_FINISH,
  payload: data
})

// Not sure if these are needed
export const testbedAreasCreateStart = (data: ITestbedAreaCreateRequest):ITestbedAreasAction => ({
  type: testbedAreasActionTypes.CREATE_OR_UPDATE_TESTBED_AREA_START,
   payload: data
})

export const testbedAreasDeleteStart = (id:number):ITestbedAreasAction => ({
  type: testbedAreasActionTypes.DELETE_TESTBED_AREA_START,
  payload: id
})

export const testbedsError = ():ITestbedAreasAction => ({
  type: testbedAreasActionTypes.TESTBED_AREA_ERROR
})
