import React, {useEffect, useState} from "react";
import PageContainer from "../_common/PageContainer";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {useSelector, useDispatch} from "react-redux";
import {Field, FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import {Checkbox, TextField, Select, MenuItem} from "@material-ui/core";
import SaveIcon from "@mui/icons-material/Save";
import {
  selectResourcesList,
  selectResourcesLoading,
} from "../_redux/resources/resources.selectors";
import {useAppDispatch} from "../_redux/hooks";
import {Col, Row} from "react-bootstrap";
import {IResourceLocal} from "../_redux/resources/resources.types";
import {
  operatorCreateOrUpdateStart,
  resourcesFetchStart,
  sensorsCreateOrUpdateStart,
  uxVsCreateOrUpdateStart,
  eqipmentCreateOrUpdateStart, sensorDeleteStart, uxvDeleteStart, operatorDeleteStart, equipmentDeleteStart
} from "../_redux/resources/resources.actions";

import {selectTestbedAreasList} from "../_redux/testbed-areas/testbed-areas.selectors";
import {InputLabel} from "@mui/material";

const columns = [
  {
    name: 'Resource ID',
    selector: (row: any) => row.resourceId,
    sortable: true
  },
  {
    name: 'Name',
    selector: (row: any) => row.resourceName,
    sortable: true
  },
  {
    name: 'Type',
    selector: (row: any) => row.resourceType,
    sortable: true
  },
  {
    name: 'Transferable',
    selector: (row: any) => String(row.transferable),
    sortable: true
  },
  {
    name: 'Mobile',
    selector: (row: any) => String(row.mobile),
    sortable: true
  }
];

const INITIAL_VALUES: IResourceLocal = {
  resourceId: 0,
  resourceName: '',
  resourceDescription: '',
  resourceLocation: '',
  partition: 0,
  preferedSrid: 0,
  transferable: false,
  mobile: false,
  reservationItems: undefined,
  healthStatus: undefined,
  resourceStatus: undefined,
  resourceType: 'Sensor',
  testbedAreaId: undefined,
  meta: {}
}

const SensorView: React.FC<{}> = () => {
  const [toggleForm, setToggleForm] = useState(false);
  const resourcesList = useSelector(selectResourcesList)
  const loading = useSelector(selectResourcesLoading);
  const dispatch = useAppDispatch();
  const testbedAres = useSelector(selectTestbedAreasList);

  useEffect(() => {
    dispatch(resourcesFetchStart());
  }, [])

  const form = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (values, formikHelpers) => {
      switch (values.resourceType) {
        case 'Sensor':
          dispatch(sensorsCreateOrUpdateStart(values));
          break;
        case 'UxV':
          dispatch(uxVsCreateOrUpdateStart(values));
          break;
        case 'Operator':
          dispatch(operatorCreateOrUpdateStart(values));
          break;
        case 'Equipment':
          dispatch(eqipmentCreateOrUpdateStart(values));
          break;
      }
      formikHelpers.setSubmitting(false)
      setToggleForm(false);
    }
  })


  const createRow = () => {
    setToggleForm(true);
    form.setValues(INITIAL_VALUES);
    form.setSubmitting(false);
  };

  const deleteRow = (row: IResourceLocal) => {
    if (row.resourceId) {
      switch (row.resourceType) {
        case 'Sensor':
          dispatch(sensorDeleteStart(row.resourceId));
          break;
        case 'UxV':
          dispatch(uxvDeleteStart(row.resourceId));
          break;
        case 'Operator':
          dispatch(operatorDeleteStart(row.resourceId));
          break;
        case 'Equipment':
          dispatch(equipmentDeleteStart(row.resourceId));
          break;
      }
    }
    setToggleForm(false);

  };

  const cancelAll = () => {
    setToggleForm(false);
  };

  const handleOnRowClicked = (row: IResourceLocal) => {
    setToggleForm(true);
    form.setValues(row, true);
    form.setSubmitting(false);
  };

  return (
    (loading) ? <div>Loading....</div> :
      <PageContainer>
        <section>
          <Row>
            <Col sm={12}>
              <DataTable
                title="Resources"
                columns={columns}
                data={resourcesList}
                dense
                pagination
                sortIcon={<ArrowDownward/>}
                highlightOnHover={true}
                onRowClicked={handleOnRowClicked}/>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-row align-items-end justify-content-end">
              <Button
                color={"primary"}
                startIcon={<AddCircleIcon/>}
                onClick={createRow}
                size="large"
              />
            </Col>
          </Row>
        </section>
        {
          toggleForm &&
          <section className="my-4">
            <Row className="mb-3">
              <Col className="d-flex flex-row justify-content-between align-items-center">
                <Box>
                  {form.values.resourceId ? `Resource ID: ${form.values.resourceId}` : `Create new Testbed`}
                </Box>
                <Box className="d-flex flex-row">
                  {
                    form.values.resourceId &&
                    <Button style={{color: "red"}} className="me-2" variant={"outlined"} startIcon={<DeleteIcon/>}
                            onClick={() => deleteRow(form.values)}>DELETE</Button>
                  }
                  <Button color={"secondary"} variant={"outlined"} startIcon={<CancelIcon/>}
                          onClick={cancelAll}>CANCEL</Button>
                </Box>
              </Col>
            </Row>
            <Row>
              <FormikProvider value={form}>
                <div>
                  <Box>
                    <Row>
                      <Col sm={4}>
                        <Field as={TextField} name="resourceName" label="Resource Name (*)" type={"text"}  requared/>
                      </Col>
                      <Col sm={4}>
                        <Field as={TextField} name="resourceDescription" label="Resource Description" type={"text"}/>
                      </Col>
                      <Col sm={4}>
                        <Field as={TextField} name="resourceLocation" label="Resource Location (*)" type={"text"}/>
                      </Col>
                      <Col sm={4}>
                        <Field as={TextField} name="partition" label="Partition" type={"number"}/>
                      </Col>
                      <Col sm={4}>
                        <Field as={TextField} name="preferedSrid" label="Prefered SRID" type={"number"}/>
                      </Col>
                      <Col sm={4}>
                        <Field as={TextField} name="healthStatus" label="Health Status" type={"text"} disabled/>
                      </Col>
                      <Col sm={4}>
                        <Field as={TextField} name="resourceStatus" label="Resource Status" type={"text"}/>
                      </Col>

                      {form.values.resourceId ? <Field name="resourceType" type={"hidden"} /> :
                        <Col>
                          <InputLabel id="resourceType"> Resource Type (*) </InputLabel>
                          <Field name="resourceType" as={Select} id='resourceType' className = "w-100">
                            <MenuItem value="Sensor"> Sensor </MenuItem>
                            <MenuItem value="UxV"> UxV </MenuItem>
                            <MenuItem value="Equipment"> Equipment </MenuItem>
                            <MenuItem value="Operator"> Operator </MenuItem>
                          </Field>
                        </Col>
                        }

                      <Col sm={4}>
                        <InputLabel id="testbedAreaId">Testbed Area (*)</InputLabel>
                        <Field name="testbedAreaId" as={Select} id='testbedAreaId' className = "w-100">
                          {
                            testbedAres.map(i=>(
                              <MenuItem key={`testbedareaoption_${i.testbedAreaId}`} value={i.testbedAreaId}>{i.name}</MenuItem>
                            ))
                          }
                        </Field>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col sm={4}>
                        <Checkbox id="transferable" name="transferable" checked={form.values.transferable} onChange={form.handleChange}/>
                        <label htmlFor="transferable">Transferable</label>
                      </Col>
                      <Col sm={4}>
                        <Checkbox id="mobile" name="mobile" checked={form.values.mobile} onChange={form.handleChange}/>
                        <label htmlFor="mobile">Mobile</label>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      {form.values.resourceType==='Sensor' &&
                        <>
                          <Col sm={4}>
                            <Field as={TextField}  name="meta.canonicalName"  label="Canonical Name" placeholder="Canonical Name" type={"text"}/>
                          </Col>
                          <Col sm={4}>
                            <Field as={TextField}  name="meta.sensorName"  label="Sensor Name (*)" placeholder="Sensor Name" type={"text"}/>
                          </Col>
                          <Col sm={4}>
                            <Field as={TextField}  name="meta.unit"  label="Unit" placeholder="Unit" type={"text"}/>
                          </Col>
                          <Col sm={4}>
                            <Field as={Select}  name="meta.sensorType"  label="Sensor Type (*)" className = "w-50">
                              <option value = "Static"> Static </option>
                              <option value = "Mobile"> Mobile </option>
                            </Field>
                          </Col>
                        </>
                      }

                      {form.values.resourceType==='UxV' &&
                      <>
                        <Field as={Select}  name="meta.uxVType"  label="UxV Type (*)">
                          <option value = "UAV"> UAV </option>
                          <option value = "UGV"> UGV </option>
                        </Field>
                      </>
                      }

                      {form.values.resourceType==='Operator' &&
                      <>
                        <Col sm={4}>
                          <Field as={TextField}  name="meta.operatorName"  label="Operator Name (*)" placeholder="Operator Name" type={"text"}/>
                        </Col>
                        <Col sm={4}>
                          <Field as={Select}  name="meta.operatorCategory"  label="Operator Category (*)" className = "w-50">
                            <option value="Firefighter"> Firefighter </option>
                            <option value="CBRN_Recce"> CBRN Recce </option>
                            <option value="CBRN_Sampling"> CBRN Sampling </option>
                            <option value="CBRN_Marking"> CBRN Marking </option>
                            <option value="CBRN_Decontamination"> CBRN Decontamination </option>
                            <option value="Medical"> Medical </option>
                            <option value="General_Officer"> General Officer </option>
                          </Field>
                        </Col>

                      </>
                      }

                      {form.values.resourceType==='Equipment' &&
                      <>
                        <Col sm={4}>
                          <Field as={TextField}  name="meta.amount"  label="Amount" placeholder="Amount" type={"number"}/>
                        </Col>
                        <Col sm={4}>
                          <Field as={Select}  name="meta.equipmentType"  label="Equipment Type (*)" className = "w-50">
                            <option value = "Backpack"> Backpack </option>
                            <option value = "Heavy Backpack"> Heavy Backpack </option>
                            <option value = "Tent"> Tent </option>
                            <option value = "Suit"> Suit </option>
                            <option value = "Vehicle"> Vehicle </option>
                            <option value = "Shower"> Shower </option>
                            <option value = "Protective Suit"> Protective Suit </option>
                            <option value = "Other"> Other </option>
                          </Field>
                        </Col>

                      </>
                      }
                    </Row>
                    <Row>
                      <Col className="d-flex flex-row justify-content-end align-items-center">
                        <Button color={"primary"} type="button" variant={"outlined"} disabled={!form.isValid || form.isSubmitting} startIcon={<SaveIcon/>} onClick={form.submitForm} >SUBMIT</Button>
                      </Col>
                    </Row>
                  </Box>
                </div>
              </FormikProvider>
            </Row>
          </section>
        }
      </PageContainer>
  )
}


export default SensorView;
