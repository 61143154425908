import {Action} from "redux";
import {
  IEquipmentPropertiesEquipment,
  IOperatorPropertiesOperator,
  IResource,
  ISensorPropertiesSensor,
  IUxVPropertiesUxV
} from "../../_api/openAPI";

export interface IResourcesAction extends Action<resourcesActionTypes> {
  payload?: any
}

export interface IResourceLocal extends IResource {
  resourceType: "Sensor" | "UxV" | "Operator" | "Equipment"
  testbedAreaId?: number
  meta: ISensorPropertiesSensor | IUxVPropertiesUxV | IOperatorPropertiesOperator | IEquipmentPropertiesEquipment
}

export interface IResourcesState {
  resourceList: IResourceLocal[]
  loading: boolean
}

enum resourcesActionTypes {
  FETCH_RESOURCES_START = "FETCH_RESOURCES_START",
  FETCH_RESOURCES_FINISH = "FETCH_RESOURCES_FINISH",
  CREATE_OR_UPDATE_SENSOR_START = "CREATE_OR_UPDATE_SENSOR_START",
  CREATE_OR_UPDATE_SENSOR_FINISH = "CREATE_OR_UPDATE_SENSOR_FINISH",
  CREATE_OR_UPDATE_UxV_START = "CREATE_OR_UPDATE_UxV_START",
  CREATE_OR_UPDATE_UxV_FINISH = "CREATE_OR_UPDATE_UxV_FINISH",
  CREATE_OR_UPDATE_OPERATOR_START = "CREATE_OR_UPDATE_OPERATOR_START",
  CREATE_OR_UPDATE_OPERATOR_FINISH = "CREATE_OR_UPDATE_OPERATOR_FINISH",
  CREATE_OR_UPDATE_EQUIPMENT_START = "CREATE_OR_UPDATE_EQUIPMENT_START",
  CREATE_OR_UPDATE_EQUIPMENT_FINISH = "CREATE_OR_UPDATE_EQUIPMENT_FINISH",
  DELETE_OPERATOR_START = "DELETE_OPERATOR_START",
  DELETE_OPERATOR_FINISH = "DELETE_OPERATOR_FINISH",
  DELETE_EQUIPMENT_START = "DELETE_EQUIPMENT_START",
  DELETE_EQUIPMENT_FINISH = "DELETE_EQUIPMENT_FINISH",
  DELETE_SENSOR_START = "DELETE_SENSOR_START",
  DELETE_SENSOR_FINISH = "DELETE_SENSOR_FINISH",
  DELETE_UxV_START = "DELETE_UxV_START",
  DELETE_UxV_FINISH = "DELETE_UxV_FINISH",
  RESOURCE_ERROR = "RESOURCE_ERROR"
}

export default resourcesActionTypes
