import {createSelector} from "reselect";
import {ITestbedsState} from "./testbeds.types";
import {IRootState} from "../root-reducer";


const selectTestbeds = (state:IRootState) => state.testbeds;


export const selectTestbedsList = createSelector(
  [selectTestbeds],
  (testbed:ITestbedsState)=>testbed.testbedList
);

export const selectTestbedsLoading = createSelector(
  [selectTestbeds],
  (testbed:ITestbedsState)=>testbed.loading
);
