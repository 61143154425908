import {Action} from "redux";
import {ITestbedArea, ITestbedAreaProjection} from "../../_api/openAPI";

export interface ITestbedAreasAction extends Action<testbedAreasActionTypes> {
  payload?: any
}

export interface ITestbedAreasState {
  testbedAreasList: ITestbedAreaProjection[]
  loading: boolean
}

enum testbedAreasActionTypes {
  FETCH_TESTBED_AREAS_START = "FETCH_TESTBED_AREAS_START",
  FETCH_TESTBED_AREAS_FINISH = "FETCH_TESTBED_AREAS_FINISH",
  CREATE_OR_UPDATE_TESTBED_AREA_START = "CREATE_OR_UPDATE_TESTBED_AREA_START",
  CREATE_OR_UPDATE_TESTBED_AREA_FINISH = "CREATE_OR_UPDATE_TESTBED_AREA_FINISH",
  DELETE_TESTBED_AREA_START = "DELETE_TESTBED_AREA_START",
  DELETE_TESTBED_AREA_FINISH = "DELETE_TESTBED_AREA_FINISH",
  TESTBED_AREA_ERROR = "TESTBED_AREA_ERROR"

}

export default testbedAreasActionTypes
