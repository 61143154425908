import {call, all, takeLatest, put, select} from "redux-saga/effects";
import Service from "../../_api/api-service";
import {reservationsError, reservationsFetchFinish} from "./reservations.actions";
import reservationsActionTypes, {IReservationsAction, ReservationStatus} from "./reservations.types";
import {IReservationProjection} from "../../_api/openAPI";
import {AxiosResponse} from "axios";
import {testbedsError} from "../testbed-areas/testbed-areas.actions";


function* fetchReservations() {
  try {
    const response:AxiosResponse<IReservationProjection[]> = yield Service.reservationControllerApi.findReservationsByManager("test");
    const reservations = response.data as IReservationProjection[]
    console.log(reservations)
    yield put<IReservationsAction>(reservationsFetchFinish(reservations));

  } catch (e) {
    yield put(reservationsError())
  }
}

function* updateReservation({payload}: IReservationsAction){
  try {
    const data:{id: number, status: ReservationStatus} = payload
    yield Service.reservationControllerApi.replaceReservation(data.id, data.status)
    yield fetchReservations()
  }
  catch (e){
    console.log(e)
  }
}

function* onReservationsFetchStart(){
    yield takeLatest(reservationsActionTypes.FETCH_RESERVATIONS_START, fetchReservations)
}

function* onReservationStatusUpdateStart(){
  yield takeLatest(reservationsActionTypes.UPDATE_RESERVATION_STATUS_START, updateReservation)
}

export function* reservationsSagas() {
    yield all([
        call(onReservationsFetchStart),
        call(onReservationStatusUpdateStart)
    ])
}

