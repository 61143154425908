import React, {useEffect} from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppRouter from './AppRouter'
import {useDispatch, useSelector} from "react-redux";

import {testbedsFetchStart} from "./_redux/testbeds/testbeds.actions";
import {selectTestbedsList} from "./_redux/testbeds/testbed.selectors";
import {testbedAreasFetchStart} from "./_redux/testbed-areas/testbed-areas.actions";
import {reservationsFetchStart} from "./_redux/reservations/reservations.actions";
import {toast} from "react-toastify";

interface IAppProps {}

const App: React.FC<IAppProps > = () => {
  const dispatch = useDispatch();
  const testbeds = useSelector(selectTestbedsList)
  useEffect(()=>{
    dispatch(testbedsFetchStart())
  },[])


  useEffect(()=> {
    dispatch(testbedAreasFetchStart());
  },[testbeds])


  return (
    <div className="App">
      <CssBaseline />
      <AppRouter />
    </div>
  )
}


export default App

