import {Component, createContext, PropsWithChildren, useContext} from "react";
import Keycloak, {KeycloakConfig} from "keycloak-js";
import {auth} from "../_api/api-service";

interface ContextValues {
  keycloak: Keycloak,
  isAuthenticated?: boolean
  userInfo?: {
    email?: string,
    email_verified?: boolean
    family_name?: string
    given_name?: string
  }
}

interface Props extends PropsWithChildren {
  keycloakConf: KeycloakConfig
}

interface State {
  initialized: boolean
}


const AuthContext = createContext<ContextValues>({} as ContextValues)

class AuthProvider extends Component<Props, State>{
  state:State = {
    initialized: false
  }

  keycloak: Keycloak
  constructor(props: Props) {
    super(props);
    this.keycloak = new Keycloak(props.keycloakConf)
    this.keycloak.init({
      flow:"implicit",
      useNonce:true,
      onLoad:"login-required",
      scope:"profile",
    }).then()
  }


  componentDidMount() {
    this.keycloak.onTokenExpired = ()=>{
      this.keycloak.updateToken(5).then((refreshed)=>{
        console.log("Token refreshed: ", refreshed)
      })
    }


    this.keycloak.onReady = async (authenticated)=>{
      if(authenticated) {
       auth.token = this.keycloak.token || ""
        await this.keycloak.loadUserInfo()
        if(this.keycloak.token) {
          // @ts-ignore

        }
      }
      this.setState({initialized:Boolean(authenticated)})
    }

  }


  render() {
    if(this.state.initialized) {
      return (
          <AuthContext.Provider value={{
            keycloak: this.keycloak,
            isAuthenticated: this.keycloak.authenticated,
            userInfo: this.keycloak.userInfo
          }}>
            {this.props.children}
          </AuthContext.Provider>
      )
    } else {
      return null;
    }
  }
}

const AuthConsumer = AuthContext.Consumer;

export const useAuth = ()=>{
  return useContext(AuthContext);
}

export {AuthProvider as Provider, AuthConsumer as Consumer}

export default AuthContext;
