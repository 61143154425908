import reservationsActionTypes, {IReservationsAction, ReservationStatus} from "./reservations.types"
import {IReservationProjection} from "../../_api/openAPI";

export const reservationsError = ():IReservationsAction => ({
  type: reservationsActionTypes.RESERVATION_ERROR
})

export const reservationsFetchStart = ():IReservationsAction => ({
    type: reservationsActionTypes.FETCH_RESERVATIONS_START
})

export const reservationsFetchFinish = (reservations: IReservationProjection[]):IReservationsAction => ({
    type: reservationsActionTypes.FETCH_RESERVATIONS_FINISH,
    payload: reservations
})

export const updateReservationStatusStart = (id:number, status: ReservationStatus):IReservationsAction => ({
  type: reservationsActionTypes.UPDATE_RESERVATION_STATUS_START,
  payload: {id, status}
})

