import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import TestbedAreaView from "./testbed-area-view.component"
import {selectTestbedsList} from "../_redux/testbeds/testbed.selectors";
import {testbedAreasFetchStart} from "../_redux/testbed-areas/testbed-areas.actions";

const TestbedAreaWrapper: React.FC<{}> = ({}) => {
  return(
    <TestbedAreaView/>
  )
}


export default TestbedAreaWrapper
