import {Box, TextField} from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import React, {useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import {useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import PageContainer from "_common/PageContainer";
import {reservationsFetchStart, updateReservationStatusStart} from "_redux/reservations/reservations.actions";
import {selectReservationList, selectReservationsLoading} from "_redux/reservations/reservations.selectors";
import CancelIcon from '@mui/icons-material/Cancel';
import {IReservationProjection} from "../_api/openAPI";
import {useAppDispatch} from "../_redux/hooks";
import {Col, Row} from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import {ReservationStatus} from "../_redux/reservations/reservations.types";


const columns = [
  {
    name: 'Reservation ID',
    selector: (row: any) => row.reservationId,
    sortable: true
  },
  {
    name: 'From',
    selector: (row: any) => row.validFrom,
    sortable: true
  },
  {
    name: 'Until',
    selector: (row: any) => row.validUntil,
    sortable: true
  },
  {
    name: 'Status',
    selector: (row: any) => row.reservationStatus,
    sortable: true

  },
];

const columnsRes = [
  {
    name: 'ID',
    selector: (row: any) => row.resourceId,
    sortable: true
  },
  {
    name: 'Name',
    selector: (row: any) => row.resourceName,
    sortable: true
  },
  {
    name: 'Type',
    selector: (row: any) => row.resourceType,
    sortable: true
  },
];


const Reservations: React.FC<{}> = () => {
  const loading = useSelector(selectReservationsLoading);
  const dispatch = useAppDispatch();
  const reservationList = useSelector(selectReservationList);


  const [selectedReservation, setSelectedReservation] = useState<IReservationProjection | undefined>(undefined)


  useEffect(()=>{
    dispatch(reservationsFetchStart());
  },[])


  const cancelAll = () => {
    setSelectedReservation(undefined);
  };


  const handleOnRowClicked = (row: IReservationProjection) => {
    setSelectedReservation(row);
  };


  return (
    (loading) ? <div>Loading....</div> :
      <div>

        <PageContainer>
          <section>
            <Row>
              <Col sm={12}>
                <DataTable
                  title="Reservations"
                  columns={columns}
                  data={reservationList}
                  dense
                  pagination
                  sortIcon={<ArrowDownward/>}
                  highlightOnHover={true}
                  onRowClicked={handleOnRowClicked}/>
              </Col>
            </Row>
          </section>
          {
            selectedReservation &&
            <section className="my-4">
              <Row className="mb-3">
                <Col className="d-flex flex-row justify-content-between align-items-center">
                  <Box>
                    {selectedReservation.reservationId ? `Reservation ID: ${selectedReservation.reservationId}` : `Create new Reservation`}
                  </Box>
                  <Box className="d-flex flex-row">
                    {
                      (selectedReservation.reservationStatus === "PENDING") && <>
                        <Button style={{color: "red"}} className="me-2" variant={"outlined"} startIcon={<DeleteIcon/>}
                                onClick = {() => {
                                  dispatch(updateReservationStatusStart(selectedReservation.reservationId as number, ReservationStatus.REJECTED))
                                  setSelectedReservation(undefined)
                                }} >REJECT</Button>
                        <Button color={"primary"} type="button" variant={"outlined"}
                                onClick = {() => dispatch(updateReservationStatusStart(selectedReservation.reservationId as number, ReservationStatus.OK))} startIcon={<SaveIcon/>} >ACCEPT</Button>
                      </>
                      }

                  </Box>
                </Col>
              </Row>
              <Row>
                  <Box>
                    <Row>
                      <Col sm={4}>
                        <TextField
                          label="Status"
                          id="reservationStatus"
                          name="reservationStatus"
                          placeholder="Status"
                          type={"text"}
                          value={selectedReservation.reservationStatus}
                          inputProps={{ disabled: true, }}
                        />
                      </Col>
                      <Col sm={4}>
                        <TextField
                          label="Valid From"
                          id="validFrom"
                          name="validFrom"
                          placeholder="Valid From"
                          type={"text"}
                          value={selectedReservation.validFrom}
                          inputProps={{ disabled: true, }}

                        />
                      </Col>
                      <Col sm={4}>
                        <TextField
                          label="Valid Until"
                          id="validUntil"
                          name="validUntil"
                          placeholder="Valid Until"
                          type={"text"}
                          value={selectedReservation.validUntil}
                          inputProps={{ disabled: true, }}

                        />
                      </Col>
                      <Col sm={4}>
                        <TextField
                          label="Testbed Area"
                          id="testbedArea"
                          name="testbedArea"
                          placeholder="Testbed Area"
                          type={"text"}
                          value={selectedReservation.testbedAreaId}
                          inputProps={{ disabled: true, }}

                        />
                      </Col>
                      <section>
                        {selectedReservation.reservationItems && <Row className="my-4">
                          <Col>
                            <DataTable
                              title="Resources"
                              columns={columnsRes}
                              data={selectedReservation.reservationItems}
                              dense
                              pagination
                              sortIcon={<ArrowDownward/>}
                              highlightOnHover={true}
                              // onRowClicked={handleOnRowClicked}
                            />
                          </Col>
                        </Row>}
                      </section>
                    </Row>
                    <Row>
                      <Col className="d-flex flex-row justify-content-end align-items-center">
                        <Button color={"secondary"} variant={"outlined"} startIcon={<CancelIcon/>}
                                onClick={cancelAll}>CANCEL</Button>
                      </Col>
                    </Row>

                  </Box>
              </Row>
            </section>
          }
        </PageContainer>
      </div>
  )
}


export default Reservations
