import {
  DdlScriptControllerApi,
  ExperimentControllerApi,
  ReservationControllerApi,
  ResourceControllerApi, TestbedAreaControllerApi,
  TestbedControllerApi,
  UserControllerApi,
    Configuration
} from "./openAPI";
import {toast} from 'react-toastify';
import axios from "axios";

export let auth = {
  token: ""
}
export const Client = axios.create({})

Client.interceptors.request.use((config) => {
  config.headers = {
    "Authorization": `Bearer ${auth.token}`,
    ...config.headers,
  }
  return config
})

Client.interceptors.response.use((response) => {
  return response
  }
  , (error) => {
    console.log(error.response)
    toast.error(error.response.data?.message || "Error", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    return Promise.reject(error)
  })


export const Delegators = {
  experimentControllerApi: (): ExperimentControllerApi => {
    return new ExperimentControllerApi(undefined, "https://outlander4.di.uoa.gr:8181", Client)
  },

  reservationControllerApi: (): ReservationControllerApi => {
    return new ReservationControllerApi()
  },

  resourceControllerApi: (): ResourceControllerApi => {
    return new ResourceControllerApi()
  },

  testbedAreaControllerApi: (): TestbedAreaControllerApi => {
    return new TestbedAreaControllerApi()
  },

  ddlScriptControllerApi: (): DdlScriptControllerApi => {
    return new DdlScriptControllerApi()
  },
}

class Service  {
  static testbedControllerApi = new TestbedControllerApi(undefined, "https://outlander4.di.uoa.gr:8181", Client)
  static testbedAreaControllerApi = new TestbedAreaControllerApi(undefined, "https://outlander4.di.uoa.gr:8181", Client);
  static resourceControllerApi = new ResourceControllerApi(undefined, "https://outlander4.di.uoa.gr:8181", Client)
  static reservationControllerApi = new ReservationControllerApi(undefined, "https://outlander4.di.uoa.gr:8181", Client)
}



export default Service;
