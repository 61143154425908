import {createSelector} from "reselect";
import {IRootState} from "../root-reducer";
import { IReservationsState } from "./reservations.types";
import _ from "lodash";


const selectReservations = (state:IRootState) => state.reservations;


export const selectReservationList = createSelector(
    [selectReservations],
    (reservations:IReservationsState)=>_.orderBy(reservations.reservationList, i=>i.reservationId)
);

export const selectReservationsLoading = createSelector(
    [selectReservations],
    (reservations)=>reservations.loading
);

