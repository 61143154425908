import {createSelector} from "reselect";
import {IRootState} from "../root-reducer";


const selectTestbedAreas = (state:IRootState) => state.testbedAreas;


export const selectTestbedAreasList = createSelector(
  [selectTestbedAreas],
  (i)=>i.testbedAreasList
);

export const selectTestbedAreasLoading = createSelector(
  [selectTestbedAreas],
  (i)=>i.loading
);
